import { Col, Row, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import CommissionService from "../../../../services/CommissionService";
import dayjs from "dayjs";
import ReusableChart from "./ReusableChart";

const RetentionCountReport = ({
  dateRange,
  teamName,
  teamMember,
  periodCount,
}: {
  dateRange: any;
  teamName: string;
  teamMember: string;
  periodCount: number;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dateLabels, setDateLabels] = useState<any[]>([]);
  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [chartHeight, setChartHeight] = useState<number>(200);
  const tableRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200 && tableRef.current) {
        const tableColHeight = tableRef.current.getBoundingClientRect().height;
        setChartHeight(Math.max(200, tableColHeight - 30));
      } else {
        setChartHeight(250);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1] && teamName) {
      const periods = [];
      let start = dayjs(dateRange[0]);
      let end = dayjs(dateRange[1]);

      for (let i = 0; i < periodCount; i++) {
        const formattedStart = start.format("YYYY-MM-DD");
        const formattedEnd = end.format("YYYY-MM-DD");

        periods.push({
          start: formattedStart,
          end: formattedEnd,
          label: `${start.format("DD.MMM.YY")} - ${end.format("DD.MMM.YY")}`,
        });

        const diffDays = end.diff(start, "days") + 1;
        end = start.subtract(1, "days");
        start = end.subtract(diffDays - 1, "days");
      }

      const reversedPeriods = periods.reverse(); // Period'ları tersine çevir
      getReports(teamName, reversedPeriods);
      setDateLabels(reversedPeriods);      
    }
    // eslint-disable-next-line
  }, [dateRange, teamName, periodCount]);

  const excludedUsernames = [
    "az.success.team.crm@oneamz.com",
    "stm@oneamz.com",
    "duygu.karakilic.hi5acm.crm@oneamz.com",
    "murat.yakar.hi5acm.crm@oneamz.com",
  ];

  const getCountKeys = () => {
    if (location.pathname.includes("/hi5-acm-reports/summary-report")) {
      return ["slpRetention", "goldRetention"];
    }
    if (location.pathname.includes("/stm-reports/summary-report")) {
      return ["softwareRetention"];
    }
    if (location.pathname.includes("/acm-reports/summary-report")) {
      return ["goldRetention"];
    }
    return [];
  };

  const getReports = async (teamName: string, periods: any[]) => {
    setLoading(true);

    try {
      const promises = periods.map((period) =>
        CommissionService.earningReport(teamName, period.start, period.end)
      );
      const responses = await Promise.all(promises);

      const retentionMap = new Map();

      const countKeys = getCountKeys();

      periods.forEach((period, periodIndex) => {
        const response = responses[periodIndex]?.data || [];
        response
          .filter(
            (item: any) => !excludedUsernames.includes(item.member.username)
          )
          .forEach((item: any) => {
            const memberKey = `${item.member.firstName} ${item.member.lastName}`;
            if (!retentionMap.has(memberKey)) {
              retentionMap.set(memberKey, {
                member: item.member,
                counts: Array(periods.length).fill(0),
                softwareRetention: Array(periods.length).fill(0),
                slpRetention: Array(periods.length).fill(0),
                goldRetention: Array(periods.length).fill(0),
              });
            }
            const retentionData = retentionMap.get(memberKey);

            countKeys.forEach((key) => {
              const customerKey = `${key}Customers`;
              retentionData[key][periodIndex] = Array.isArray(item[customerKey])
                ? item[customerKey].length
                : 0;
            });

            retentionData.counts[periodIndex] = countKeys.reduce(
              (sum, key) =>
                sum +
                (Array.isArray(item[`${key}Customers`])
                  ? item[`${key}Customers`].length
                  : 0),
              0
            );

            retentionMap.set(memberKey, retentionData);
          });
      });

      const tableData = Array.from(retentionMap.values()).map((item) => {
        const data: Record<string, any> = {
          member: item.member,
        };
        periods.forEach((_, index) => {
          countKeys.forEach((key) => {
            data[`${key}-${index}`] = item[key][index] || 0;
          });
        });
        return data;
      });

      setDataSource(tableData);

      const chartData = Array.from(retentionMap.values()).map((item) => ({
        name: `${item.member.firstName} ${item.member.lastName}`,
        data: item.counts.map((count: number) => count || 0),
      }));

      setChartSeries(chartData);
      // setDateLabels([...periods].reverse());
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: teamMember,
      dataIndex: "member",
      key: "member",
      fixed: "left",
      width: 220,
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a, b) =>
        a?.member?.firstName.localeCompare(b?.member?.firstName),
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={`${record?.member?.firstName} ${record?.member?.lastName}`}
          >
            <b>
              {record?.member?.firstName} {record?.member?.lastName}
            </b>
          </div>
        );
      },
    },
    ...(location.pathname.includes("/stm-reports/summary-report")
      ? [
          {
            title: (
              <div
                className="column-group-title"
                style={{
                  border: "3px solid #e9e9e9",
                  paddingBottom: "8px",
                  paddingTop: "8px",
                  marginBottom: "-16px",
                }}
              >
                Software Retention
              </div>
            ),
            children: dateLabels.map((label: any, index: number) => ({
              title: label.label,
              dataIndex: `softwareRetention-${index}`,
              key: `softwareRetention-${index}`,
              align: "center" as "center",
              width: 175,
              render: (count: number) => <b>{count ?? 0}</b>,
            })),
          },
        ]
      : []),
    ...(location.pathname.includes("/hi5-acm-reports/summary-report")
      ? [
          {
            title: (
              <div
                className="column-group-title"
                style={{
                  border: "3px solid #e9e9e9",
                  paddingBottom: "8px",
                  paddingTop: "8px",
                  marginBottom: "-16px",
                }}
              >
                SLP Retention
              </div>
            ),
            children: dateLabels.map((label: any, index: number) => ({
              title: label.label,
              dataIndex: `slpRetention-${index}`,
              key: `slpRetention-${index}`,
              align: "center" as "center",
              width: 175,
              render: (count: number) => <b>{count ?? 0}</b>,
            })),
          },
        ]
      : []),
    ...(location.pathname.includes("/acm-reports/summary-report") ||
    location.pathname.includes("/hi5-acm-reports/summary-report")
      ? [
          {
            title: (
              <div
                className="column-group-title"
                style={{
                  border: "3px solid #e9e9e9",
                  paddingBottom: "8px",
                  paddingTop: "8px",
                  marginBottom: "-16px",
                }}
              >
                Gold Retention
              </div>
            ),
            children: dateLabels.map((label: any, index: number) => ({
              title: label.label,
              dataIndex: `goldRetention-${index}`,
              key: `goldRetention-${index}`,
              align: "center" as "center",
              width: 175,
              render: (count: number) => <b>{count ?? 0}</b>,
            })),
          },
        ]
      : []),
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40, marginTop: 40 }}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={14}>
            <ReusableChart
              type="bar"
              series={chartSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col>
          <Col xs={24} xl={10} ref={tableRef}>
            <Table
              rowKey={(record) => record.member?.id}
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              scroll={{ x: "100%" }}
              pagination={false}
              tableLayout="fixed"
              summary={(pageData) => {
                // Software Retention Total
                let softwareRetentionTotals: number[] =
                  Array(periodCount).fill(0);
                if (location.pathname.includes("/stm-reports/summary-report")) {
                  pageData.forEach((row) => {
                    dateLabels.forEach((_, index) => {
                      softwareRetentionTotals[index] +=
                        row[`softwareRetention-${index}`] ?? 0;
                    });
                  });
                }

                // SLP Retention Total
                let slpRetentionTotals: number[] = Array(periodCount).fill(0);
                if (
                  location.pathname.includes("/hi5-acm-reports/summary-report")
                ) {
                  pageData.forEach((row) => {
                    dateLabels.forEach((_, index) => {
                      slpRetentionTotals[index] +=
                        row[`slpRetention-${index}`] ?? 0;
                    });
                  });
                }

                // Gold Retention Total
                let goldRetentionTotals: number[] = Array(periodCount).fill(0);
                if (
                  location.pathname.includes(
                    "/hi5-acm-reports/summary-report"
                  ) ||
                  location.pathname.includes("/acm-reports/summary-report")
                ) {
                  pageData.forEach((row) => {
                    dateLabels.forEach((_, index) => {
                      goldRetentionTotals[index] +=
                        row[`goldRetention-${index}`] ?? 0;
                    });
                  });
                }

                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row className="summary-cell">
                      {/* Member Column */}
                      <Table.Summary.Cell
                        index={0}
                        colSpan={1}
                        align="left"
                        className="summary-cell"
                      >
                        <b>Total</b>
                      </Table.Summary.Cell>

                      {/* Software Retention Totals */}
                      {location.pathname.includes(
                        "/stm-reports/summary-report"
                      ) &&
                        softwareRetentionTotals.map((total, index) => (
                          <Table.Summary.Cell
                            key={`software-total-${index}`}
                            index={index + 1}
                            align="center"
                            className="summary-cell"
                          >
                            <b>{total || 0}</b>
                          </Table.Summary.Cell>
                        ))}

                      {/* SLP Retention Totals */}
                      {location.pathname.includes(
                        "/hi5-acm-reports/summary-report"
                      ) &&
                        slpRetentionTotals.map((total, index) => (
                          <Table.Summary.Cell
                            key={`slp-total-${index}`}
                            index={index + 2}
                            align="center"
                            className="summary-cell"
                          >
                            <b>{total || 0}</b>
                          </Table.Summary.Cell>
                        ))}

                      {/* Gold Retention Totals */}
                      {(location.pathname.includes(
                        "/hi5-acm-reports/summary-report"
                      ) ||
                        location.pathname.includes(
                          "/acm-reports/summary-report"
                        )) &&
                        goldRetentionTotals.map((total, index) => (
                          <Table.Summary.Cell
                            key={`gold-total-${index}`}
                            index={index + 3}
                            align="center"
                            className="summary-cell"
                          >
                            <b>{total || 0}</b>
                          </Table.Summary.Cell>
                        ))}
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default RetentionCountReport;
