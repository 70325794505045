import { useEffect, useState } from "react";
// import dayjs from 'dayjs';
import CustomerAssignmentHistoryService from "../../../../services/CustomerAssignmentHistoryService";
import { ColumnsType } from "antd/es/table";
import { Col, Row } from "antd";
import { Table } from "antd/lib";

const AdditionalPackagesPurchasedWithGoldOrSlpPackageReport = ({
  dateRange,
  memberFlag,
}: {
  dateRange: any;
  memberFlag: string;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (memberFlag) {
      getAdditionalPackageSummaryReport(memberFlag);
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const getAdditionalPackageSummaryReport = (roleName: string) => {
    setLoading(true);
    CustomerAssignmentHistoryService.getAdditionalPackageSummaryReport(roleName)
      .then((response) => {
        let formattedData = Object.entries(response.data.groupedReport).map(
          ([key, value]) => ({
            packageName: key,
            packageCount: value,
          })
        );

        // Filter the data based on memberFlag
        if (memberFlag === "ACCOUNT_MANAGER") {
          formattedData = formattedData.filter(
            (item) => item.packageName !== "OneAmz Gold User"
          );
        } else if (memberFlag === "HI5_ACM_MEMBER") {
          formattedData = formattedData.filter(
            (item) => item.packageName !== "Seller Launch Pad"
          );
        }

        setData(formattedData);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Package Name",
      dataIndex: "packageName",
      key: "packageName",
      width: 300,
      ellipsis: true,
      sorter: (a, b) => a.packageName.localeCompare(b.packageName),
      render: (text: string) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={`${text}`}
        >
          <b>{text}</b>
        </div>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "packageCount",
      key: "packageCount",
      align: "center" as "center",
      width: 150,
      sorter: (a: any, b: any) => a?.packageCount - b?.packageCount,
      render: (text) => <b>{text} qty</b>,
    },
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40, marginTop: 40 }}>
      <Col span={24}>
        <Table
          rowKey={"packageName"}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{ x: "100%" }}
          sticky
          pagination={false}
          summary={(pageData) => {
            let total = 0;
            pageData.forEach(({ packageCount }) => {
              total += packageCount;
            });
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={1} align="left">
                    <b>Total</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="center">
                    <b>{total} qty</b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default AdditionalPackagesPurchasedWithGoldOrSlpPackageReport;
