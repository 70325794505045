import {
  Button,
  Modal,
  Form,
  Select,
  Row,
  Col,
  Tooltip,
  DatePicker,
} from "antd";
import React, { useState } from "react";
import UserService from "../../../services/UserService";
import { IUserResponseData } from "../../../types/User";
import filterIcon from "../../../assets/filter.svg";
import filterClearIcon from "../../../assets/filter-clear.svg";
import AmazonSuspendedApplicantSourcesService from "../../../services/AmazonSuspendedApplicantSourcesService";
import CallSalesPackageService from "../../../services/CallSalesPackageService";
import StatusService from "../../../services/StatusService";

const { Item } = Form;

interface CustomerFilterProps {
  onChangeFilter: (values: any) => void;
  setFormFields: any;
  formFields: any;
}

const Filter: React.FC<CustomerFilterProps> = ({
  onChangeFilter,
  setFormFields,
  formFields,
}) => {
  const { RangePicker } = DatePicker;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customerStatusOptions, setCustomerStatusOptions] = useState<any[]>([]);
  const [customerSuspendTypeOptions, setCustomerSuspendTypeOptions] = useState<
    any[]
  >([]);
  const [customerSuspendServiceTypeOptions, setCustomerSuspendServiceTypeOptions] = useState<
  any[]
>([]);
  const [customerActionTypeOptions, setCustomerActionTypeOptions] = useState<
  any[]
>([]);
  const [customerInputOptions, setCustomerInputOptions] = useState<any[]>([]);
  const [customerSources, setCustomerSources] = useState<any[]>([]);
  const [userOptions, setUserOptions] = useState<IUserResponseData[]>([]);
  const [packagesOptions, setPackagesOptions] = useState<any>([]);
  const [form] = Form.useForm();
  const roleName = localStorage.getItem("roleName");
  const [selectedStatusItems, setSelectedStatusItems] = useState<any[]>([]);
  const [selectedTypeItems, setSelectedTypeItems] = useState<any[]>([]);
  const [selectedInputItems, setSelectedInputItems] = useState<any[]>([]);
  const savedLocalFilters = localStorage.getItem("amazonSuspendedFilterText");

  const showModal = () => {
    if (userOptions.length === 0) {
      getUsers();
    }
    if (packagesOptions.length === 0) {
      getPackages();
    }
    if (customerStatusOptions.length === 0) {
      getCustomerStatus();
    }
    if (customerSuspendTypeOptions.length === 0) {
      getCustomerSuspendType();
    }
    if (customerSuspendServiceTypeOptions.length === 0) {
      getCustomerServiceType();
    }
    if (customerActionTypeOptions.length === 0) {
      getCustomerActionType();
    }
    if (customerInputOptions.length === 0) {
      getCustomerInput();
    }
    if (customerSources.length === 0) {
      getSources();
    }
    setIsModalOpen(true);
  };

  const onFinish = (values: any) => {
    onChangeFilter(values);
    setFormFields(form.getFieldsValue());
    setIsModalOpen(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const getUsers = () => {
    UserService.getAll("?roleName=SUSPEND_TEAM")
      .then((response: any) => {
        let data = response;
        setUserOptions(data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const clearForm = () => {
    form.resetFields();
    setSelectedStatusItems([]);
    setSelectedTypeItems([]);
    setSelectedInputItems([]);
    setFormFields(form.getFieldsValue());
    localStorage.removeItem("amazonSuspendedFilterText");
  };

  const clearLocalFilterAndForm = () => {
    clearForm();
    onFinish({ ...form.getFieldsValue() });
  };

  const getCustomerStatus = () => {
    StatusService.getAll(
      "statusType=AMAZON_SUSPENDED_APPLICANT_STATUS",
      `&active=true`
    )
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name };
        });
        setCustomerStatusOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getCustomerSuspendType = () => {
    StatusService.getAll(
      "statusType=AMAZON_SUSPENDED_APPLICANT_SUSPEND_TYPE",
      `&active=true`
    )
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name };
        });
        setCustomerSuspendTypeOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getCustomerServiceType = () => {
    StatusService.getAll(
      "statusType=AMAZON_SUSPENDED_APPLICANT_SERVICE_TYPE",
      `&active=true`
    )
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name };
        });
        setCustomerSuspendServiceTypeOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getCustomerActionType = () => {
    StatusService.getAll(
      "statusType=AMAZON_SUSPENDED_APPLICANT_ACTION_TYPE",
      `&active=true`
    )
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name };
        });
        setCustomerActionTypeOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getCustomerInput = () => {
    StatusService.getAll(
      "statusType=AMAZON_SUSPENDED_APPLICANT_INPUT",
      `&active=true`
    )
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setCustomerInputOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getSources = () => {
    AmazonSuspendedApplicantSourcesService.getAll()
      .then((response: any) => {
        let data = response.data;
        const newArray = data
          .filter((item: any) => item.active)
          .map((item: any) => {
            return { value: item.id, label: item.name };
          });
        setCustomerSources(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getPackages = () => {
    CallSalesPackageService.getAll()
      .then((response: any) => {
        let data = response.data;
        const newArray = data
          .filter((item: any) => item.isActive)
          .map((item: any) => {
            return { value: item.id, label: item.name };
          });
        setPackagesOptions(newArray);
      })
      .catch((e: Error) => {
        console.error(e);
      });
  };

  const suspendedCountryOptions = [
    { value: "CA" },
    { value: "MX" },
    { value: "AE" },
    { value: "SA" },
    { value: "AU" },
    { value: "UK" },
    { value: "US" },
    { value: "JP" },
  ];

  // const numberOfViolationsOptions = Array.from({ length: 11 }, (_, i) => ({
  //   value: i.toString(),
  // }));

  // const numberOfAmazonCallsOptions = Array.from({ length: 16 }, (_, i) => ({
  //   value: i.toString(),
  // }));

  const onValuesChange = (changedValues: any, allValues: any) => {
    const { startPassiveDay, endPassiveDay } = allValues;
    if (
      startPassiveDay &&
      (endPassiveDay < startPassiveDay || !endPassiveDay)
    ) {
      form.setFieldsValue({ endPassiveDay: startPassiveDay });
    }
  };

  const selectAllStatus = () => {
    const allStatusIds = customerStatusOptions.map((option) => option.value);
    setSelectedStatusItems(allStatusIds);
    form.setFieldsValue({
      suspendStatus: allStatusIds,
    });
  };

  const selectAllType = () => {
    const allSuspendTypeIds = customerSuspendTypeOptions.map(
      (option) => option.value
    );
    setSelectedTypeItems(allSuspendTypeIds);
    form.setFieldsValue({
      suspendType: allSuspendTypeIds,
    });
  };

  const selectAllInput = () => {
    const allInputIds = customerInputOptions.map((option) => option.value);
    setSelectedInputItems(allInputIds);
    form.setFieldsValue({
      input: allInputIds,
    });
  };

  const handleChangeStatus = (selected: any) => {
    setSelectedStatusItems(selected);
    form.setFieldsValue({
      suspendStatus: selected,
    });
  };

  const handleChangeType = (selected: any) => {
    setSelectedTypeItems(selected);
    form.setFieldsValue({
      suspendType: selected,
    });
  };

  const handleChangeInput = (selected: any) => {
    setSelectedInputItems(selected);
    form.setFieldsValue({
      input: selected,
    });
  };

  return (
    <>
      <Modal
        title="Customer Filter"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
        >
          {roleName !== "SUSPEND_TEAM" && userOptions ? (
            <>
              Suspend Team Member
              <Item name="suspendTeamMember">
                <Select placeholder={"Select a team member"}>
                  <Select.Option key={"null-member"} value={null}>
                    Select
                  </Select.Option>
                  {userOptions?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.firstName} {option.lastName}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </>
          ) : null}
          Suspend Status
          <Item name="suspendStatus">
            <Select
              placeholder={"Select a suspend status"}
              options={customerStatusOptions}
              showSearch
              allowClear
              mode="multiple"
              value={selectedStatusItems}
              style={{ width: "75%", marginRight: "10px" }}
              onChange={handleChangeStatus}
              filterOption={(input, option: any) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            ></Select>
            <Button style={{ float: "right" }} onClick={selectAllStatus}>
              Select All
            </Button>
          </Item>
          Suspend Type
          <Item name="suspendType">
            <Select
              placeholder={"Select a suspend type"}
              options={customerSuspendTypeOptions}
              showSearch
              allowClear
              mode="multiple"
              value={selectedTypeItems}
              style={{ width: "75%", marginRight: "10px" }}
              onChange={handleChangeType}
              filterOption={(input, option: any) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            ></Select>
            <Button style={{ float: "right" }} onClick={selectAllType}>
              Select All
            </Button>
          </Item>
          Input
          <Item name="input">
            <Select
              placeholder={"Select a input"}
              options={customerInputOptions}
              showSearch
              allowClear
              mode="multiple"
              value={selectedInputItems}
              style={{ width: "75%", marginRight: "10px" }}
              onChange={handleChangeInput}
              filterOption={(input, option: any) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            ></Select>
            <Button style={{ float: "right" }} onClick={selectAllInput}>
              Select All
            </Button>
          </Item>
          <Row justify="space-between" align="middle" gutter={[8, 8]}>
            <Col xs={24} sm={12}>
              Source
              <Item name="source">
                <Select placeholder={"Select a source"}>
                  <Select.Option key={"null-source"} value={null}>
                    Select a source
                  </Select.Option>
                  {customerSources?.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              Package
              <Item name="package">
                <Select placeholder={"Select a package"}>
                  <Select.Option key={"null-package"} value={null}>
                    Select a package
                  </Select.Option>
                  {packagesOptions?.map((option: any) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
          </Row>
          <Row justify="space-between" align="middle" gutter={[8, 8]}>
            <Col xs={24} sm={12}>
              Suspended Country
              <Item name="suspendedCountry">
                <Select placeholder={"Select a suspended country"}>
                  <Select.Option key={"null-suspended-country"} value={null}>
                    Select a suspended country
                  </Select.Option>
                  {suspendedCountryOptions?.map(
                    (option: any, index: number) => (
                      <Select.Option key={index} value={option.value}>
                        {option.value}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              OneAmz App Status
              <Item name="oneamzAppStatus">
                <Select placeholder={"Select OneAmz App Status"}>
                  <Select.Option key={"null-oneamz-app-status"} value={null}>
                    Select
                  </Select.Option>
                  <Select.Option key={"true"} value="true">
                    Yes
                  </Select.Option>
                  <Select.Option key={"false"} value="false">
                    No
                  </Select.Option>
                </Select>
              </Item>
            </Col>
          </Row>
          <Row justify="space-between" align="middle" gutter={[8, 8]}>
            <Col xs={24} sm={12}>
              Service Type
              <Item name="serviceType">
                <Select placeholder={"Select a service type"}>
                  <Select.Option key={"null-service-type"} value={null}>
                    Select a service type
                  </Select.Option>
                  {customerSuspendServiceTypeOptions?.map(
                    (option: any, index: number) => (
                      <Select.Option key={index} value={option.value}>
                        {option.label}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              Action Type
              <Item name="actionType">
                <Select placeholder={"Select a action type"}>
                  <Select.Option key={"null-action-type"} value={null}>
                    Select a action type
                  </Select.Option>
                  {customerActionTypeOptions?.map(
                    (option: any, index: number) => (
                      <Select.Option key={index} value={option.value}>
                        {option.label}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Item>
            </Col>
          </Row>
          <Row justify="space-between" align="middle" gutter={[8, 8]}>
          <Col xs={24} sm={24}>
              Assignment Date
              <Item name="assignmentDateRange">
                <RangePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
              </Item>
            </Col>
          </Row>
          {/* <Row justify="space-between" align="middle" gutter={[8, 8]}>
            <Col xs={24} sm={12}>
              Number of Violations
              <Item name="numberOfViolations">
                <Select placeholder={"Select a number of violations"}>
                  <Select.Option key={"null-violations"} value={null}>
                    Select a number of violations
                  </Select.Option>
                  {numberOfViolationsOptions?.map(
                    (option: any, index: number) => (
                      <Select.Option key={index} value={option.value}>
                        {option.value}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              Number of Amazon Calls
              <Item name="numberOfAmazonCalls">
                <Select placeholder={"Select a number of amazon calls"}>
                  <Select.Option key={"null-amazon-calls"} value={null}>
                    Select a number of amazon calls
                  </Select.Option>
                  {numberOfAmazonCallsOptions?.map(
                    (option: any, index: number) => (
                      <Select.Option key={index} value={option.value}>
                        {option.value}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Item>
            </Col>
          </Row> */}
          <Item>
            <Row justify="end">
              <Button
                key="back"
                style={{ marginRight: "10px" }}
                onClick={clearForm}
              >
                Clear
              </Button>
              <Button htmlType="submit" type="primary">
                Apply
              </Button>
            </Row>
          </Item>
        </Form>
      </Modal>
      <Tooltip title="Filters">
        <Button className="mr-15" onClick={showModal} type="default">
          <img src={filterIcon} alt="userIcon" style={{ margin: "1px" }} />
        </Button>
      </Tooltip>
      {savedLocalFilters && savedLocalFilters.length > 0 ? (
        <Tooltip title="Clear Filters">
          <Button
            style={{ marginRight: "10px" }}
            onClick={clearLocalFilterAndForm}
            type="default"
          >
            <img
              src={filterClearIcon}
              alt="userIcon"
              style={{ margin: "1px" }}
            />
          </Button>
        </Tooltip>
      ) : null}
    </>
  );
};

export default Filter;
