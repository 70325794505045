import { Col, Row, Table, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useRef, useState } from "react";
import AmazonSuspendedApplicantReportsService from "../../../../services/AmazonSuspendedApplicantReportsService";
import dayjs from "dayjs";
import { SuspendFileStatus } from "../../../../types/AmazonSuspendedApplicantReports";
import ReusableChart from "../../CommonReport/SummaryReport/ReusableChart";

const SuspendedFileStatusReport = ({
  dateRange,
  periodCount,
}: {
  dateRange: any;
  periodCount: number;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [grandTotal, setGrandTotal] = useState<any>({});
  const [dateLabels, setDateLabels] = useState<any[]>([]);
  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [chartHeight, setChartHeight] = useState<number>(200);
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1600 && tableRef.current) {
        const tableColHeight = tableRef.current.getBoundingClientRect().height;
        setChartHeight(Math.max(200, tableColHeight - 30));
      } else {
        setChartHeight(250);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const periods = [];
      let start = dayjs(dateRange[0]);
      let end = dayjs(dateRange[1]);

      for (let i = 0; i < periodCount; i++) {
        const formattedStart = start.format("YYYY-MM-DDT00:00:00");
        const formattedEnd = end.format("YYYY-MM-DDT23:59:59");

        periods.push({
          start: formattedStart,
          end: formattedEnd,
          label: `${start.format("DD.MMM.YY")} - ${end.format("DD.MMM.YY")}`,
        });

        const diffDays = end.diff(start, "days") + 1;
        end = start.subtract(1, "days");
        start = end.subtract(diffDays - 1, "days");
      }

      const reversedPeriods = periods.reverse(); // Period'ları tersine çevir
      getReports(reversedPeriods);
      setDateLabels(reversedPeriods);
    }
    // eslint-disable-next-line
  }, [dateRange, periodCount]);

  const getReports = async (periods: any[]) => {
    setLoading(true);
    try {
      const promises = periods.map((period) =>
        AmazonSuspendedApplicantReportsService.getSuspendFileStatus(
          period.start,
          period.end
        )
      );
      const grandTotalPromise =
        AmazonSuspendedApplicantReportsService.getSuspendFileStatusGrandTotal();

      const [responses, grandTotalData] = await Promise.all([
        Promise.all(promises),
        grandTotalPromise,
      ]);

      setGrandTotal(grandTotalData.data || {});

      const allMembers = Array.from(
        new Set(
          responses.flatMap((response) =>
            response.data.map((item: SuspendFileStatus) => item.memberName)
          )
        )
      );

      const normalizedData = allMembers.map((memberName) => {
        const row: Record<string, any> = { memberName };
        periods.forEach((period, index) => {
          const periodData = responses[index].data.find(
            (item: SuspendFileStatus) => item.memberName === memberName
          );
          row[period.label] = {
            statusCounts: periodData?.statusCounts || {},
          };
        });
        return row;
      });

      setDataSource(normalizedData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dataSource.length > 0) {
      const solvedSeries = {
        name: "Çözüldü",
        data: dateLabels.map((period) =>
          dataSource.reduce(
            (total, row) =>
              total + (row[period.label]?.statusCounts?.["Çözüldü"] || 0),
            0
          )
        ),
      };

      const inProgressSeries = {
        name: "Devam Ediyor",
        data: dateLabels.map((period) =>
          dataSource.reduce(
            (total, row) =>
              total + (row[period.label]?.statusCounts?.["Devam Ediyor"] || 0),
            0
          )
        ),
      };

      const notOpenedSeries = {
        name: "Açılamadı",
        data: dateLabels.map((period) =>
          dataSource.reduce(
            (total, row) =>
              total +
              (row[period.label]?.statusCounts?.["Açılamadı"] || 0) +
              (row[period.label]?.statusCounts?.[
                "Açılamadı - İadesi Yapıldı"
              ] || 0),
            0
          )
        ),
      };

      setChartSeries([solvedSeries, inProgressSeries, notOpenedSeries]);
    }
  }, [dataSource, dateLabels]);

  const columns: ColumnsType<SuspendFileStatus> = [
    {
      title: "Member Name",
      dataIndex: "member",
      key: "member",
      fixed: "left",
      width: 220,
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.memberName.localeCompare(b.memberName),
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={`${record?.memberName}`}
          >
            <b>{record?.memberName}</b>
          </div>
        );
      },
    },
    {
      title: (
        <div
          className="column-group-title"
          style={{
            border: "3px solid #e9e9e9",
            paddingBottom: "8px",
            paddingTop: "8px",
            marginBottom: "-16px",
          }}
        >
          Çözüldü
        </div>
      ),
      children: dateLabels.map((period) => ({
        title: period.label,
        dataIndex: period.label,
        key: `${period.label}-solved`,
        align: "center",
        width: 180,
        render: (_: any, record: any) => (
          <b>{record[period.label]?.statusCounts?.["Çözüldü"] || 0}</b>
        ),
      })),
    },
    {
      title: (
        <div
          className="column-group-title"
          style={{
            border: "3px solid #e9e9e9",
            paddingBottom: "8px",
            paddingTop: "8px",
            marginBottom: "-16px",
          }}
        >
          Devam Ediyor
        </div>
      ),
      children: dateLabels.map((period) => ({
        title: period.label,
        dataIndex: period.label,
        key: `${period.label}-inProgress`,
        align: "center",
        width: 180,
        render: (_: any, record: any) => (
          <b>{record[period.label]?.statusCounts?.["Devam Ediyor"] || 0}</b>
        ),
      })),
    },
    {
      title: (
        <Tooltip title="Açılamadı ve Açılmadı - İadesi Yapıldı Toplamı">
          <div
            className="column-group-title"
            style={{
              border: "3px solid #e9e9e9",
              paddingBottom: "8px",
              paddingTop: "8px",
              marginBottom: "-16px",
            }}
          >
            Açılamadı *
          </div>
        </Tooltip>
      ),
      children: dateLabels.map((period) => ({
        title: period.label,
        dataIndex: period.label,
        key: `${period.label}-notOpened`,
        align: "center",
        width: 180,
        render: (_: any, record: any) => (
          <b>
            {(record[period.label]?.statusCounts?.["Açılamadı"] || 0) +
              (record[period.label]?.statusCounts?.[
                "Açılmadı- İadesi Yapıldı"
              ] || 0) +
              (record[period.label]?.statusCounts?.[
                "Açılamadı - İadesi Yapıldı"
              ] || 0)}
          </b>
        ),
      })),
    },
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40, marginTop: 40 }}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} xxl={14}>
            <ReusableChart
              type="bar"
              series={chartSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col>
          <Col xs={24} xxl={10} ref={tableRef}>
            <Table
              rowKey={(record) => record?.memberName}
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              scroll={{ x: "100%" }}
              pagination={false}
              tableLayout="fixed"
              //   className="summary-report-table"
              summary={(summaryData) => {
                if (summaryData.length === 0) return null;

                const summaryTotals: Record<string, any> = {};

                // Her statü için toplamları hesapla
                dateLabels.forEach((period) => {
                  summaryTotals[`${period.label}-solved`] = summaryData.reduce(
                    (sum, row) =>
                      sum + (row[period.label]?.statusCounts?.["Çözüldü"] || 0),
                    0
                  );
                  summaryTotals[`${period.label}-inProgress`] =
                    summaryData.reduce(
                      (sum, row) =>
                        sum +
                        (row[period.label]?.statusCounts?.["Devam Ediyor"] ||
                          0),
                      0
                    );
                  summaryTotals[`${period.label}-notOpened`] =
                    summaryData.reduce(
                      (sum, row) =>
                        sum +
                        (row[period.label]?.statusCounts?.["Açılamadı"] || 0) +
                        (row[period.label]?.statusCounts?.[
                          "Açılamadı - İadesi Yapıldı"
                        ] || 0),
                      0
                    );
                });

                const grandTotalsMap = Array.isArray(grandTotal)
                  ? grandTotal.reduce(
                      (acc: Record<string, number>, item: any) => {
                        acc[item.status] = item.grandTotal;
                        return acc;
                      },
                      {}
                    )
                  : {};

                // Summary satırı oluştur
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row className="summary-cell">
                      {/* Member Name Kolonuna "Total" Başlığı */}
                      <Table.Summary.Cell
                        index={0}
                        colSpan={1}
                        className="summary-cell"
                      >
                        <b>Total</b>
                      </Table.Summary.Cell>

                      {/* Çözüldü için toplama */}
                      {dateLabels.map((period, index) => (
                        <Table.Summary.Cell
                          key={`${period.label}-solved`}
                          index={index + 1}
                          align="center"
                          className="summary-cell"
                        >
                          <b>{summaryTotals[`${period.label}-solved`]}</b>
                        </Table.Summary.Cell>
                      ))}

                      {/* Devam Ediyor için toplama */}
                      {dateLabels.map((period, index) => (
                        <Table.Summary.Cell
                          key={`${period.label}-inProgress`}
                          index={index + 1 + dateLabels.length}
                          align="center"
                          className="summary-cell"
                        >
                          <b>{summaryTotals[`${period.label}-inProgress`]}</b>
                        </Table.Summary.Cell>
                      ))}

                      {/* Açılamadı için toplama */}
                      {dateLabels.map((period, index) => (
                        <Table.Summary.Cell
                          key={`${period.label}-notOpened`}
                          index={index + 1 + dateLabels.length * 2}
                          align="center"
                          className="summary-cell"
                        >
                          <b>{summaryTotals[`${period.label}-notOpened`]}</b>
                        </Table.Summary.Cell>
                      ))}
                    </Table.Summary.Row>
                    {/* Grand Total Row */}
                    {Object.keys(grandTotalsMap).length > 0 && (
                      <Table.Summary.Row className="summary-cell">
                        <Table.Summary.Cell
                          index={0}
                          colSpan={1}
                          className="summary-cell"
                        >
                          <Tooltip title="All times">
                            <b>Grand Total *</b>
                          </Tooltip>
                        </Table.Summary.Cell>
                        {/* Çözüldü Grand Total */}
                        <Table.Summary.Cell
                          index={1}
                          align="center"
                          colSpan={dateLabels.length}
                          className="summary-cell"
                        >
                          <div
                            className="column-group-title"
                            style={{
                              border: "3px solid #e9e9e9",
                              paddingBottom: "4px",
                              paddingTop: "4px",
                            }}
                          >
                            <b>{grandTotalsMap["Çözüldü"] || 0}</b>
                          </div>
                        </Table.Summary.Cell>
                        {/* Devam Ediyor Grand Total */}
                        <Table.Summary.Cell
                          index={2}
                          align="center"
                          colSpan={dateLabels.length}
                          className="summary-cell"
                        >
                          <div
                            className="column-group-title"
                            style={{
                              border: "3px solid #e9e9e9",
                              paddingBottom: "4px",
                              paddingTop: "4px",
                            }}
                          >
                            <b>{grandTotalsMap["Devam Ediyor"] || 0}</b>
                          </div>
                        </Table.Summary.Cell>
                        {/* Açılamadı Grand Total */}
                        <Table.Summary.Cell
                          index={3}
                          align="center"
                          colSpan={dateLabels.length}
                          className="summary-cell"
                        >
                          <div
                            className="column-group-title"
                            style={{
                              border: "3px solid #e9e9e9",
                              paddingBottom: "4px",
                              paddingTop: "4px",
                            }}
                          >
                            <b>
                              {(grandTotalsMap["Açılamadı"] || 0) +
                                (grandTotalsMap["Açılamadı - İadesi Yapıldı"] ||
                                  0)}
                            </b>
                          </div>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    )}
                  </Table.Summary>
                );
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SuspendedFileStatusReport;
