import { Col, Row, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useRef, useState } from "react";
import AmazonSuspendedApplicantReportsService from "../../../../services/AmazonSuspendedApplicantReportsService";
import dayjs from "dayjs";
import { CountryReport } from "../../../../types/AmazonSuspendedApplicantReports";
import ReusableChart from "../../CommonReport/SummaryReport/ReusableChart";

const SuspendTypeBasedSuspendedFilesByCountryReport = ({
  dateRange,
  periodCount,
}: {
  dateRange: any;
  periodCount: number;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);
  const [dateLabels, setDateLabels] = useState<any[]>([]);
  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [chartHeight, setChartHeight] = useState<number>(200);
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1600 && tableRef.current) {
        const tableColHeight = tableRef.current.getBoundingClientRect().height;
        setChartHeight(Math.max(200, tableColHeight - 30));
      } else {
        setChartHeight(250);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const periods = [];
      let start = dayjs(dateRange[0]);
      let end = dayjs(dateRange[1]);

      for (let i = 0; i < periodCount; i++) {
        const formattedStart = start.format("YYYY-MM-DDT00:00:00");
        const formattedEnd = end.format("YYYY-MM-DDT23:59:59");

        periods.push({
          start: formattedStart,
          end: formattedEnd,
          label: `${start.format("DD.MMM.YY")} - ${end.format("DD.MMM.YY")}`,
        });

        const diffDays = end.diff(start, "days") + 1;
        end = start.subtract(1, "days");
        start = end.subtract(diffDays - 1, "days");
      }

      const reversedPeriods = periods.reverse(); // Period'ları tersine çevir
      getReports(reversedPeriods);
      setDateLabels(reversedPeriods);
    }
    // eslint-disable-next-line
  }, [dateRange, periodCount]);

  const getReports = async (periods: any[]) => {
    setLoading(true);
    try {
      const promises = periods.map((period) =>
        AmazonSuspendedApplicantReportsService.getSuspendedCountryReports(
          period.start,
          period.end
        )
      );

      const responses = await Promise.all(promises);

      const suspendTypeMap: Record<string, any> = {};
      const allCountries = new Set();

      responses.forEach((response, index) => {
        const periodLabel = periods[index].label;

        response.data.countryReports.forEach((item: any) => {
          const { suspendType, suspendedCountry, totalCount } = item;

          if (!suspendTypeMap[suspendType]) {
            suspendTypeMap[suspendType] = { suspendType };
          }

          if (!suspendTypeMap[suspendType][periodLabel]) {
            suspendTypeMap[suspendType][periodLabel] = {};
          }

          suspendTypeMap[suspendType][periodLabel][suspendedCountry] =
            totalCount || 0;

          allCountries.add(suspendedCountry);
        });
      });

      const uniqueSuspendTypes = Object.values(suspendTypeMap);
      setCountries(Array.from(allCountries));
      setDataSource(uniqueSuspendTypes);

      const chartData = Array.from(allCountries).map((country: any) => {
        return {
          name: country,
          data: periods.map((period) => {
            let total = 0;
            uniqueSuspendTypes.forEach((type: any) => {
              total += type[period.label]?.[country] || 0;
            });
            return total;
          }),
        };
      });

      setChartSeries(chartData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<CountryReport> = [
    {
      title: "Suspend Type",
      dataIndex: "suspendType",
      key: "suspendType",
      align: "center",
      fixed: "left",
      width: 200,
      ellipsis: true,
      sorter: (a: any, b: any) => a.suspendType.localeCompare(b.suspendType),
      defaultSortOrder: "ascend",
      render: (suspendType: string) => (
        <div
          style={{
            textAlign: "left",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <b>{suspendType ? suspendType : "-"}</b>
        </div>
      ),
    },
    ...dateLabels.map((period) => ({
      title: (
        <div
          className="column-group-title"
          style={{
            border: "3px solid #e9e9e9",
            paddingBottom: "8px",
            paddingTop: "8px",
            marginBottom: "-16px",
          }}
        >
          {period.label}
        </div>
      ),
      align: "center" as "center",
      children: Array.from(countries).map((country) => {
        const countryKey = country as string;
        return {
          title: countryKey,
          dataIndex: [period.label, countryKey],
          key: `${period.label}-${countryKey}`,
          align: "center" as "center",
          width: 100,
          sorter: (a: any, b: any) => {
            const aValue = a[period.label]?.[countryKey] || 0;
            const bValue = b[period.label]?.[countryKey] || 0;
            return aValue - bValue;
          },
          render: (value: number) => <b>{value || 0}</b>,
        };
      }),
    })),
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40, marginTop: 40 }}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={24} xxl={14}>
            <ReusableChart
              type="bar"
              series={chartSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col>
          <Col xs={24} xl={24} xxl={10} ref={tableRef}>
            <Table
              rowKey={(record) => record?.suspendType}
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              scroll={{ x: "100%" }}
              pagination={false}
              tableLayout="fixed"
              summary={(pageData) => {
                if (!pageData.length) return null;

                // Tüm kolonlar için toplamları hesapla
                const totals: Record<string, number> = {};

                columns.slice(1).forEach((periodColumn: any) => {
                  periodColumn.children.forEach((countryColumn: any) => {
                    const dataIndex = countryColumn.dataIndex;

                    totals[dataIndex] = pageData.reduce((sum, row) => {
                      // Toplamları doğru hesaplarken `row` yapısını kontrol et
                      const periodData = row[dataIndex[0]]; // `period.label`
                      const countryData = periodData?.[dataIndex[1]]; // `suspendedCountry`
                      return sum + (countryData || 0);
                    }, 0);
                  });
                });

                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row className="summary-cell">
                      {/* İlk Kolon: Toplam Başlığı */}
                      <Table.Summary.Cell
                        index={0}
                        align="left"
                        className="summary-cell"
                      >
                        <b>Total</b>
                      </Table.Summary.Cell>

                      {/* Diğer kolonlar için toplamları göster */}
                      {columns.slice(1).map((periodColumn: any) =>
                        periodColumn.children.map((countryColumn: any) => (
                          <Table.Summary.Cell
                            key={`${periodColumn.key}-${countryColumn.key}`}
                            index={
                              periodColumn.children.indexOf(countryColumn) + 1
                            }
                            align="center"
                            className="summary-cell"
                          >
                            <b>{totals[countryColumn.dataIndex] || 0}</b>
                          </Table.Summary.Cell>
                        ))
                      )}
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SuspendTypeBasedSuspendedFilesByCountryReport;
