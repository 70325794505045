import { Col, Modal, Row, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useRef, useState } from "react";
import AmazonSuspendedApplicantReportsService from "../../../../services/AmazonSuspendedApplicantReportsService";
import dayjs from "dayjs";
import {
  CustomerDetail,
  CustomerDetailsReport,
} from "../../../../types/AmazonSuspendedApplicantReports";
import ReusableChart from "../../CommonReport/SummaryReport/ReusableChart";

const SolvedStatusBasedOneAmzAppUserReport = ({
  dateRange,
  periodCount,
}: {
  dateRange: any;
  periodCount: number;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [customerDetails, setCustomerDetails] = useState<CustomerDetail[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [dateLabels, setDateLabels] = useState<any[]>([]);
  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [chartHeight, setChartHeight] = useState<number>(200);
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200 && tableRef.current) {
        const tableColHeight = tableRef.current.getBoundingClientRect().height;
        setChartHeight(Math.max(200, tableColHeight - 30));
      } else {
        setChartHeight(250);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const periods = [];
      let start = dayjs(dateRange[0]);
      let end = dayjs(dateRange[1]);

      for (let i = 0; i < periodCount; i++) {
        const formattedStart = start.format("YYYY-MM-DDT00:00:00");
        const formattedEnd = end.format("YYYY-MM-DDT23:59:59");

        periods.push({
          start: formattedStart,
          end: formattedEnd,
          label: `${start.format("DD.MMM.YY")} - ${end.format("DD.MMM.YY")}`,
        });

        const diffDays = end.diff(start, "days") + 1;
        end = start.subtract(1, "days");
        start = end.subtract(diffDays - 1, "days");
      }

      const reversedPeriods = periods.reverse(); // Period'ları tersine çevir
      getReports(reversedPeriods);
      setDateLabels(reversedPeriods);
    }
    // eslint-disable-next-line
  }, [dateRange, periodCount]);

  const getReports = async (periods: any[]) => {
    setLoading(true);
    try {
      const promises = periods.map((period) =>
        AmazonSuspendedApplicantReportsService.getCustomerDetails(
          period.start,
          period.end
        )
      );

      const responses = await Promise.all(promises);

      const formattedData = responses.map((response, index) => ({
        ...response.data,
        period: periods[index].label,
      }));

      setDataSource(formattedData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCellClick = (
    record: CustomerDetailsReport,
    isOneAmzUser: boolean | null,
    column: string
  ) => {
    const filteredDetails = record.customerDetails?.filter(
      (detail) => isOneAmzUser === null || detail.oneAmzUser === isOneAmzUser
    );

    setCustomerDetails(filteredDetails || []);
    setModalTitle(column);
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (dataSource.length > 0) {
      const oneAmzUserSeries = dateLabels.map((period) => {
        const periodData = dataSource.find(
          (data: any) => data.period === period.label
        );
        return periodData?.oneAmzUserCount || 0;
      });

      const nonOneAmzUserSeries = dateLabels.map((period) => {
        const periodData = dataSource.find(
          (data: any) => data.period === period.label
        );
        return periodData?.nonOneAmzUserCount || 0;
      });

      const totalUserSeries = dateLabels.map((period) => {
        const periodData = dataSource.find(
          (data: any) => data.period === period.label
        );
        return periodData?.totalCount || 0;
      });

      setChartSeries([
        {
          name: "OneAmz App Users",
          data: oneAmzUserSeries,
        },
        {
          name: "Non OneAmz App Users",
          data: nonOneAmzUserSeries,
        },
        {
          name: "Total Users",
          data: totalUserSeries,
        },
      ]);
    }
  }, [dataSource, dateLabels]);

  const columns: ColumnsType<CustomerDetailsReport> = [
    {
      title: "Period",
      dataIndex: "period",
      key: "period",
      align: "center",
      fixed: "left",
      width: 180,
      sorter: (a: any, b: any) => {
        const dateA = dayjs(a.period.split(" - ")[0], "DD.MMM.YY");
        const dateB = dayjs(b.period.split(" - ")[0], "DD.MMM.YY");
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      },
      defaultSortOrder: "descend",
      render: (period: string) => <b>{period ? period : "-"}</b>,
    },
    {
      title: "OneAmz App User Count",
      dataIndex: "oneAmzUserCount",
      key: "oneAmzUserCount",
      align: "center",
      width: 150,
      sorter: (a, b) => a.oneAmzUserCount - b.oneAmzUserCount,
      render: (oneAmzUserCount: number, record) => (
        <div
          onClick={() =>
            handleCellClick(record, true, "OneAmz App Users Details")
          }
          style={{ cursor: "pointer" }}
        >
          <b>{oneAmzUserCount !== undefined ? oneAmzUserCount : "0"}</b>
        </div>
      ),
    },
    {
      title: "Non OneAmz App User Count",
      dataIndex: "nonOneAmzUserCount",
      key: "nonOneAmzUserCount",
      align: "center",
      width: 150,
      sorter: (a, b) => a.nonOneAmzUserCount - b.nonOneAmzUserCount,
      render: (nonOneAmzUserCount: number, record) => (
        <div
          onClick={() =>
            handleCellClick(record, false, "Non OneAmz App Users Details")
          }
          style={{ cursor: "pointer" }}
        >
          <b>{nonOneAmzUserCount !== undefined ? nonOneAmzUserCount : "0"}</b>
        </div>
      ),
    },
    {
      title: "Total User Count",
      dataIndex: "totalCount",
      key: "totalCount",
      align: "center",
      width: 150,
      sorter: (a, b) => a.totalCount - b.totalCount,
      render: (totalCount: number, record) => (
        <div
          onClick={() => handleCellClick(record, null, "All Users Details")}
          style={{ cursor: "pointer" }}
        >
          <b>{totalCount !== undefined ? totalCount : "0"}</b>
        </div>
      ),
    },
  ];

  const customerDetailsColumns: ColumnsType<CustomerDetail> = [
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      fixed: "left",
      width: 200,
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.customerName.localeCompare(b.customerName),
      render: (text: string) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={text}
        >
          <b
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {text}
          </b>
        </div>
      ),
    },
    {
      title: "Resolved Date",
      dataIndex: "resolvedAt",
      key: "resolvedAt",
      align: "center",
      width: 150,
      sorter: (a, b) =>
        a.resolvedAt && b.resolvedAt
          ? dayjs(a.resolvedAt).unix() - dayjs(b.resolvedAt).unix()
          : 0,
      render: (resolvedAt: string) => (
        <div>{resolvedAt ? dayjs(resolvedAt).format("DD MMM YYYY") : "-"}</div>
      ),
    },
    ...(modalTitle === "All Users Details"
      ? [
          {
            title: "OneAmz User",
            dataIndex: "oneAmzUser",
            key: "oneAmzUser",
            align: "center" as "center",
            width: 120,
            sorter: (a: any, b: any) => {
              if (a.oneAmzUser && !b.oneAmzUser) return -1;
              if (!a.oneAmzUser && b.oneAmzUser) return 1;
              return 0;
            },
            render: (text: boolean) => (
              <Tag color={text === true ? "green" : "red"}>
                {text === true ? "Yes" : "No"}
              </Tag>
            ),
          },
        ]
      : []),
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40, marginTop: 40 }}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={14} xxl={14}>
            <ReusableChart
              type="bar"
              series={chartSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col>
          <Col xs={24} xl={10} xxl={10} ref={tableRef}>
            <Table
              rowKey={(record) => record?.period}
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              scroll={{ x: "100%" }}
              pagination={false}
              tableLayout="fixed"
            />
            <Modal
              title={modalTitle}
              open={isModalVisible}
              onCancel={() => setIsModalVisible(false)}
              footer={null}
              width={"60%"}
            >
              <Table
                rowKey={(record) => record?.customerName}
                loading={loading}
                dataSource={customerDetails}
                columns={customerDetailsColumns}
                scroll={{ x: "max-content", y: "200px" }}
                pagination={false}
                tableLayout="fixed"
                size="small"
                className="zebra-table"
              />
            </Modal>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SolvedStatusBasedOneAmzAppUserReport;
