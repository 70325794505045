import { Col, Row, Table } from "antd";
import { useEffect, useRef, useState } from "react";
import { ColumnsType } from "antd/es/table";
import CustomerAssignmentHistoryService from "../../../../services/CustomerAssignmentHistoryService";
import dayjs from "dayjs";
// import { useLocation } from "react-router-dom";
import ReusableChart from "./ReusableChart";

const ReasonForDropoutReport = ({
  memberFlag,
  dateRange,
  periodCount,
}: {
  memberFlag: string;
  dateRange: any;
  periodCount: number;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dateLabels, setDateLabels] = useState<any[]>([]);
  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [chartHeight, setChartHeight] = useState<number>(200);
  // const location = useLocation();
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200 && tableRef.current) {
        const tableColHeight = tableRef.current.getBoundingClientRect().height;
        setChartHeight(Math.max(200, tableColHeight - 30));
      } else {
        setChartHeight(250);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1] && memberFlag) {
      const periods = [];
      let start = dayjs(dateRange[0]);
      let end = dayjs(dateRange[1]);

      for (let i = 0; i < periodCount; i++) {
        const formattedStart = start.format("YYYY-MM-DDT00:00:00");
        const formattedEnd = end.format("YYYY-MM-DDT23:59:59");

        periods.push({
          start: formattedStart,
          end: formattedEnd,
          label: `${start.format("DD.MMM.YY")} - ${end.format("DD.MMM.YY")}`,
        });

        const diffDays = end.diff(start, "days") + 1;
        end = start.subtract(1, "days");
        start = end.subtract(diffDays - 1, "days");
      }

      const reversedPeriods = periods.reverse(); // Period'ları tersine çevir
      getReports(reversedPeriods, memberFlag);
      setDateLabels(reversedPeriods);
    }
    // eslint-disable-next-line
  }, [dateRange, memberFlag, periodCount]);

  const getReports = async (periods: any[], memberFlag: string) => {
    setLoading(true);

    try {
      const parentGroups: Record<string, any> = {}; // Unique parentStatus grubu

      // Tüm dönemler için API çağrıları
      const responses = await Promise.all(
        periods.map((period) =>
          CustomerAssignmentHistoryService.getStatusBasedOldMemberFlagReport(
            period.start,
            period.end,
            memberFlag
          )
        )
      );

      // Her dönem için gelen verileri işle
      responses.forEach((response, periodIndex) => {
        response.data.forEach((item: any) => {
          const parentName = item.status.parent?.name || item.status.name;
          const parentId = item.status.parent?.id || item.status.id;

          // Parent status unique değilse oluştur
          if (!parentGroups[parentId]) {
            parentGroups[parentId] = {
              key: parentId,
              parentStatus: parentName,
              parentCustomerCount: Array(periods.length).fill(0), // Her dönem için toplamlar
              subStatuses: [],
            };
          }

          // Mevcut döneme değer ekle
          parentGroups[parentId].parentCustomerCount[periodIndex] +=
            item.customerCount;

          // SubStatuses işle
          if (item.status.parent) {
            const existingSubStatus = parentGroups[parentId].subStatuses.find(
              (sub: any) => sub.key === item.status.id
            );

            // SubStatus yoksa ekle
            if (!existingSubStatus) {
              parentGroups[parentId].subStatuses.push({
                key: item.status.id,
                statusName: item.status.name,
                customerCount: Array(periods.length).fill(0),
              });
            }

            // SubStatus'un mevcut dönemine değer ekle
            const subStatus = parentGroups[parentId].subStatuses.find(
              (sub: any) => sub.key === item.status.id
            );
            if (subStatus) {
              subStatus.customerCount[periodIndex] += item.customerCount;
            }
          }
        });
      });

      // Sonuçları tablo için uygun forma dönüştür
      const groupedData = Object.values(parentGroups);
      setDataSource(groupedData);

      // Grafik serisini oluştur
      const series = groupedData.map((group: any) => ({
        name: group.parentStatus,
        data: group.parentCustomerCount,
      }));

      setChartSeries(series);
      // setDateLabels([...periods].reverse());
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "Parent Status",
      dataIndex: "parentStatus",
      key: "parentStatus",
      width: 200,
      fixed: "left",
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a: any, b: any) => a.parentStatus.localeCompare(b.parentStatus),
      render: (text: any, record: any) => {
        const formattedText = text
          .toLowerCase()
          .split("_")
          .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");

        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={formattedText}
          >
            <b>{formattedText}</b>
          </div>
        );
      },
    },
    ...dateLabels.map((label: any, index: number) => ({
      title: label.label,
      key: `parentCustomerCount_${index}`,
      dataIndex: ["parentCustomerCount", index],
      align: "center" as "center",
      width: 175,
      render: (text: any) => <b>{text ?? 0}</b>,
    })),
  ];

  const expandedRowRender = (record: any) => {
    const childColumns: ColumnsType<any> = [
      {
        title: "Sub Reason Name",
        dataIndex: "statusName",
        key: "statusName",
        fixed: "left",
        width: 200,
        ellipsis: true,
        defaultSortOrder: "descend",
        sorter: (a: any, b: any) => a.statusName.localeCompare(b.statusName),
        render: (text: any) => {
          const formattedText = text
            .toLowerCase()
            .split("_")
            .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");

          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              title={formattedText}
            >
              <b>{formattedText}</b>
            </div>
          );
        },
      },
      ...dateLabels.map((label: any, index: number) => ({
        title: label.label,
        dataIndex: ["customerCount", index],
        align: "center" as "center",
        width: 175,
        render: (count: any) => <b>{count ?? 0}</b>,
      })),
    ];

    return (
      <Table
        columns={childColumns}
        dataSource={record.subStatuses}
        pagination={false}
        rowKey={(item) => item?.key || item?.status?.id}
        size="small"
        tableLayout="fixed"
        // scroll={{ x: 'max-content' }}
      />
    );
  };

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40, marginTop: 40 }}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={14}>
            <ReusableChart
              type="bar"
              series={chartSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col>
          <Col xs={24} xl={10} ref={tableRef}>
            <div style={{ overflowX: "auto", width: "100%" }}>
              <Table
                rowKey={(record) => record?.key || record?.status?.id}
                loading={loading}
                dataSource={dataSource}
                columns={columns}
                // scroll={{ x: "max-content" }}
                pagination={false}
                expandable={{
                  expandedRowRender,
                }}
                tableLayout="fixed"
                className="summary-report-table"
                summary={(pageData) => {
                  const totalCounts = dateLabels.map((_, index) => {
                    return pageData.reduce((total, record) => {
                      return total + (record.parentCustomerCount[index] || 0);
                    }, 0);
                  });

                  return (
                    <Table.Summary fixed>
                      <Table.Summary.Row className="summary-cell">
                        <Table.Summary.Cell
                          index={0}
                          colSpan={2}
                          align="left"
                          className="summary-cell"
                        >
                          <b>Total</b>
                        </Table.Summary.Cell>
                        {totalCounts.map((total, index) => (
                          <Table.Summary.Cell
                            key={index}
                            index={index + 2}
                            align="center"
                            className="summary-cell"
                          >
                            <b>{total}</b>
                          </Table.Summary.Cell>
                        ))}
                      </Table.Summary.Row>
                    </Table.Summary>
                  );
                }}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ReasonForDropoutReport;
