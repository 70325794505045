import React, { useState, useEffect } from "react";
import { Card, Col, Row, Table, Tag } from "antd";
import AdditionalPackageHistoryService from "../../services/AdditionalPackageHistoryService";
import moment from "moment";

interface AdditionalPackageHistoryCardProps {
  email: number;
}

const AdditionalPackageHistoryCard: React.FC<
  AdditionalPackageHistoryCardProps
> = ({
  email,
}) => {
  const [packageHistory, setPackageHistory] = useState<any[]>([]);

  const getAdditionalPackageHistory = () => {
    if (email) {
      AdditionalPackageHistoryService.getByEmail(email)
        .then((response) => {
          setPackageHistory(response.data);
        })
        .catch((error: Error) => {
          console.error(error);
        });
    }
  };
  useEffect(() => {
    getAdditionalPackageHistory();
    // eslint-disable-next-line
  }, [email]);

  const columns = [
    {
      title: <span style={{ fontSize: 12 }}>Package Name</span>,
      dataIndex: "packageName",
      key: "packageName",
      ellipsis: true,
      width: 100,
      sorter: (a: any, b: any) => a.packageName.localeCompare(b.packageName),
      render: (text: any) => <span style={{ fontSize: 12 }}>{text}</span>,
    },
    {
      title: <span style={{ fontSize: 12 }}>Start Date</span>,
      dataIndex: "startDate",
      key: "startDate",
      ellipsis: true,
      width: 90,
      align: "center" as const,
      sorter: (a: any, b: any) => a.startDate - b.startDate,
      defaultSortOrder: "descend" as const,
      render: (text: any) => (
        <span style={{ fontSize: 12 }}>
          {text ? moment(text).format("DD/MM/YYYY HH:mm") : ""}
        </span>
      ),
    },
    {
      title: <span style={{ fontSize: 12 }}>End Date</span>,
      dataIndex: "endDate",
      key: "endDate",
      ellipsis: true,
      width: 90,
      align: "center" as const,
      sorter: (a: any, b: any) => a.endDate - b.endDate,
      render: (text: any) => (
        <span style={{ fontSize: 12 }}>
          {text ? moment(text).format("DD/MM/YYYY HH:mm") : ""}
        </span>
      ),
    },
    {
      title: <span style={{ fontSize: 12 }}>Status</span>,
      key: "status",
      width: 90,
      align: "center" as const,
      sorter: (a: any, b: any) => {
        const aIsActive = moment(a.endDate).isAfter(moment());
        const bIsActive = moment(b.endDate).isAfter(moment());
        return aIsActive === bIsActive ? 0 : aIsActive ? -1 : 1;
      },
      render: (text: any, record: any) => {
        const isActive = moment(record.endDate).isAfter(moment());
        return (
          <Tag
            style={{
              color: isActive ? "green" : "red",
              fontSize: "11px",
              textAlign: "center",
            }}
          >
            {isActive ? "ACTIVE" : "SUSPENDED"}
          </Tag>
        );
      },
    },
  ];

  return (
    <Card bordered={false} className={"sales-card"}>
      <p className="title">Additional Package History</p>
      <Row style={{ marginTop: "24px", height: "100%" }}>
        <Col span={24}>
          <Table
            rowKey={(record: any) => record.id}
            columns={columns}
            dataSource={packageHistory}
            pagination={false}
            scroll={{ x: "100%", y: 360 }}
            size="small"
            bordered
            className="dashboard-table"
          />
        </Col>
      </Row>
    </Card>
  );
};

export default AdditionalPackageHistoryCard;
