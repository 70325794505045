import { Col, Row, Statistic, Table, Typography } from "antd";
import CallTeamReportService from "../../../../services/CallTeamReportService";
import React, { useEffect, useRef, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { PhoneOutlined, ClockCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import ReusableChart from "../../CommonReport/SummaryReport/ReusableChart";

const { Text } = Typography;

const CtmSourceBasedPhoneCallSummaryReport = ({
  dateRange,
  periodCount,
}: {
  dateRange: any;
  periodCount: number;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dateLabels, setDateLabels] = useState<any[]>([]);
  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [chartHeight, setChartHeight] = useState<number>(200);
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1600 && tableRef.current) {
        const tableColHeight = tableRef.current.getBoundingClientRect().height;
        setChartHeight(Math.max(200, tableColHeight - 30));
      } else {
        setChartHeight(250);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const periods = [];
      let start = dayjs(dateRange[0]);
      let end = dayjs(dateRange[1]);

      for (let i = 0; i < periodCount; i++) {
        const formattedStart = start.format("YYYY-MM-DDT00:00:00");
        const formattedEnd = end.format("YYYY-MM-DDT23:59:59");

        periods.push({
          start: formattedStart,
          end: formattedEnd,
          label: `${start.format("DD.MMM.YY")} - ${end.format("DD.MMM.YY")}`,
        });

        const diffDays = end.diff(start, "days") + 1;
        end = start.subtract(1, "days");
        start = end.subtract(diffDays - 1, "days");
      }

      const reversedPeriods = periods.reverse();
      getReports(reversedPeriods);
      setDateLabels(reversedPeriods);
    }
    // eslint-disable-next-line
  }, [dateRange, periodCount]);

  const getReports = async (periods: any[]) => {
    setLoading(true);
    try {
      const promises = periods.map((period) =>
        CallTeamReportService.getSourceBasedPhoneCallSummaryReport(
          period.start,
          period.end
        )
      );

      const responses = await Promise.all(promises);
      const formattedData = formatData(responses, periods);
      setDataSource(formattedData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const formatData = (responses: any[], periods: any[]) => {
    const memberMap = new Map();

    responses.forEach((response, periodIndex) => {
      response.data
        .filter((source: any) => source.source?.active)
        .forEach((source: any) => {
          source.phoneCallSummaryResponseDtoList.forEach((record: any) => {
            const userId = record.userLiteResponseDto?.id;

            if (!memberMap.has(userId)) {
              memberMap.set(userId, {
                key: userId,
                firstName: record.userLiteResponseDto?.firstName,
                lastName: record.userLiteResponseDto?.lastName,
                periods: Array.from({ length: periods.length }, () => ({
                  totalCalls: 0,
                  answered: 0,
                  missed: 0,
                })),
                details: [],
              });
            }

            const memberData = memberMap.get(userId);
            if (!memberData.periods[periodIndex]) {
              memberData.periods[periodIndex] = {
                totalCalls: record.totalCalls || 0,
                answered: record.answered || 0,
                missed: record.missed || 0,
              };
            } else {
              memberData.periods[periodIndex].totalCalls +=
                record.totalCalls || 0;
              memberData.periods[periodIndex].answered += record.answered || 0;
              memberData.periods[periodIndex].missed += record.missed || 0;
            }

            const existingSource = memberData.details.find(
              (detail: any) => detail.sourceName === source.source?.name
            );

            if (!existingSource) {
              // Yeni bir sourceName ekleniyorsa, tüm periodIndex'leri doldur
              const newSource = {
                sourceName: source.source?.name,
                periods: {} as any,
              };
              periods.forEach((_: any, pIndex: number) => {
                newSource.periods[pIndex] = {
                  totalCalls: 0,
                  answered: 0,
                  missed: 0,
                  answeredRatio: 0,
                  durationTimeAvg: 0,
                };
              });
              memberData.details.push(newSource);
            }

            // Varolan kaydı güncelle
            const sourceData = memberData.details.find(
              (detail: any) => detail.sourceName === source.source?.name
            );
            if (sourceData) {
              sourceData.periods[periodIndex] = {
                totalCalls: record.totalCalls || 0,
                answered: record.answered || 0,
                missed: record.missed || 0,
                answeredRatio: record.answeredCallRatio || 0,
                durationTimeAvg: record.durationTimeAvg || 0,
              };
            }
          });
        });
    });
    memberMap.forEach((memberData: any) => {
      memberData.details = memberData.details.filter((detail: any) => {
        // Her bir detay için tüm period'lardaki değerleri kontrol et
        return !Object.values(detail.periods).every(
          (period: any) =>
            period.totalCalls === 0 &&
            period.durationTimeAvg === 0.0 &&
            (period.answered === null || period.answered === 0) &&
            (period.missed === null || period.missed === 0) &&
            period.answeredRatio === 0.0
        );
      });
    });
    return Array.from(memberMap.values());
  };

  const columns: ColumnsType<any> = [
    {
      title: "Call Team Member",
      dataIndex: "firstName",
      key: "firstName",
      width: 300,
      fixed: "left",
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a, b) => a?.firstName.localeCompare(b?.firstName),
      render: (_, record) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={`${record?.firstName} ${record?.lastName}`}
        >
          <b>
            {record?.firstName} {record?.lastName}
          </b>
        </div>
      ),
    },
    ...dateLabels.map((period: any, index: number) => ({
      title: (
        <div
          className="column-group-title"
          style={{
            border: "3px solid #e9e9e9",
            paddingBottom: "8px",
            paddingTop: "8px",
            minWidth: 180,
          }}
        >
          {period.label}
        </div>
      ),
      align: "center" as "center",
      children: [
        {
          title: "Total Calls",
          dataIndex: ["periods", index, "totalCalls"],
          key: `totalCalls_${index}`,
          align: "center" as "center",
          width: 150,
          render: (_: any, record: any) => (
            <Statistic
              value={record?.periods?.[index]?.totalCalls || 0}
              prefix={<PhoneOutlined />}
              valueStyle={{ fontSize: "14px", fontWeight: "400" }}
            />
          ),
        },
        {
          title: "Answered",
          dataIndex: ["periods", index, "answered"],
          key: `answered_${index}`,
          align: "center" as "center",
          width: 150,
          render: (_: any, record: any) => (
            <Statistic
              value={record?.periods?.[index]?.answered || 0}
              prefix={<PhoneOutlined />}
              valueStyle={{
                color: "#3f8600",
                fontSize: "14px",
                fontWeight: "400",
              }}
            />
          ),
        },
        {
          title: "Missed",
          dataIndex: ["periods", index, "missed"],
          key: `missed_${index}`,
          align: "center" as "center",
          width: 150,
          render: (_: any, record: any) => (
            <Statistic
              value={record?.periods?.[index]?.missed || 0}
              prefix={<PhoneOutlined />}
              valueStyle={{
                color: "#cf1322",
                fontSize: "14px",
                fontWeight: "400",
              }}
            />
          ),
        },
      ],
    })),
  ];

  const expandedRowRender = (record: any) => {
    const childColumns: ColumnsType<any> = [
      {
        title: "Source Name",
        dataIndex: "sourceName",
        key: "sourceName",
        width: 300,
        fixed: "left",
        ellipsis: true,
        defaultSortOrder: "ascend",
        sorter: (a: any, b: any) => a?.sourceName.localeCompare(b?.sourceName),
        render: (text: any) => (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={text}
          >
            <b>{text}</b>
          </div>
        ),
      },
      ...dateLabels.map((period: any, periodIndex: number) => ({
        title: (
          <div
            className="column-group-title"
            style={{
              border: "3px solid #e9e9e9",
              paddingBottom: "8px",
              paddingTop: "8px",
              minWidth: 180,
            }}
          >
            {period.label}
          </div>
        ),
        align: "center" as "center",
        children: [
          {
            title: "Total Calls",
            key: `totalCalls_${periodIndex}`,
            align: "center" as "center",
            width: 100,
            render: (_: any, sourceRecord: any) =>
              sourceRecord.periods[periodIndex] && (
                <Statistic
                  value={sourceRecord.periods[periodIndex]?.totalCalls || 0}
                  prefix={<PhoneOutlined />}
                  valueStyle={{ fontSize: "14px", fontWeight: "400" }}
                />
              ),
          },
          {
            title: "Answered",
            key: `answered_${periodIndex}`,
            align: "center" as "center",
            width: 100,
            render: (_: any, sourceRecord: any) =>
              sourceRecord.periods[periodIndex] && (
                <Statistic
                  value={sourceRecord.periods[periodIndex]?.answered || 0}
                  prefix={<PhoneOutlined />}
                  valueStyle={{
                    color: "#3f8600",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                />
              ),
          },
          {
            title: "Missed",
            key: `missed_${periodIndex}`,
            align: "center" as "center",
            width: 100,
            render: (_: any, sourceRecord: any) =>
              sourceRecord.periods[periodIndex] && (
                <Statistic
                  value={sourceRecord.periods[periodIndex]?.missed || 0}
                  prefix={<PhoneOutlined />}
                  valueStyle={{
                    color: "#cf1322",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                />
              ),
          },
          {
            title: "Answered Ratio",
            key: `answeredRatio_${periodIndex}`,
            align: "center" as "center",
            width: 100,
            render: (_: any, sourceRecord: any) =>
              sourceRecord.periods[periodIndex] && (
                <Statistic
                  value={sourceRecord.periods[periodIndex]?.answeredRatio || 0}
                  suffix="%"
                  valueStyle={{ fontSize: "14px", fontWeight: "400" }}
                />
              ),
          },
          {
            title: "Average Call Duration",
            key: `durationTimeAvg_${periodIndex}`,
            align: "center" as "center",
            width: 100,
            render: (_: any, sourceRecord: any) =>
              sourceRecord.periods[periodIndex] && (
                <Statistic
                  value={
                    sourceRecord.periods[periodIndex]?.durationTimeAvg || 0
                  }
                  suffix="s"
                  prefix={<ClockCircleOutlined />}
                  valueStyle={{ fontSize: "14px", fontWeight: "400" }}
                />
              ),
          },
        ],
      })),
    ];

    const sourceData = record.details || [];

    return (
      <Table
        columns={childColumns}
        dataSource={sourceData}
        pagination={false}
        scroll={{ x: "100%" }}
        rowKey={(record) => `${record.sourceName}`}
        size="small"
        tableLayout="fixed"
      />
    );
  };

  useEffect(() => {
    if (dataSource.length > 0 && dateLabels.length > 0) {
      // Grafik verilerini member bazlı toplama
      const memberChartData = dataSource.map((record: any) => ({
        name: `${record.firstName} ${record.lastName}`,
        data: dateLabels.map(
          (_, index) => record?.periods?.[index]?.totalCalls || 0
        ),
      }));
      setChartSeries(memberChartData);
    }
  }, [dataSource, dateLabels]);

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40, marginTop: 40 }}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={24} xxl={14}>
            <ReusableChart
              type="bar"
              series={chartSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col>
          <Col xs={24} xl={24} xxl={10} ref={tableRef}>
            <Text style={{ margin: "0 0 5px 0" }} type="warning">
              ** The source values, for which there is no record of a phone call, were concealed.
            </Text>
            <Table
              rowKey={(record) =>
                `${record.key}_${record.firstName}_${record.lastName}`
              }
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              scroll={{ x: "100%" }}
              pagination={false}
              tableLayout="fixed"
              expandable={{
                expandedRowRender,
              }}
              summary={() => {
                const summaryTotals = dateLabels.map((_, index) => {
                  return dataSource.reduce(
                    (acc, record) => {
                      const periodData = record?.periods?.[index] || {};
                      acc.totalCalls += periodData.totalCalls || 0;
                      acc.answered += periodData.answered || 0;
                      acc.missed += periodData.missed || 0;
                      return acc;
                    },
                    { totalCalls: 0, answered: 0, missed: 0 }
                  );
                });

                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row className="summary-cell">
                      <Table.Summary.Cell
                        index={0}
                        colSpan={2}
                        align="left"
                        className="summary-cell"
                      >
                        <b>Total</b>
                      </Table.Summary.Cell>
                      {summaryTotals.map((total, index) => (
                        <React.Fragment key={`summary_period_${index}`}>
                          <Table.Summary.Cell
                            index={index + 2}
                            align="center"
                            className="summary-cell"
                          >
                            <Statistic
                              value={total.totalCalls}
                              prefix={<PhoneOutlined />}
                              valueStyle={{
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            />
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            index={index + 3}
                            align="center"
                            className="summary-cell"
                          >
                            <Statistic
                              value={total.answered}
                              prefix={<PhoneOutlined />}
                              valueStyle={{
                                color: "#3f8600",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            />
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            index={index + 4}
                            align="center"
                            className="summary-cell"
                          >
                            <Statistic
                              value={total.missed}
                              prefix={<PhoneOutlined />}
                              valueStyle={{
                                color: "#cf1322",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            />
                          </Table.Summary.Cell>
                        </React.Fragment>
                      ))}
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CtmSourceBasedPhoneCallSummaryReport;
