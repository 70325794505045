import React from "react";
import ReactApexChart from "react-apexcharts";

interface ReusableChartProps {
  type: "line" | "bar" | "area";
  series: any[];
  categories: string[];
  height?: number;
}

// const generateVibrantColor = () => {
//   const minBrightness = 100; // Minimum parlaklık
//   const r = Math.floor(Math.random() * (255 - minBrightness) + minBrightness);
//   const g = Math.floor(Math.random() * (255 - minBrightness) + minBrightness);
//   const b = Math.floor(Math.random() * (255 - minBrightness) + minBrightness);
//   return `rgb(${r}, ${g}, ${b})`;
// };

// // Pastel renk dizisi oluşturucu
// const generatePastelColors = (length: number) =>
//   Array.from({ length }, generateVibrantColor);

const ReusableChart = React.memo(
  ({
    type,
    series = [],
    categories = [],
    height = 200,
  }: ReusableChartProps) => {
    const calculateColumnWidth = (series: string[]) => {
      const totalBars = series.length; // Toplam bar sayısı
      if (totalBars <= 5) return "30px"; // Bar azsa geniş yap
      if (totalBars > 5) return "90%"; // Orta sayıda bar için daralt
      return `${Math.max(10, 100 / totalBars)}%`; // Çok bar varsa dinamik oran
    };

    // const pastelColors = generatePastelColors(series.length);

    // const contrastColors = [
    //   "#FF6F61", // Coral
    //   "#6B5B95", // Purple
    //   "#88B04B", // Green
    //   "#F7CAC9", // Pink
    //   "#92A8D1", // Blue
    //   "#955251", // Brown
    //   "#B565A7", // Violet
    //   "#009B77", // Teal
    //   "#DD4124", // Red
    //   "#45B8AC", // Turquoise
    // ];
    
    // const flatUiColors = [
    //   "#1abc9c", // Turquoise
    //   "#2ecc71", // Emerald
    //   "#3498db", // Peter River
    //   "#9b59b6", // Amethyst
    //   "#34495e", // Wet Asphalt
    //   "#f1c40f", // Sunflower
    //   "#e67e22", // Carrot
    //   "#e74c3c", // Alizarin
    //   "#95a5a6", // Concrete
    //   "#7f8c8d", // Asbestos
    // ];

    const vibrantColors = [
      "#FFC107", // Amber
      "#2196F3", // Blue
      "#9C27B0", // Purple
      "#FF5722", // Deep Orange
      "#8BC34A", // Light Green
      "#00BCD4", // Cyan
      "#E91E63", // Pink
      "#3F51B5", // Indigo
      "#FF9800", // Orange
      "#4CAF50", // Green
    ];  

    const options = {
      chart: {
        type,
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
      colors: vibrantColors,
      // colors: pastelColors, // Pastel renkleri burada kullanıyoruz
      plotOptions: {
        bar: {
          horizontal: false, // Barların yatay mı dikey mi olacağını belirler
          columnWidth: calculateColumnWidth(series), // Bar genişliğini ayarlar
          borderRadius: 4, // Piksel cinsinden yuvarlaklık.
          endingShape: "rounded", // Barların uç şekli ('flat', 'rounded')
          dataLabels: {
            position: "top", // Veri etiketlerinin konumu ('top', 'center', 'bottom')
          },
        },
        area: {
          fillTo: "origin" as "origin", // Grafiğin nereden doldurulacağını belirtir ('origin', 'end')
        },
      },
      fill: {
        type: "gradient", // Alanın doldurma tipi ('solid', 'gradient')
        gradient: {
          shade: "dark", // Gradyanın tonu ('light', 'dark')
          type: "diagonal2", // Gradyan yönü ('horizontal', 'vertical', 'diagonal1', 'diagonal2')
          shadeIntensity: 0.8, // Gradyanın yoğunluğu (0 ile 1 arasında)
          gradientToColors: ["#FDD835"], // Gradyanın diğer rengi
          inverseColors: true, // Gradyanı ters çevirir
          opacityFrom: 0.9, // Doldurma alanı için başlangıç opaklığı
          opacityTo: 0.1, // Doldurma alanı için bitiş opaklığı
          stops: [0, 90, 100], // Gradyan durakları
        },
      },      
      grid: {
        padding: {
          left: 40,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "12px", // Yazı boyutu
          fontWeight: "bold", // Yazı kalınlığı
          colors: ["#000"], // Yazı rengi (örneğin siyah)
        },
      },
      stroke: {
        width: 2,
        curve: "smooth" as "smooth",
      },
      xaxis: {
        categories,
        tooltip: {
          enabled: false,
        },
        labels: {
          formatter: (value: any) => {
            if (typeof value !== "string") return "";
            const maxLength = window.innerWidth < 768 ? 10 : value.length;
            return value.length > maxLength
              ? `${value.substring(0, maxLength)}...`
              : value;
          },
        },
      },
    };

    return (
      <ReactApexChart
        options={options}
        series={series}
        type={type}
        height={height}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.type === nextProps.type &&
      JSON.stringify(prevProps.series) === JSON.stringify(nextProps.series) &&
      JSON.stringify(prevProps.categories) ===
        JSON.stringify(nextProps.categories) &&
      prevProps.height === nextProps.height
    );
  }
);

export default ReusableChart;
