import { useEffect, useState } from "react";
import AmazonSuspendedApplicantService from "../../../services/AmazonSuspendedApplicantService";
import { useLocation, useNavigate } from "react-router-dom";
import UserService from "../../../services/UserService";
import {
  Button,
  Card,
  Pagination,
  Row,
  Select,
  Table,
  Tag,
  type PaginationProps,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import DownloadExcel from "../../CustomerDetail/DownloadExcel";
import { convertDate, openNotification } from "../../../utils";
import { StartCall } from "../../General/StartCall";
import { CopyClipboard } from "../../General/CopyClipboard";
import { FilterField } from "./FilterField";
import NewTask from "../../AmazonSuspendedApplicantDetail/Tasks/NewTask";
import ImportExcel from "../../AmazonSuspendedApplicantDetail/ImportExcel";
import { ManuelEntry } from "../../AmazonSuspendedApplicantDetail/ManuelEntry";
import StatusService from "../../../services/StatusService";

interface User {
  createdAt: string;
  id: number;
  fullName: string;
  email: string;
  phone: string;
  username: string;
  buyerEmail: string;
  sellerEmail: string;
  oneamzEmail: string;
  accountPassword: string;
  serverIp: string;
  serverPassword: string;
  source: {
    id: number;
    name: string;
    sourceKey: string;
    active: boolean;
  };
  suspendTeamMember: {
    id: number;
    username: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    meetPortalEmployeeUrl: string;
    memberFlag: string;
  };
  amazonSuspendedApplicantStatus: {
    id: number;
    name: string;
    active: boolean;
    parent: string;
  };
  amazonSuspendedApplicantInput: {
    id: number;
    name: string;
    active: boolean;
    parent: string;
  };
  amazonSuspendedApplicantSuspendType: {
    id: number;
    name: string;
    active: boolean;
    parent: string;
  };
  callCenterSalesPackage: {
    name: string;
  };
  additionalPackageHistory: {
    id: number;
    email: string;
    packageId: number;
    packageName: string;
    startDate: string;
    endDate: string;
    createdAtOneamz: string;
    paidAmount: number;
  };
  attachmentList: Array<{
    id: number;
    fileName: string;
  }>;
  noteList: Array<{
    id: number;
    description: string;
  }>;
  assignmentDateStart: string;
  assignmentDateEnd: string;
  uptime: number;
  serviceType: {
    id: number;
    name: string;
    active: boolean;
    parent: string;
  };
  numberOfViolations: number;
  numberOfViolationsPaid: number;
  suspendedCountry: string;
  numberOfAmazonCalls: number;
  oneamzAppStatus: boolean;
  actionType: {
    id: number;
    name: string;
    active: true;
    parent: string;
  };
  actionDate: string;
  actionTypeHistoryList: Array<{
    id: number;
    actionType: {
      id: number;
      name: string;
      active: true;
      parent: string;
    };
    actionDate: string;
    actionBy: {
      id: number;
      username: string;
      firstName: string;
      lastName: string;
      phoneNumber: string;
      meetPortalEmployeeUrl: string;
      memberFlag: string;
    };
  }>;
}

export const AmazonSuspendedApplicantList: React.FC<any> = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [totalCustomerCount, setTotalCustomerCount] = useState(0);
  const roleName = localStorage.getItem("roleName");
  const authUserId = localStorage.getItem("id");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customerStatusOptions, setCustomerStatusOptions] = useState<any[]>([]);
  const [customerSuspendTypeOptions, setCustomerSuspendTypeOptions] = useState<
    any[]
  >([]);
  const [
    customerSuspendServiceTypeOptions,
    setCustomerSuspendServiceTypeOptions,
  ] = useState<any[]>([]);
  const [customerActionTypeOptions, setCustomerActionTypeOptions] = useState<
    any[]
  >([]);
  const [numberOfAmazonCallsOptions, setNumberOfAmazonCallsOptions] = useState<
    any[]
  >([]);
  const [suspendedCountryOptions, setSuspendedCountryOptions] = useState<any[]>(
    []
  );
  const [numberOfViolationsOptions, setNumberOfViolationsOptions] = useState<
    any[]
  >([]);
  const [customerInputOptions, setCustomerInputOptions] = useState<any[]>([]);
  const [userOptions, setUserOptions] = useState<any[]>([]);
  const [adminUserOptions, setAdminUserOptions] = useState<any[]>([]);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const location = useLocation();
  const lastCurrentPage = location.state?.currentPage ?? 0;
  const lastPageSize = location.state?.pageSize ?? 20;
  const lastFormFields = location.state?.formFields ?? {};
  const [formFields, setFormFields] = useState(lastFormFields);
  const [filterText, setFilterText] = useState<string>(
    localStorage.getItem("amazonSuspendedFilterText") ||
      `?page=${lastCurrentPage}&size=${lastPageSize}`
  );
  const [currentPage, setCurrentPage] = useState<number>(lastCurrentPage);
  const [pageSize, setPageSize] = useState<number>(lastPageSize);
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterOptions, setFilterOptions] = useState([]);

  useEffect(() => {
    if (customerStatusOptions.length === 0) {
      getCustomerStatus();
    }
    if (customerSuspendTypeOptions.length === 0) {
      getCustomerSuspendType();
    }
    if (customerSuspendServiceTypeOptions.length === 0) {
      getCustomerServiceType();
    }
    if (customerActionTypeOptions.length === 0) {
      getCustomerActionType();
    }
    if (customerInputOptions.length === 0) {
      getCustomerInput();
    }
    // eslint-disable-next-line
  }, [
    customerStatusOptions,
    customerSuspendTypeOptions,
    customerSuspendServiceTypeOptions,
    customerActionTypeOptions,
    customerInputOptions,
  ]);

  useEffect(() => {
    let newFilterText = filterText;
    newFilterText = newFilterText.replace(/(page=)(\d+)/, `$1${currentPage}`);
    newFilterText = newFilterText.replace(/(size=)(\d+)/, `$1${pageSize}`);
    setFilterText(newFilterText);
    // eslint-disable-next-line
  }, [currentPage, pageSize]);

  const onChangeSearch = (search: string) => {
    const params = new URLSearchParams(filterText);
    if (search) {
      params.set("search", search);
    } else {
      params.delete("search");
    }
    setCurrentPage(0);
    setPageSize(20);
    const newFilterText = "?" + params.toString();
    setFilterText(newFilterText);
  };

  const paginationOnChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (page > 0) {
      setCurrentPage(page - 1);
    }
    setPageSize(pageSize);
  };

  const updateFilterSort = (field: string, order: any) => {
    const direction =
      order === "ascend" ? "asc" : order === "descend" ? "desc" : "";

    const sortParamRegex = /&sort=([^&]*)&direction=([^&]*)/;
    const sortMatch = filterText.match(sortParamRegex);

    let newFilterText = filterText;

    if (sortMatch) {
      newFilterText = newFilterText.replace(
        sortParamRegex,
        `&sort=${field}&direction=${direction}`
      );
    } else if (field && direction) {
      newFilterText = `${newFilterText}&sort=${field}&direction=${direction}`;
    }

    setFilterText(newFilterText);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let nextSortOrder = "";

    if (sorter.field !== sortField) {
      nextSortOrder = "ascend";
    } else {
      switch (sortOrder) {
        case "ascend":
          nextSortOrder = "descend";
          break;
        case "descend":
          nextSortOrder = "";
          break;
        default:
          nextSortOrder = "ascend";
      }
    }

    setSortOrder(nextSortOrder);
    setSortField(sorter.field || "");
    updateFilterSort(sorter.field, nextSortOrder);
  };

  const getCustomerStatus = () => {
    StatusService.getAll(
      "statusType=AMAZON_SUSPENDED_APPLICANT_STATUS",
      `&active=true`
    )
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setCustomerStatusOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getCustomerSuspendType = () => {
    StatusService.getAll(
      "statusType=AMAZON_SUSPENDED_APPLICANT_SUSPEND_TYPE",
      `&active=true`
    )
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setCustomerSuspendTypeOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getCustomerServiceType = () => {
    StatusService.getAll(
      "statusType=AMAZON_SUSPENDED_APPLICANT_SERVICE_TYPE",
      `&active=true`
    )
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setCustomerSuspendServiceTypeOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getCustomerActionType = () => {
    StatusService.getAll(
      "statusType=AMAZON_SUSPENDED_APPLICANT_ACTION_TYPE",
      `&active=true`
    )
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setCustomerActionTypeOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const suspendedCountry = [
      { value: "CA" },
      { value: "MX" },
      { value: "AE" },
      { value: "SA" },
      { value: "AU" },
      { value: "UK" },
      { value: "US" },
      { value: "JP" },
    ];
    setSuspendedCountryOptions(suspendedCountry);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const numberOfViolations = Array.from({ length: 11 }, (_, i) => ({
      value: i.toString(),
    }));
    setNumberOfViolationsOptions(numberOfViolations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const numberOfAmazonCalls = Array.from({ length: 16 }, (_, i) => ({
      value: i.toString(),
    }));
    setNumberOfAmazonCallsOptions(numberOfAmazonCalls);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCustomerInput = () => {
    StatusService.getAll(
      "statusType=AMAZON_SUSPENDED_APPLICANT_INPUT",
      `&active=true`
    )
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setCustomerInputOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getUsers = () => {
    setIsLoading(true);
    if (roleName === "SUSPEND_TEAM_MANAGER") {
      AmazonSuspendedApplicantService.getAll(`${filterText}`)
        .then((response: any) => {
          setUsers(response.data.data);
          setTotalCustomerCount(response.data.totalElements);
          setIsLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });
      UserService.getAll("?roleName=SUSPEND_TEAM")
        .then((response: any) => {
          let data = response;
          const newArray = data.map((item: any) => {
            let fullName = `${item.firstName} ${item.lastName}`;
            return { value: item.id, label: fullName };
          });
          setUserOptions(newArray);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    } else if (roleName === "SUSPEND_TEAM") {
      AmazonSuspendedApplicantService.getAll(
        `${filterText}&suspendTeamMemberId=${authUserId}`
      )
        .then((response: any) => {
          setUsers(response.data.data);
          setTotalCustomerCount(response.data.totalElements);
          setIsLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });
    } else {
      AmazonSuspendedApplicantService.getAll(`${filterText}`)
        .then((response: any) => {
          setUsers(response.data.data);
          setTotalCustomerCount(response.data.totalElements);
          setIsLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });
      UserService.getAll("?roleName=SUSPEND_TEAM")
        .then((response: any) => {
          let data = response;
          const newArray = data.map((item: any) => {
            let fullName = `${item.firstName} ${item.lastName}`;
            return { value: item.id, label: fullName };
          });
          setUserOptions(newArray);
        })
        .catch((e: Error) => {
          console.log(e);
        });
      if (roleName === "ADMIN") {
        UserService.getAll("?roleName=ADMIN")
          .then((response: any) => {
            let data = response;
            const newArray = data.map((item: any) => {
              let fullName = `${item.firstName} ${item.lastName}`;
              return { value: item.id, label: fullName };
            });
            setAdminUserOptions(newArray);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }
    }
  };

  useEffect(() => {
    const loadFilterTextFromLocalStorage = () => {
      const storedFilterText = localStorage.getItem(
        "amazonSuspendedFilterText"
      );
      if (storedFilterText) {
        setFilterText(storedFilterText);
      }
    };
    loadFilterTextFromLocalStorage();
  }, []);

  const columns: ColumnsType<User> = [
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      fixed: "left",
      width: 120,
      sorter: true,
      render: (text: string, record: any) => {
        return (
          <p style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            {record?.source?.name}
          </p>
        );
      },
    },
    {
      title: (
        <p style={{ textAlign: "center", margin: 0, padding: 0 }}>
          Created Date
        </p>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      fixed: "left",
      sorter: true,
      width: 110,
      render: (text: string) => (
        <p style={{ fontWeight: "bolder", fontSize: 12 }}>
          {convertDate(text)}
        </p>
      ),
    },
    {
      title: "Customer",
      dataIndex: "fullName",
      key: "fullName",
      fixed: "left",
      sorter: true,
      width: 270,
      render: (
        text: string,
        record: {
          phone: string;
          email: string;
        }
      ) => (
        <>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <CopyClipboard text={text} />
          </div>
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <CopyClipboard text={record.email} />
          </span>
          <br />
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {record.phone}
            </span>{" "}
            <StartCall text={record.phone} />
          </span>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "amazonSuspendedApplicantStatus",
      key: "amazonSuspendedApplicantStatus",
      // sorter: true,
      width: 300,
      render: (_: any, row: any) => {
        if (roleName === "ADMIN") {
          const statusOption = customerStatusOptions.find(
            (option: any) =>
              option.value === row?.amazonSuspendedApplicantStatus?.id
          );
          return <span>{statusOption ? statusOption?.label : "-"}</span>;
        } else {
          return (
            <Select
              onChange={(selectedStatus) =>
                handleChangeStatus(selectedStatus, row.id)
              }
              options={customerStatusOptions}
              showSearch
              filterOption={(input, option: any) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              key={row.id}
              {...(row?.amazonSuspendedApplicantStatus?.id && {
                defaultValue: row.amazonSuspendedApplicantStatus.id,
              })}
              placeholder={"Select"}
              style={{ width: "280px" }}
            ></Select>
          );
        }
      },
    },
    {
      title: "Input",
      dataIndex: "amazonSuspendedApplicantInput",
      key: "amazonSuspendedApplicantInput",
      // sorter: true,
      width: 300,
      render: (_: any, row: any) => {
        if (roleName === "ADMIN") {
          const inputOption = customerInputOptions.find(
            (option: any) =>
              option.value === row?.amazonSuspendedApplicantInput?.id
          );
          return <span>{inputOption ? inputOption?.label : "-"}</span>;
        } else {
          return (
            <Select
              onChange={(selectedStatus) =>
                handleChangeInput(selectedStatus, row.id)
              }
              options={customerInputOptions}
              showSearch
              filterOption={(input, option: any) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              key={row.id}
              {...(row?.amazonSuspendedApplicantInput?.id && {
                defaultValue: row.amazonSuspendedApplicantInput.id,
              })}
              placeholder={"Select"}
              style={{ width: "280px" }}
            ></Select>
          );
        }
      },
    },
    {
      title: (
        <p style={{ textAlign: "center", margin: 0, padding: 0 }}>
          Assignment Date
        </p>
      ),
      dataIndex: "assignmentDateStart",
      key: "assignmentDateStart",
      sorter: true,
      width: 120,
      align: "center",
      render: (text: string, record: any) => {
        const uptime = record?.uptime;
        let tagColor = "green";

        if (uptime >= 90) {
          tagColor = "red";
        } else if (uptime >= 60) {
          tagColor = "yellow";
        }

        return (
          <>
            <p style={{ fontWeight: "bolder", fontSize: 12 }}>
              {convertDate(text)}
            </p>
            <Tag color={tagColor}>{uptime} days</Tag>
          </>
        );
      },
    },
    ...(roleName !== "SUSPEND_TEAM"
      ? [
          {
            title: "Suspend Team Member",
            dataIndex: "suspendMember",
            key: "suspendMember",
            // sorter: true,
            width: 300,
            render: (_: any, row: any) => {
              if (roleName === "ADMIN") {
                return (
                  <span>
                    {row.suspendTeamMember?.firstName}{" "}
                    {row.suspendTeamMember?.lastName}
                  </span>
                );
              } else {
                return (
                  <Select
                    showSearch
                    filterOption={(input, option: any) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    onChange={(selectedUser) =>
                      handeChangeAssigneUser(selectedUser, row.id)
                    }
                    options={userOptions}
                    key={row.id}
                    {...(row?.suspendTeamMember?.id && {
                      defaultValue: row.suspendTeamMember.id,
                    })}
                    placeholder={"Select"}
                    style={{ width: "280px" }}
                  ></Select>
                );
              }
            },
          },
        ]
      : []),
    {
      title: "Package",
      dataIndex: "callCenterSalesPackage",
      key: "callCenterSalesPackage",
      align: "center",
      width: 120,
      render: (_: any, row: any) => (
        <span>{row?.callCenterSalesPackage?.name ?? "-"}</span>
      ),
    },
    {
      title: "Suspend Type",
      dataIndex: "amazonSuspendedApplicantSuspendType",
      key: "amazonSuspendedApplicantSuspendType",
      width: 300,
      render: (_: any, row: any) => {
        if (roleName === "ADMIN") {
          const suspendTypeOption = customerSuspendTypeOptions.find(
            (option: any) =>
              option.value === row?.amazonSuspendedApplicantSuspendType?.id
          );
          return (
            <span>{suspendTypeOption ? suspendTypeOption?.label : "-"}</span>
          );
        } else {
          return (
            <Select
              onChange={(selectedSuspendType) =>
                handleChangeSuspend(selectedSuspendType, row.id)
              }
              options={customerSuspendTypeOptions}
              showSearch
              filterOption={(input, option: any) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              key={row.id}
              {...(row?.amazonSuspendedApplicantSuspendType?.id && {
                defaultValue: row.amazonSuspendedApplicantSuspendType.id,
              })}
              placeholder={"Select"}
              style={{ width: "280px" }}
            ></Select>
          );
        }
      },
    },
    {
      title: "Service Type",
      dataIndex: "serviceType",
      key: "serviceType",
      width: 300,
      render: (_: any, row: User) => {
        if (roleName === "ADMIN") {
          const suspendServiceTypeOption =
            customerSuspendServiceTypeOptions.find(
              (option) => option.value === row?.serviceType?.id
            );
          return (
            <span>
              {suspendServiceTypeOption ? suspendServiceTypeOption?.label : "-"}
            </span>
          );
        } else {
          return (
            <Select
              onChange={(selectedSuspendServiceType) =>
                handleChangeSuspendServiceType(
                  selectedSuspendServiceType,
                  row.id
                )
              }
              options={customerSuspendServiceTypeOptions}
              showSearch
              filterOption={(input, option: any) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              key={row.id}
              {...(row?.serviceType?.id && {
                defaultValue: row.serviceType.id,
              })}
              placeholder={"Select"}
              style={{ width: "280px" }}
            ></Select>
          );
        }
      },
    },
    {
      title: (
        <p style={{ textAlign: "center", margin: 0, padding: 0 }}>
          Action Date
        </p>
      ),
      dataIndex: "actionDate",
      key: "actionDate",
      sorter: true,
      width: 110,
      align: "center",
      render: (text: string) => (
        <p style={{ fontWeight: "bolder", fontSize: 12 }}>
          {convertDate(text)}
        </p>
      ),
    },
    {
      title: "Action Type",
      dataIndex: "actionType",
      key: "actionType",
      width: 300,
      render: (_: any, row: User) => {
        if (roleName === "ADMIN") {
          const actionTypeOption = customerActionTypeOptions.find(
            (option) => option.value === row?.actionType?.id
          );
          return (
            <span>{actionTypeOption ? actionTypeOption?.label : "-"}</span>
          );
        } else {
          return (
            <Select
              onChange={(selectedActionType) =>
                handleChangeActionType(selectedActionType, row.id)
              }
              options={customerActionTypeOptions}
              showSearch
              filterOption={(input, option: any) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              key={row.id}
              {...(row?.actionType?.id && {
                defaultValue: row.actionType.id,
              })}
              placeholder={"Select"}
              style={{ width: "280px" }}
            ></Select>
          );
        }
      },
    },
    {
      title: (
        <p style={{ textAlign: "center", margin: 0, padding: 0 }}>
          Number of Violations Paid
        </p>
      ),
      dataIndex: "numberOfViolationsPaid",
      key: "numberOfViolationsPaid",
      width: 120,
      align: "center",
      render: (text: number) => <b>{text}</b>,
    },
    {
      title: (
        <p style={{ textAlign: "center", margin: 0, padding: 0 }}>
          Number of Violations
        </p>
      ),
      dataIndex: "numberOfViolations",
      key: "numberOfViolations",
      width: 120,
      render: (_: any, row: User) => {
        if (roleName === "ADMIN") {
          const numberOfViolationsOption = numberOfViolationsOptions.find(
            (option) => option.value === row.numberOfViolations
          );
          return (
            <span>
              {numberOfViolationsOption ? numberOfViolationsOption.value : "-"}
            </span>
          );
        } else {
          return (
            <Select
              onChange={(selectedNumberOfViolationsOption) =>
                handleChangeNumberOfViolations(
                  selectedNumberOfViolationsOption,
                  row.id
                )
              }
              options={numberOfViolationsOptions.map((option) => ({
                label: option.value,
                value: option.value,
              }))}
              defaultValue={row.numberOfViolations}
              placeholder={"Select"}
              style={{ width: "100px" }}
            />
          );
        }
      },
    },
    {
      title: (
        <p style={{ textAlign: "center", margin: 0, padding: 0 }}>
          Suspended Country
        </p>
      ),
      dataIndex: "suspendedCountry",
      key: "suspendedCountry",
      width: 120,
      render: (_: any, row: User) => {
        if (roleName === "ADMIN") {
          const suspendedCountryOption = suspendedCountryOptions.find(
            (option) => option.value === row.suspendedCountry
          );
          return (
            <span>
              {suspendedCountryOption ? suspendedCountryOption.value : "-"}
            </span>
          );
        } else {
          return (
            <Select
              onChange={(selectedSuspendedCountryOption) =>
                handleChangeSuspendedCountry(
                  selectedSuspendedCountryOption,
                  row.id
                )
              }
              options={suspendedCountryOptions.map((option) => ({
                label: option.value,
                value: option.value,
              }))}
              defaultValue={row.suspendedCountry}
              placeholder={"Select"}
              style={{ width: "100px" }}
            />
          );
        }
      },
    },
    {
      title: (
        <p style={{ textAlign: "center", margin: 0, padding: 0 }}>
          Number of Amazon Calls
        </p>
      ),
      dataIndex: "numberOfAmazonCalls",
      key: "numberOfAmazonCalls",
      width: 120,
      render: (_: any, row: User) => {
        if (roleName === "ADMIN") {
          const numberOfAmazonCallsOption = numberOfAmazonCallsOptions.find(
            (option) => option.value === row.numberOfAmazonCalls
          );
          return (
            <span>
              {numberOfAmazonCallsOption
                ? numberOfAmazonCallsOption.value
                : "-"}
            </span>
          );
        } else {
          return (
            <Select
              onChange={(selectedNumberOfAmazonCallsOption) =>
                handleChangeNumberOfAmazonCalls(
                  selectedNumberOfAmazonCallsOption,
                  row.id
                )
              }
              options={numberOfAmazonCallsOptions.map((option) => ({
                label: option.value,
                value: option.value,
              }))}
              defaultValue={row.numberOfAmazonCalls}
              placeholder={"Select"}
              style={{ width: "100px" }}
            />
          );
        }
      },
    },
    {
      title: "OneAmz App Status",
      dataIndex: "oneamzAppStatus",
      key: "oneamzAppStatus",
      sorter: true,
      align: "center",
      width: 120,
      render: (text: boolean, record: any) => (
        <Tag color={record.oneamzAppStatus === true ? "green" : "red"}>
          {record?.oneamzAppStatus === true ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: "Detail",
      key: "detail",
      width: 100,
      fixed: "right" as const,
      render: (_: any, record: any) => (
        <Row justify="center" align="middle" gutter={[0, 8]}>
          <Button
            type="primary"
            onClick={() => {
              let filterToSave = filterText;
              const regex = /search=[^&]*/g;
              filterToSave = filterToSave.replace(regex, "");
              filterToSave = filterToSave.replace(/^&|&$/g, "");
              localStorage.setItem("amazonSuspendedFilterText", filterToSave);
              navigate(`/amazon-suspended-applicants/${record.id}`, {
                state: {
                  currentPage: currentPage,
                  pageSize: pageSize,
                  formFields: formFields,
                  filterText: filterText,
                },
              });
            }}
          >
            Detail
          </Button>
          {roleName !== "SUCCESS_TEAM_REPORTER" ? (
            <NewTask
              customerName={record?.fullName}
              customerId={record?.id}
              setTriggerUpdate={() => {}}
              getTasks={() => {}}
              userOptions={
                roleName === "ADMIN" ? adminUserOptions : userOptions
              }
            />
          ) : null}
        </Row>
      ),
    },
  ];

  const handleChangeStatus = (status: any, id: any) => {
    setIsLoading(true);
    AmazonSuspendedApplicantService.update(id, { statusId: status })
      .then((response: any) => {
        openNotification("success", "Success", "Changed status");
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeSuspend = (suspend: any, id: any) => {
    setIsLoading(true);
    AmazonSuspendedApplicantService.updateSuspend(id, {
      suspendTypeId: suspend,
    })
      .then((response: any) => {
        openNotification("success", "Success", "Changed suspend status");
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeSuspendServiceType = (serviceType: any, id: any) => {
    setIsLoading(true);
    AmazonSuspendedApplicantService.updateServiceType(id, {
      serviceTypeId: serviceType,
    })
      .then((response: any) => {
        openNotification("success", "Success", "Changed service type");
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeActionType = (actionType: any, id: any) => {
    setIsLoading(true);
    AmazonSuspendedApplicantService.updateActionType(id, {
      actionTypeId: actionType,
    })
      .then((response: any) => {
        openNotification("success", "Success", "Changed action type");
        getUsers();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeNumberOfAmazonCalls = (
    numberOfAmazonCalls: any,
    id: any
  ) => {
    setIsLoading(true);
    AmazonSuspendedApplicantService.updateNumberOfAmazonCalls(id, {
      numberOfAmazonCalls,
    })
      .then((response: any) => {
        openNotification(
          "success",
          "Success",
          "Changed number of amazon calls"
        );
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeSuspendedCountry = (suspendedCountry: any, id: any) => {
    setIsLoading(true);
    AmazonSuspendedApplicantService.updateSuspendedCountry(id, {
      suspendedCountry,
    })
      .then((response: any) => {
        openNotification("success", "Success", "Changed suspended country");
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeNumberOfViolations = (numberOfViolations: any, id: any) => {
    setIsLoading(true);
    AmazonSuspendedApplicantService.updateNumberOfViolations(id, {
      numberOfViolations,
    })
      .then((response: any) => {
        openNotification("success", "Success", "Changed number of violations");
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeInput = (input: any, id: any) => {
    setIsLoading(true);
    AmazonSuspendedApplicantService.updateInput(id, { inputId: input })
      .then((response: any) => {
        openNotification("success", "Success", "Changed input");
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handeChangeAssigneUser = (user: any, id: any) => {
    setIsLoading(true);
    AmazonSuspendedApplicantService.updateAssigneUser(id, user)
      .then((response: any) => {
        openNotification("success", "Success", "Changed assigne user");
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, [filterText, sortField, sortOrder]);

  const handleUploadExcelData = () => {
    getUsers();
  };

  const handleUploadManuelData = () => {
    getUsers();
  };

  return (
    <>
      <Card
        className="card-customers"
        title={
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div
              className="customer-card-title"
              style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
            >
              Amazon Suspended Applicant List
            </div>
            <div className="customer-card-info">
              <b>{totalCustomerCount} </b>Amazon Suspended Applicants
            </div>
          </div>
        }
        extra={
          <Row style={{ minWidth: 400, display: "flex" }}>
            <FilterField
              onChangeSearch={onChangeSearch}
              setFilterText={setFilterText}
              pageSize={pageSize}
              setFormFields={setFormFields}
              formFields={formFields}
              setFilterOptions={setFilterOptions}
            />
            {/* {!isHomePage && roleName === "SUSPEND_TEAM_MANAGER" && (
              <Button
                type="primary"
                style={{ marginRight: 16 }}
                onClick={() => navigate(`/prospect-customers/sales-approval`)}
                // onClick={() => window.open(`/prospect-customers/sales-approval`, "_blank")}
              >
                Sales Approval
              </Button>
            )} */}
            <DownloadExcel filterText={filterText} />
            {roleName === "SUSPEND_TEAM_MANAGER" && (
              <ImportExcel handleUploadExcelData={handleUploadExcelData} />
            )}
            {roleName === "SUSPEND_TEAM_MANAGER" && (
              <ManuelEntry handleUploadManuelData={handleUploadManuelData} />
            )}
          </Row>
        }
        bordered={true}
        style={{ marginTop: "10px" }}
      >
        <Table
          rowKey="id"
          loading={isLoading}
          scroll={{ x: "100%" }}
          sticky
          columns={columns}
          dataSource={users}
          pagination={false}
          onChange={handleTableChange}
        />
        <Row style={{ marginTop: "30px" }} justify={"center"}>
          <Pagination
            showSizeChanger={true}
            total={totalCustomerCount}
            defaultPageSize={pageSize}
            defaultCurrent={0}
            current={currentPage + 1}
            showQuickJumper
            onChange={paginationOnChange}
          />
        </Row>
      </Card>
    </>
  );
};
