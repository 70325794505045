import { Col, Modal, Row, Table, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { ColumnsType } from "antd/es/table";
import CallCenterSalesLogService from "../../../../services/CallCenterSalesLogService";
import dayjs from "dayjs";
import ReusableChart from "../../CommonReport/SummaryReport/ReusableChart";

const { Text } = Typography;

const CtmSalesLogReport = ({
  dateRange,
  periodCount,
}: {
  dateRange: any;
  periodCount: number;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any>([]);
  const [selectedMember, setSelectedMember] = useState<any>(null);
  const [selectedPeriod, setSelectedPeriod] = useState<string | null>(null);
  const [selectedPeriodData, setSelectedPeriodData] = useState<any[]>([]);
  const [modalTotalVisible, setModalTotalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [dateLabels, setDateLabels] = useState<any[]>([]);
  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [chartHeight, setChartHeight] = useState<number>(200);
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200 && tableRef.current) {
        const tableColHeight = tableRef.current.getBoundingClientRect().height;
        setChartHeight(Math.max(200, tableColHeight - 30));
      } else {
        setChartHeight(250);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const periods = [];
      let start = dayjs(dateRange[0]);
      let end = dayjs(dateRange[1]);

      for (let i = 0; i < periodCount; i++) {
        const formattedStart = start.format("YYYY-MM-DDT00:00:00");
        const formattedEnd = end.format("YYYY-MM-DDT23:59:59");

        periods.push({
          start: formattedStart,
          end: formattedEnd,
          label: `${start.format("DD.MMM.YY")} - ${end.format("DD.MMM.YY")}`,
        });

        const diffDays = end.diff(start, "days") + 1;
        end = start.subtract(1, "days");
        start = end.subtract(diffDays - 1, "days");
      }

      const reversedPeriods = periods.reverse(); // Period'ları tersine çevir
      getReports(reversedPeriods);
      setDateLabels(reversedPeriods);
    }
    // eslint-disable-next-line
  }, [dateRange, periodCount]);

  const getReports = (periods: any[]) => {
    setLoading(true);

    const excludedUsernames = [
      "az.success.team.crm@oneamz.com",
      "stm@oneamz.com",
      "duygu.karakilic.hi5acm.crm@oneamz.com",
    ];

    const promises = periods.map((period) =>
      CallCenterSalesLogService.getSalesLogReport(
        period.start,
        period.end,
        "CALL_TEAM"
      )
    );

    Promise.all(promises)
      .then((responses) => {
        const mergedData = responses.map((response, index) => {
          return response.data
            .filter(
              (item: any) => !excludedUsernames.includes(item.user.username)
            )
            .map((item: any) => ({
              ...item,
              [`sales_${periods[index]?.label}`]: item.salesDataMap,
            }));
        });

        const flattenedData = mergedData.flat();
        const groupedData = flattenedData.reduce((acc, curr) => {
          const existing = acc.find(
            (item: any) => item.user.id === curr.user.id
          );

          if (existing) {
            Object.keys(curr).forEach((key) => {
              if (key.startsWith("sales_")) {
                existing[key] = curr[key];
              }
            });
          } else {
            acc.push(curr);
          }
          return acc;
        }, []);

        setDataSource(groupedData);

        const chartData = groupedData.map((member: any) => {
          const salesValues = periods.map((period) => {
            const salesDataMap = member[`sales_${period.label}`];
            if (!salesDataMap) return 0;
            return Object.values(salesDataMap).reduce(
              (sum: number, value: any) => {
                const { current } = extractNumericValues(value);
                return sum + current;
              },
              0
            );
          });

          return {
            name: `${member.user.firstName} ${member.user.lastName}`,
            data: salesValues,
          };
        });

        setChartSeries(chartData);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  const extractNumericValues = (
    value: string | undefined
  ): { current: number } => {
    if (!value) {
      return { current: 0 };
    }
    const match = value.match(/^(\d+)/);
    return match ? { current: parseFloat(match[1]) } : { current: 0 };
  };

  const handleTotalClick = (periodKey: string) => {
    const uniquePackages: Record<string, number> = {};

    dataSource.forEach((record: any) => {
      const salesDataMap = record[periodKey];
      if (salesDataMap) {
        Object.entries(salesDataMap).forEach(([packageName, value]) => {
          const { current } = extractNumericValues(value as string);
          if (current > 0) {
            // Sadece 0'dan büyük olanları dahil et
            uniquePackages[packageName] =
              (uniquePackages[packageName] || 0) + current;
          }
        });
      }
    });

    const periodData = Object.entries(uniquePackages).map(
      ([packageName, sales]) => ({
        packageName,
        sales,
      })
    );

    setSelectedPeriod(periodKey.replace("sales_", ""));
    setSelectedPeriodData(periodData);
    setModalTotalVisible(true);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Call Team Member",
      dataIndex: "user",
      key: "user",
      fixed: "left",
      width: 200,
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a, b) => a?.user?.firstName.localeCompare(b?.user?.firstName),
      render: (text: string, record: any) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={`${record?.user?.firstName} ${record?.user?.lastName}`}
        >
          <b>
            {record?.user?.firstName} {record?.user?.lastName}
          </b>
        </div>
      ),
    },
    ...(dataSource.length > 0
      ? Object.keys(dataSource[0])
          .filter((key) => key.startsWith("sales_"))
          .map((periodKey) => ({
            title: periodKey.replace("sales_", ""),
            dataIndex: periodKey,
            key: periodKey,
            width: 175,
            align: "center" as "center",
            render: (salesDataMap: any, record: any) => {
              const totalSales = salesDataMap
                ? Object.values(salesDataMap).reduce(
                    (sum: number, value: any) => {
                      const { current } = extractNumericValues(value);
                      return sum + current;
                    },
                    0
                  )
                : 0;

              return (
                <button
                  onClick={() => {
                    setSelectedMember({
                      user: record.user,
                      salesDataMap,
                      periodLabel: periodKey.replace("sales_", ""),
                    });
                    setModalVisible(true);
                  }}
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    background: "none",
                    border: "none",
                    padding: "0",
                  }}
                >
                  {totalSales}
                </button>
              );
            },
          }))
      : []),
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40, marginTop: 40 }}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={14}>
            <ReusableChart
              type="bar"
              series={chartSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col>
          <Col xs={24} xl={10} ref={tableRef}>
            <Text style={{ margin: "0 0 5px 0" }} type="warning">
              ** When the cells in the table are clicked, details about the
              related sales quantities can be accessed.
            </Text>
            <Table
              rowKey={(record) => record?.user?.id}
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              scroll={{ x: "100%" }}
              pagination={false}
              tableLayout="fixed"
              // className="summary-report-table"
              summary={(pageData) => {
                const totalSalesPerPeriod = Object.keys(dataSource[0] || {})
                  .filter((key) => key.startsWith("sales_"))
                  .map((periodKey) =>
                    pageData.reduce((sum, record) => {
                      const salesDataMap = record[periodKey];
                      if (!salesDataMap) return sum;
                      return (
                        sum +
                        Object.values(salesDataMap).reduce(
                          (innerSum: number, value: any) => {
                            const { current } = extractNumericValues(value);
                            return innerSum + current;
                          },
                          0
                        )
                      );
                    }, 0)
                  );

                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row className="summary-cell">
                      <Table.Summary.Cell
                        index={0}
                        colSpan={1}
                        align="left"
                        className="summary-cell"
                      >
                        <b>Total</b>
                      </Table.Summary.Cell>
                      {totalSalesPerPeriod.map((total, index) => (
                        <Table.Summary.Cell
                          key={index}
                          index={index + 1}
                          align="center"
                          className="summary-cell"
                        >
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleTotalClick(
                                `sales_${dateLabels[index].label}`
                              )
                            }
                          >
                            <b>{total}</b>
                          </div>
                        </Table.Summary.Cell>
                      ))}
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
            <Modal
              title={`${selectedMember?.user?.firstName} ${selectedMember?.user?.lastName}'s Sales (${selectedMember?.periodLabel})`}
              open={modalVisible}
              onCancel={() => setModalVisible(false)}
              footer={null}
            >
              <Table
                dataSource={Object.keys(selectedMember?.salesDataMap || {})
                  .filter((key) => {
                    const { current } = extractNumericValues(
                      selectedMember?.salesDataMap[key]
                    );
                    return current > 0; // Sadece 0'dan büyük olanları dahil et
                  })
                  .map((key) => ({
                    key,
                    salesKey: key,
                    value: selectedMember?.salesDataMap[key],
                  }))}
                columns={[
                  {
                    title: "Package",
                    dataIndex: "salesKey",
                    key: "salesKey",
                    align: "left",
                    render: (value: string) => <b>{value}</b>,
                  },
                  {
                    title: "Sales",
                    dataIndex: "value",
                    key: "value",
                    align: "center",
                    render: (value: string) => {
                      const { current } = extractNumericValues(value);
                      return <b>{current}</b>;
                    },
                  },
                ]}
                pagination={false}
                rowKey={(record) => record.key}
                size="small"
                className="zebra-table"
                summary={(pageData) => {
                  const totalSales = pageData?.reduce(
                    (total, record) => {
                      const { current } = extractNumericValues(record?.value);
                      total.current += current;
                      return total;
                    },
                    { current: 0 }
                  );
                  return (
                    <Table.Summary fixed>
                      <Table.Summary.Row className="summary-cell">
                        <Table.Summary.Cell
                          index={0}
                          colSpan={1}
                          align="left"
                          className="summary-cell"
                        >
                          <b>Total</b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell
                          index={1}
                          align="center"
                          className="summary-cell"
                        >
                          <b>{totalSales.current}</b>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  );
                }}
              />
            </Modal>
            <Modal
              title={`Sales Details (${selectedPeriod})`}
              open={modalTotalVisible}
              onCancel={() => setModalTotalVisible(false)}
              footer={null}
            >
              <Table
                dataSource={selectedPeriodData}
                columns={[
                  {
                    title: "Package",
                    dataIndex: "packageName",
                    key: "packageName",
                    align: "left",
                    render: (value: string) => <b>{value}</b>,
                  },
                  {
                    title: "Sales",
                    dataIndex: "sales",
                    key: "sales",
                    align: "center",
                    render: (value) => <b>{value}</b>,
                  },
                ]}
                rowKey={(record) => record.packageName}
                pagination={false}
                size="small"
                className="zebra-table"
                summary={(pageData) => {
                  const totalSales = pageData?.reduce(
                    (total, record) => {
                      total.current += record.sales;
                      return total;
                    },
                    { current: 0 }
                  );
                  return (
                    <Table.Summary fixed>
                      <Table.Summary.Row className="summary-cell">
                        <Table.Summary.Cell
                          index={0}
                          colSpan={1}
                          align="left"
                          className="summary-cell"
                        >
                          <b>Total</b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell
                          index={1}
                          align="center"
                          className="summary-cell"
                        >
                          <b>{totalSales.current}</b>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  );
                }}
              />
            </Modal>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CtmSalesLogReport;
