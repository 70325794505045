import { Breadcrumb, Card, Col, Collapse, Row, Select, Typography } from "antd";
import { NavLink } from "react-router-dom";
import { ReportsRouter } from "../../ReportsRouter";
import DateRange from "../../../Common/DateRange";
import { useState } from "react";
import MemberBasedSuspendedFileCountReport from "./MemberBasedSuspendedFileCountReport";
import SuspendedFileStatusReport from "./SuspendedFileStatusReport";
import SourceBasedSuspendedFileStatusReport from "./SourceBasedSuspendedFileStatusReport";
import SuspendTypeBasedMemberReport from "./SuspendTypeBasedMemberReport";
import SuspendTypeBasedMonthlySuspendReport from "./SuspendTypeBasedMonthlySuspendReport";
import MemberBasesMonthlySuspendReport from "./MemberBasesMonthlySuspendReport";
import SuspendTypeBasedAvarageOpeningTimeOfSuspendReport from "./SuspendTypeBasedAvarageOpeningTimeOfSuspendReport";
import MemberBasedAvarageOpeningTimeOfSuspendReport from "./MemberBasedAvarageOpeningTimeOfSuspendReport";
import SolvedStatusBasedOneAmzAppUserReport from "./SolvedStatusBasedOneAmzAppUserReport";
import NumberOfAmazonCallsReport from "./NumberOfAmazonCallsReport";
import SuspendTypeBasedSuspendedFilesByCountryReport from "./SuspendTypeBasedSuspendedFilesByCountryReport";
import SuspendedFileCountReport from "./SuspendedFileCountReport";
import SuspendedFileCountByInputReport from "./SuspendFileCountByInputReport";
import SuspendedFileCountSuspendTypesReport from "./SuspendFileCountSuspendTypesReport";
import SuspendedTypeStatusReport from "./SuspendedTypeStatusReport";

const { Title } = Typography;
const { Panel } = Collapse;

const reportPanels = [
  { key: "1", header: "Suspended File Count Report", component: SuspendedFileCountReport },
  { key: "2", header: "Suspend Source Based Report", component: SuspendedFileCountByInputReport },
  { key: "3", header: "Suspend Type Based Report", component: SuspendedFileCountSuspendTypesReport },
  { key: "4", header: "Member Based Suspended File Count Report", component: MemberBasedSuspendedFileCountReport },
  { key: "5", header: "Suspended File Status Report", component: SuspendedFileStatusReport },
  { key: "6", header: "Suspended Type Status Report", component: SuspendedTypeStatusReport },
  { key: "7", header: "Source Based Suspended File Status Report", component: SourceBasedSuspendedFileStatusReport },
  { key: "8", header: "Suspend Type Based Member Report", component: SuspendTypeBasedMemberReport },
  { key: "9", header: "Suspend Type Based Monthly Suspend Report", component: SuspendTypeBasedMonthlySuspendReport },
  { key: "10", header: "Member Based Monthly Suspend Report", component: MemberBasesMonthlySuspendReport },
  { key: "11", header: "Suspend Type Based Average Opening Time of Suspend Report", component: SuspendTypeBasedAvarageOpeningTimeOfSuspendReport },
  { key: "12", header: "Member Based Average Opening Time of Suspend Report", component: MemberBasedAvarageOpeningTimeOfSuspendReport },
  { key: "13", header: "Solved Status Based OneAmz App User Report", component: SolvedStatusBasedOneAmzAppUserReport },
  { key: "14", header: "Amazon Calls Report", component: NumberOfAmazonCallsReport },
  { key: "15", header: "Suspend Type Based Suspended Files by Country Report", component: SuspendTypeBasedSuspendedFilesByCountryReport },
];

const AmazonSuspendedApplicantReports = () => {
  const [dateRange, setDateRange] = useState<[string, string]>(["", ""]);
  const [periodCount, setPeriodCount] = useState<number>(3);
  const [loadedPanels, setLoadedPanels] = useState<string[]>([]);
  const [activePanels, setActivePanels] = useState<string[]>([]);

  const handlePanelChange = (key: string | string[]) => {
    const keyArray = Array.isArray(key) ? key : [key];
    setActivePanels(keyArray);
    setLoadedPanels((prev) => Array.from(new Set([...prev, ...keyArray])));
  };

  return (
    <>
      <Row>
        <Col>
          <Title className="onemaz-page-title" level={3}>
            Reports
          </Title>
          <Breadcrumb
            items={[
              { title: <NavLink to="/">Home</NavLink> },
              { title: "Amazon Suspended Applicant Reports" },
            ]}
          />
        </Col>
      </Row>
      <ReportsRouter />
      <Card
        className="card-customers zebra-table"
        title={
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div
              className="customer-card-title"
              style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
            >
              Amazon Suspended Applicant Reports
            </div>
          </div>
        }
        extra={
          <Row justify={"end"}>
            <DateRange thisMonth={true} onChangeDateRange={setDateRange} />
            <Select
              defaultValue={3}
              style={{ width: 150, marginLeft: 10 }}
              onChange={(value) => setPeriodCount(value)}
              size="large"
            >
              <Select.Option value={1}>Last 1 period</Select.Option>
              <Select.Option value={2}>Last 2 period</Select.Option>
              <Select.Option value={3}>Last 3 period</Select.Option>
              <Select.Option value={4}>Last 4 period</Select.Option>
              <Select.Option value={5}>Last 5 period</Select.Option>
              <Select.Option value={6}>Last 6 period</Select.Option>
            </Select>
          </Row>
        }
        bordered
        style={{ marginTop: "30px" }}
      >
        <Collapse
          onChange={handlePanelChange}
          activeKey={activePanels}
          expandIconPosition="end"
        >
          {reportPanels.map(({ key, header, component: Component }) => (
            <Panel key={key} header={<h3 style={{ margin: 0 }}>{header}</h3>}>
              {loadedPanels.includes(key) && (
                <Component dateRange={dateRange} periodCount={periodCount} />
              )}
            </Panel>
          ))}
        </Collapse>
      </Card>
    </>
  );
};

export default AmazonSuspendedApplicantReports;
