import { Col, Row, Table, Tooltip, Typography } from "antd";
import CallTeamReportService from "../../../../services/CallTeamReportService";
import { useEffect, useRef, useState } from "react";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import ReusableChart from "../../CommonReport/SummaryReport/ReusableChart";

const { Text } = Typography;

const CtmSourceBasedSalesReport = ({
  dateRange,
  periodCount,
}: {
  dateRange: any;
  periodCount: number;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [allPackages, setAllPackages] = useState<string[]>([]);
  const [dateLabels, setDateLabels] = useState<any[]>([]);
  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [chartHeight, setChartHeight] = useState<number>(200);
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1600 && tableRef.current) {
        const tableColHeight = tableRef.current.getBoundingClientRect().height;
        setChartHeight(Math.max(200, tableColHeight - 30));
      } else {
        setChartHeight(250);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const periods = [];
      let start = dayjs(dateRange[0]);
      let end = dayjs(dateRange[1]);

      for (let i = 0; i < periodCount; i++) {
        const formattedStart = start.format("YYYY-MM-DDT00:00:00");
        const formattedEnd = end.format("YYYY-MM-DDT23:59:59");

        periods.push({
          start: formattedStart,
          end: formattedEnd,
          label: `${start.format("DD.MMM.YY")} - ${end.format("DD.MMM.YY")}`,
        });

        const diffDays = end.diff(start, "days") + 1;
        end = start.subtract(1, "days");
        start = end.subtract(diffDays - 1, "days");
      }

      const reversedPeriods = periods.reverse(); // Period'ları tersine çevir
      getReports(reversedPeriods);
      setDateLabels(reversedPeriods);
    }
    // eslint-disable-next-line
  }, [dateRange, periodCount]);

  const getReports = async (periods: any[]) => {
    setLoading(true);
    try {
      const promises = periods.map((period) =>
        CallTeamReportService.getAllSourceBasedSalesReport(
          period.start,
          period.end
        )
      );

      const responses = await Promise.all(promises);

      const processedData = responses.map((response, periodIndex) => {
        return response.data.map((item: any) => {
          const member = item.member;

          const details =
            item.list?.map((source: any) => ({
              sourceName: source.sourceName,
              packageSalesMap: source.packageSalesMap,
              periodIndex,
            })) || [];

          // Tüm paket isimlerini toplamak
          item.list?.forEach((source: any) => {
            Object.keys(source.packageSalesMap).forEach((pkg) => {
              if (!allPackages.includes(pkg)) {
                allPackages.push(pkg);
              }
            });
          });

          // Her period için toplam satış sayısını hesapla
          const totalSales = item.list?.reduce((acc: any, source: any) => {
            for (const pkg in source.packageSalesMap) {
              acc += source.packageSalesMap[pkg] || 0;
            }
            return acc;
          }, 0);

          return {
            key: member.id,
            member,
            [`period_${periodIndex}`]: totalSales,
            details,
          };
        });
      });

      const mergedData = mergeDataByMember(processedData);
      setDataSource(mergedData);
      setAllPackages(Array.from(new Set(allPackages)));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const mergeDataByMember = (data: any[]) => {
    const merged: any = {};

    data.flat().forEach((item: any) => {
      if (!merged[item.key]) {
        // Eğer key yoksa, doğrudan ekle
        merged[item.key] = { ...item };
      } else {
        // Aynı key için mevcut değerleri güncelle
        Object.keys(item).forEach((key) => {
          if (key.startsWith("period_")) {
            // Period verilerini birleştir
            merged[item.key][key] = item[key];
          } else if (key === "details") {
            // Details'i birleştir (önceki detaylarla yeni detayları birleştir)
            merged[item.key].details = [
              ...(merged[item.key].details || []),
              ...(item.details || []),
            ];
          }
        });
      }
    });

    return Object.values(merged);
  };

  useEffect(() => {
    if (dataSource.length > 0 && dateLabels.length > 0) {
      const memberSalesData = dataSource.map((record: any) => ({
        name: `${record.member?.firstName} ${record.member?.lastName}`,
        data: dateLabels.map((_, index) => record[`period_${index}`] || 0),
      }));
      setChartSeries(memberSalesData);
    }
  }, [dataSource, dateLabels]);

  const columns: ColumnsType<any> = [
    {
      title: "Call Team Member",
      dataIndex: "member",
      key: "member",
      width: 200,
      fixed: "left",
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a: any, b: any) =>
        a?.member?.firstName.localeCompare(b?.member?.firstName),
      render: (text: any, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={`${record?.member?.firstName} ${record?.member?.lastName}`}
          >
            <b>
              {record?.member?.firstName} {record?.member?.lastName}
            </b>
          </div>
        );
      },
    },
    ...dateLabels.map((period, index) => ({
      title: period.label,
      dataIndex: `period_${index}`,
      key: `period_${index}`,
      width: 180,
      align: "center" as "center",
      render: (value: any) => <b>{value || 0}</b>,
    })),
  ];

  const expandedRowRender = (record: any) => {
    // Her bir period için sadece o period'daki sıfırdan büyük satış içeren paketleri filtrele
    const filteredPackagesByPeriod = dateLabels.map((_, index) => {
      return allPackages.filter((pkg) =>
        record.details?.some(
          (source: any) =>
            source.periodIndex === index &&
            source.packageSalesMap?.[pkg] &&
            source.packageSalesMap[pkg] > 0
        )
      );
    });

    const filteredDateLabels = dateLabels.filter(
      (_, index) => filteredPackagesByPeriod[index].length > 0
    );

    const childColumns: ColumnsType<any> = [
      {
        title: "Source Name",
        dataIndex: "sourceName",
        key: "sourceName",
        width: 200,
        fixed: "left",
        ellipsis: true,
        defaultSortOrder: "ascend",
        sorter: (a: any, b: any) => a?.sourceName.localeCompare(b?.sourceName),
        render: (text: any, record: any) => {
          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              title={`${record?.sourceName}`}
            >
              <b>{text}</b>
            </div>
          );
        },
      },
      ...filteredDateLabels.map((period, index) => ({
        title: (
          <div
            className="column-group-title"
            style={{
              border: "3px solid #e9e9e9",
              paddingBottom: "8px",
              paddingTop: "8px",
              minWidth: 180,
            }}
          >
            {period.label}
          </div>
        ),
        align: "center" as "center",
        children: filteredPackagesByPeriod[dateLabels.indexOf(period)].map(
          (pkg) => ({
            title: <Tooltip title={pkg}>{pkg}</Tooltip>,
            dataIndex: `package_${pkg}_period_${dateLabels.indexOf(period)}`,
            key: `package_${pkg}_period_${dateLabels.indexOf(period)}`,
            align: "center" as "center",
            width: 180,
            ellipsis: true,
            render: (_: any, sourceRecord: any) => {
              const value =
                sourceRecord.packageSalesMap?.[pkg] &&
                sourceRecord.periodIndex === dateLabels.indexOf(period)
                  ? sourceRecord.packageSalesMap[pkg]
                  : 0;
              return <b>{value || 0}</b>;
            },
          })
        ),
      })),
    ];

    const sourceData =
      record.details?.map((source: any) => ({
        key: `${source.sourceName}_${source.periodIndex}`,
        sourceName: source.sourceName,
        packageSalesMap: source.packageSalesMap,
        periodIndex: source.periodIndex,
      })) || [];

    return (
      <Table
        columns={childColumns}
        dataSource={sourceData}
        pagination={false}
        scroll={{ x: "100%" }}
        rowKey={(record) => `${record.sourceName}_${record.periodIndex}`}
        size="small"
        tableLayout="fixed"
      />
    );
  };

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40, marginTop: 40 }}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={24} xxl={14}>
            <ReusableChart
              type="bar"
              series={chartSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col>
          <Col xs={24} xl={24} xxl={10} ref={tableRef}>
            <Text style={{ margin: "0 0 5px 0" }} type="warning">
              ** Period column(s) with no sales and package columns with no
              sales are hidden.
            </Text>
            <Table
              rowKey={(record) => record?.member?.id}
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              scroll={{ x: "100%" }}
              pagination={false}
              tableLayout="fixed"
              expandable={{
                expandedRowRender,
              }}
              summary={() => {
                // Toplamları hesaplamak için
                const totalSalesByPeriod = dateLabels.map((_, index) =>
                  dataSource.reduce(
                    (sum: number, record: any) =>
                      sum + (record[`period_${index}`] || 0),
                    0
                  )
                );

                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row className="summary-cell">
                      <Table.Summary.Cell
                        index={0}
                        colSpan={2}
                        align="left"
                        className="summary-cell"
                      >
                        <b>Total</b>
                      </Table.Summary.Cell>
                      {totalSalesByPeriod.map((total, index) => (
                        <Table.Summary.Cell
                          index={index + 1}
                          align="center"
                          key={`total_period_${index}`}
                          className="summary-cell"
                        >
                          <b>{total}</b>
                        </Table.Summary.Cell>
                      ))}
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CtmSourceBasedSalesReport;
