import { Col, Row, Table } from "antd";
import CallTeamReportService from "../../../../services/CallTeamReportService";
import { useEffect, useRef, useState } from "react";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import ReusableChart from "../../CommonReport/SummaryReport/ReusableChart";

const CtmMemberBasedTotalCustomerReport = ({
  dateRange,
  periodCount,
}: {
  dateRange: any;
  periodCount: number;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dateLabels, setDateLabels] = useState<any[]>([]);
  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [chartHeight, setChartHeight] = useState<number>(200);
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200 && tableRef.current) {
        const tableColHeight = tableRef.current.getBoundingClientRect().height;
        setChartHeight(Math.max(200, tableColHeight - 30));
      } else {
        setChartHeight(250);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const periods = [];
      let start = dayjs(dateRange[0]);
      let end = dayjs(dateRange[1]);

      for (let i = 0; i < periodCount; i++) {
        const formattedStart = start.format("YYYY-MM-DDT00:00:00");
        const formattedEnd = end.format("YYYY-MM-DDT23:59:59");

        periods.push({
          start: formattedStart,
          end: formattedEnd,
          label: `${start.format("DD.MMM.YY")} - ${end.format("DD.MMM.YY")}`,
        });

        const diffDays = end.diff(start, "days") + 1;
        end = start.subtract(1, "days");
        start = end.subtract(diffDays - 1, "days");
      }

      const reversedPeriods = periods.reverse(); // Period'ları tersine çevir
      getReports(reversedPeriods);
      setDateLabels(reversedPeriods);
    }
    // eslint-disable-next-line
  }, [dateRange, periodCount]);

  const getReports = async (periods: any[]) => {
    setLoading(true);
    try {
      const promises = periods.map((period) =>
        CallTeamReportService.getMemberBasedTotalCustomerReport(
          period.start,
          period.end
        )
      );

      const responses = await Promise.all(promises);

      const mergedData: Record<string, any> = {};
      responses.forEach((response, index) => {
        response.data.reportList.forEach((item: any) => {
          const memberId = item.member.id;
          if (!mergedData[memberId]) {
            mergedData[memberId] = {
              member: item.member,
            };
          }
          mergedData[memberId][`period_${index}`] = item.customerCount;
        });
      });

      // Data'yı tablo için uygun forma dönüştür
      const formattedData = Object.values(mergedData);
      setDataSource(formattedData);

      // Grafik için verileri ayarla
      const chartData = formattedData.map((member: any) => ({
        name: `${member.member.firstName} ${member.member.lastName}`,
        data: periods.map((_, index) => member[`period_${index}`] || 0),
      }));
      setChartSeries(chartData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "Call Team Member",
      dataIndex: "member",
      key: "member",
      width: 200,
      fixed: "left",
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a: any, b: any) =>
        a?.member?.firstName.localeCompare(b?.member?.firstName),
      render: (text: any, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={`${record?.member?.firstName} ${record?.member?.lastName}`}
          >
            <b>
              {record?.member?.firstName} {record?.member?.lastName}
            </b>
          </div>
        );
      },
    },
    ...dateLabels.map((period, index) => ({
      title: period.label,
      dataIndex: `period_${index}`,
      key: `period_${index}`,
      width: 180,
      align: "center" as "center",
      render: (text: any) => <b>{text || 0}</b>,
    })),
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40, marginTop: 40 }}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={14} xxl={14}>
            <ReusableChart
              type="bar"
              series={chartSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col>
          <Col xs={24} xl={10} xxl={10} ref={tableRef}>
            <Table
              rowKey={(record) => record?.member?.id}
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              scroll={{ x: "100%" }}
              pagination={false}
              tableLayout="fixed"
              summary={(pageData) => {
                const totalPerPeriod = dateLabels.map((_, index) =>
                  pageData.reduce(
                    (sum, record) => sum + (record[`period_${index}`] || 0),
                    0
                  )
                );

                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row className="summary-cell">
                      <Table.Summary.Cell
                        index={0}
                        colSpan={1}
                        align="left"
                        className="summary-cell"
                      >
                        <b>Total</b>
                      </Table.Summary.Cell>
                      {totalPerPeriod.map((total, index) => (
                        <Table.Summary.Cell
                          key={index}
                          index={index + 1}
                          align="center"
                          className="summary-cell"
                        >
                          <b>{total}</b>
                        </Table.Summary.Cell>
                      ))}
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CtmMemberBasedTotalCustomerReport;
