import { Col, Row, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import AmazonSuspendedApplicantReportsService from "../../../../services/AmazonSuspendedApplicantReportsService";
import dayjs from "dayjs";
import { MemberMonthlySuspend } from "../../../../types/AmazonSuspendedApplicantReports";

const MemberBasesMonthlySuspendReport = ({
  dateRange,
  periodCount,
}: {
  dateRange: any;
  periodCount: number;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  // const [dateLabels, setDateLabels] = useState<any[]>([]);
  // const [chartSeries, setChartSeries] = useState<any[]>([]);
  // const [chartHeight, setChartHeight] = useState<number>(200);
  // const tableRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth > 1600 && tableRef.current) {
  //       const tableColHeight = tableRef.current.getBoundingClientRect().height;
  //       setChartHeight(Math.max(200, tableColHeight - 30));
  //     } else {
  //       setChartHeight(250);
  //     }
  //   };

  //   handleResize();
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  //   // eslint-disable-next-line
  // }, [dataSource]);

  const previousMonth = dayjs(dateRange[1]).subtract(1, "month").format("MMMM");
  const twoMonthsAgo = dayjs(dateRange[1]).subtract(2, "month").format("MMMM");

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const formattedStartDate = dayjs(dateRange[0]).format(
        "YYYY-MM-DDT00:00:00"
      );
      const formattedEndDate = dayjs(dateRange[1]).format(
        "YYYY-MM-DDT23:59:59"
      );
      getMemberBasesMonthlySuspendReport(formattedStartDate, formattedEndDate);
    }
  }, [dateRange]);

  const getMemberBasesMonthlySuspendReport = (
    startDate: string,
    endDate: string
  ) => {
    setLoading(true);
    AmazonSuspendedApplicantReportsService.getMemberMonthlySuspend(
      startDate,
      endDate
    )
      .then((response) => {
        setDataSource(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnsType<MemberMonthlySuspend> = [
    {
      title: "Member",
      dataIndex: "memberName",
      key: "memberName",
      fixed: "left",
      width: 200,
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.memberName.localeCompare(b.memberName),
      render: (text: string, record: MemberMonthlySuspend) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={record.memberName}
        >
          <b>{record.memberName}</b>
        </div>
      ),
    },
    {
      title: `${twoMonthsAgo} Received`,
      dataIndex: "twoMonthsAgoReceived",
      key: "twoMonthsAgoReceived",
      width: 180,
      align: "center",
      sorter: (a, b) => a.twoMonthsAgoReceived - b.twoMonthsAgoReceived,
      render: (twoMonthsAgoReceived: number) => (
        <div>
          <b>
            {twoMonthsAgoReceived !== undefined ? twoMonthsAgoReceived : "0"}
          </b>
        </div>
      ),
    },
    {
      title: `${twoMonthsAgo} Opened`,
      dataIndex: "twoMonthsAgoOpened",
      key: "twoMonthsAgoOpened",
      width: 180,
      align: "center",
      sorter: (a, b) => a.twoMonthsAgoOpened - b.twoMonthsAgoOpened,
      render: (twoMonthsAgoOpened: number) => (
        <div>
          <b>{twoMonthsAgoOpened !== undefined ? twoMonthsAgoOpened : "0"}</b>
        </div>
      ),
    },
    {
      title: `${twoMonthsAgo} Opened Percentage`,
      dataIndex: "twoMonthsAgoOpenedPercentage",
      key: "twoMonthsAgoOpenedPercentage",
      width: 180,
      align: "center",
      sorter: (a, b) =>
        a.twoMonthsAgoOpenedPercentage - b.twoMonthsAgoOpenedPercentage,
      render: (twoMonthsAgoOpenedPercentage: number) => (
        <div>
          <b>
            {twoMonthsAgoOpenedPercentage !== undefined
              ? twoMonthsAgoOpenedPercentage.toFixed(2)
              : "0"}
            %
          </b>
        </div>
      ),
    },
    {
      title: `${previousMonth} Received`,
      dataIndex: "previousMonthReceived",
      key: "previousMonthReceived",
      width: 180,
      align: "center",
      sorter: (a, b) => a.previousMonthReceived - b.previousMonthReceived,
      render: (previousMonthReceived: number) => (
        <div>
          <b>
            {previousMonthReceived !== undefined ? previousMonthReceived : "0"}
          </b>
        </div>
      ),
    },
    {
      title: `${previousMonth} Opened`,
      dataIndex: "previousMonthOpened",
      key: "previousMonthOpened",
      width: 180,
      align: "center",
      sorter: (a, b) => a.previousMonthOpened - b.previousMonthOpened,
      render: (previousMonthOpened: number) => (
        <div>
          <b>{previousMonthOpened !== undefined ? previousMonthOpened : "0"}</b>
        </div>
      ),
    },
    {
      title: `${previousMonth} Opened Percentage`,
      dataIndex: "previousMonthOpenedPercentage",
      key: "previousMonthOpenedPercentage",
      width: 180,
      align: "center",
      sorter: (a, b) =>
        a.previousMonthOpenedPercentage - b.previousMonthOpenedPercentage,
      render: (previousMonthOpenedPercentage: number) => (
        <div>
          <b>
            {previousMonthOpenedPercentage !== undefined
              ? previousMonthOpenedPercentage.toFixed(2)
              : "0"}
            %
          </b>
        </div>
      ),
    },
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40, marginTop: 40 }}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          {/* <Col xs={24} xl={24} xxl={14}>
            <ReusableChart
              type="bar"
              series={chartSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col> */}
          <Col xs={24} xl={24}>
            <Table
              rowKey={(record) => record?.suspendType}
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              scroll={{ x: "100%" }}
              pagination={false}
              tableLayout="fixed"
              summary={(pageData) => {
                let totalTwoMonthsAgoReceived = 0;
                let totalTwoMonthsAgoOpened = 0;
                let totalTwoMonthsAgoOpenedPercentage = 0;
                let totalPreviousMonthReceived = 0;
                let totalPreviousMonthOpened = 0;
                let totalPreviousMonthOpenedPercentage = 0;

                pageData.forEach(
                  ({
                    twoMonthsAgoReceived,
                    twoMonthsAgoOpened,
                    twoMonthsAgoOpenedPercentage,
                    previousMonthReceived,
                    previousMonthOpened,
                    previousMonthOpenedPercentage,
                  }) => {
                    totalTwoMonthsAgoReceived += twoMonthsAgoReceived || 0;
                    totalTwoMonthsAgoOpened += twoMonthsAgoOpened || 0;
                    totalTwoMonthsAgoOpenedPercentage +=
                      twoMonthsAgoOpenedPercentage || 0;
                    totalPreviousMonthReceived += previousMonthReceived || 0;
                    totalPreviousMonthOpened += previousMonthOpened || 0;
                    totalPreviousMonthOpenedPercentage +=
                      previousMonthOpenedPercentage || 0;
                  }
                );

                const avgTwoMonthsAgoOpenedPercentage = (
                  totalTwoMonthsAgoOpenedPercentage / pageData.length
                ).toFixed(2);
                const avgPreviousMonthOpenedPercentage = (
                  totalPreviousMonthOpenedPercentage / pageData.length
                ).toFixed(2);

                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row className="summary-cell">
                      <Table.Summary.Cell
                        index={0}
                        colSpan={1}
                        align="left"
                        className="summary-cell"
                      >
                        <b>Total</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={1}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{totalTwoMonthsAgoReceived}</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={2}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{totalTwoMonthsAgoOpened}</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={3}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{avgTwoMonthsAgoOpenedPercentage}%</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={4}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{totalPreviousMonthReceived}</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={5}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{totalPreviousMonthOpened}</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={6}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{avgPreviousMonthOpenedPercentage}%</b>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MemberBasesMonthlySuspendReport;
