import { Col, Row, Table, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useRef, useState } from "react";
import AmazonSuspendedApplicantReportsService from "../../../../services/AmazonSuspendedApplicantReportsService";
import dayjs from "dayjs";
import { SuspendTypeMemberReport } from "../../../../types/AmazonSuspendedApplicantReports";
import ReusableChart from "../../CommonReport/SummaryReport/ReusableChart";

const SuspendTypeBasedMemberReport = ({
  dateRange,
  periodCount,
}: {
  dateRange: any;
  periodCount: number;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [members, setMembers] = useState<string[]>([]);
  const [grandTotal, setGrandTotal] = useState<any>({});
  const [dateLabels, setDateLabels] = useState<any[]>([]);
  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [chartHeight, setChartHeight] = useState<number>(200);
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1600 && tableRef.current) {
        const tableColHeight = tableRef.current.getBoundingClientRect().height;
        setChartHeight(Math.max(200, tableColHeight - 30));
      } else {
        setChartHeight(250);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const periods = [];
      let start = dayjs(dateRange[0]);
      let end = dayjs(dateRange[1]);

      for (let i = 0; i < periodCount; i++) {
        const formattedStart = start.format("YYYY-MM-DDT00:00:00");
        const formattedEnd = end.format("YYYY-MM-DDT23:59:59");

        periods.push({
          start: formattedStart,
          end: formattedEnd,
          label: `${start.format("DD.MMM.YY")} - ${end.format("DD.MMM.YY")}`,
        });

        const diffDays = end.diff(start, "days") + 1;
        end = start.subtract(1, "days");
        start = end.subtract(diffDays - 1, "days");
      }

      const reversedPeriods = periods.reverse(); // Period'ları tersine çevir
      getReports(reversedPeriods);
      setDateLabels(reversedPeriods);
    }
    // eslint-disable-next-line
  }, [dateRange, periodCount]);

  const getReports = async (periods: any[]) => {
    setLoading(true);
    try {
      const promises = periods.map((period) =>
        AmazonSuspendedApplicantReportsService.getSuspendTypeMemberReport(
          period.start,
          period.end
        )
      );

      const grandTotalPromise =
        AmazonSuspendedApplicantReportsService.getMemberTotalFiles();

      const [responses, grandTotalData] = await Promise.all([
        Promise.all(promises),
        grandTotalPromise,
      ]);

      setGrandTotal(grandTotalData.data || {});

      const allSuspendTypes = Array.from(
        new Set(
          responses.flatMap((response) =>
            response.data.map(
              (item: SuspendTypeMemberReport) => item.suspendType
            )
          )
        )
      );

      const allMembers = Array.from(
        new Set(
          responses.flatMap((response) =>
            response.data.flatMap((item: SuspendTypeMemberReport) =>
              Object.keys(item.userCounts)
            )
          )
        )
      );

      setMembers(allMembers);

      const normalizedData = allSuspendTypes.map((suspendType) => {
        const row: Record<string, any> = { suspendType };
        periods.forEach((period, index) => {
          const periodData = responses[index].data.find(
            (item: SuspendTypeMemberReport) => item.suspendType === suspendType
          );

          allMembers.forEach((member) => {
            row[`${period.label}-${member}`] =
              periodData?.userCounts?.[member] || 0;
          });

          row[`${period.label}-totalFiles`] = periodData?.totalFiles || 0;
          row[`${period.label}-totalNotOpened`] =
            periodData?.totalNotOpened || 0;
          row[`${period.label}-totalResolved`] = periodData?.totalResolved || 0;
          row[`${period.label}-completedPercentage`] =
            periodData?.completedPercentage || 0;
          row[`${period.label}-totalPercentage`] =
            periodData?.totalPercentage || 0;
        });
        return row;
      });

      setDataSource(normalizedData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dataSource.length > 0) {
      const chartData: Record<string, number[]> = {};

      // Dinamik statüler ve diğer metrikler için seriler oluştur
      members.forEach((member) => {
        chartData[member] = dateLabels.map((period) =>
          dataSource.reduce(
            (total, row) => total + (row[`${period.label}-${member}`] || 0),
            0
          )
        );
      });

      // Seriler
      const series = Object.keys(chartData).map((key) => ({
        name: key,
        data: chartData[key],
      }));

      setChartSeries(series);
    }
  }, [dataSource, dateLabels, members]);

  const columns: ColumnsType<SuspendTypeMemberReport> = [
    {
      title: "Suspend Type",
      dataIndex: "suspendType",
      key: "suspendType",
      fixed: "left",
      width: 200,
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a, b) => {
        const suspendTypeA = a.suspendType || "";
        const suspendTypeB = b.suspendType || "";
        return suspendTypeA.localeCompare(suspendTypeB);
      },
      render: (text: string, record: any) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={record.suspendType || ""}
        >
          <b>{record.suspendType || ""}</b>
        </div>
      ),
    },
    ...dateLabels.map((period) => ({
      title: (
        <div
          className="column-group-title"
          style={{
            border: "3px solid #e9e9e9",
            paddingBottom: "8px",
            paddingTop: "8px",
            marginBottom: "-16px",
          }}
        >
          {period.label}
        </div>
      ),
      align: "center" as "center",
      children: [
        ...members.map((member) => ({
          title: member ? member.toUpperCase() : "",
          dataIndex: `${period.label}-${member}`,
          key: `${period.label}-${member}`,
          align: "center",
          width: 150,
          ellipsis: true,
          render: (value: number) => <b>{value}</b>,
        })),
        {
          title: "Total Files",
          dataIndex: `${period.label}-totalFiles`,
          key: `${period.label}-totalFiles`,
          align: "center",
          width: 150,
          ellipsis: true,
          render: (value: number) => <b>{value}</b>,
        },
        {
          title: "Files in Progress",
          dataIndex: `${period.label}-totalNotOpened`,
          key: `${period.label}-totalNotOpened`,
          align: "center",
          width: 150,
          ellipsis: true,
          render: (value: number) => <b>{value}</b>,
        },
        {
          title: "Total Resolved",
          dataIndex: `${period.label}-totalResolved`,
          key: `${period.label}-totalResolved`,
          align: "center",
          width: 150,
          ellipsis: true,
          render: (value: number) => <b>{value}</b>,
        },
        {
          title: "Completed %",
          dataIndex: `${period.label}-completedPercentage`,
          key: `${period.label}-completedPercentage`,
          align: "center",
          width: 150,
          ellipsis: true,
          render: (value: number) =>
            value !== undefined ? <b>{value.toFixed(2)}</b> : <b>"0.00"</b>,
        },
        {
          title: "Total %",
          dataIndex: `${period.label}-totalPercentage`,
          key: `${period.label}-totalPercentage`,
          align: "center",
          width: 150,
          ellipsis: true,
          render: (value: number) =>
            value !== undefined ? <b>{value.toFixed(2)}</b> : <b>"0.00"</b>,
        },
      ],
    })),
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40, marginTop: 40 }}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={24} xxl={14}>
            <ReusableChart
              type="bar"
              series={chartSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col>
          <Col xs={24} xl={24} xxl={10} ref={tableRef}>
            <Table
              rowKey={(record) => record?.suspendType}
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              scroll={{ x: "100%" }}
              pagination={false}
              tableLayout="fixed"
              summary={(pageData) => {
                if (!pageData || pageData.length === 0) return null;

                const summaryTotals: Record<string, number> = {};
                const summaryAverages: Record<string, number> = {};
                const rowCount = pageData.length;

                // Tüm period ve statüler için toplam ve yüzdelik değerler hesaplama
                dateLabels.forEach((period) => {
                  members.forEach((status) => {
                    const key = `${period.label}-${status}`;
                    summaryTotals[key] = pageData.reduce(
                      (sum, row) => sum + (row[key] || 0),
                      0
                    );
                  });

                  summaryTotals[`${period.label}-totalFiles`] = pageData.reduce(
                    (sum, row) =>
                      sum + (row[`${period.label}-totalFiles`] || 0),
                    0
                  );

                  summaryTotals[`${period.label}-totalNotOpened`] =
                    pageData.reduce(
                      (sum, row) =>
                        sum + (row[`${period.label}-totalNotOpened`] || 0),
                      0
                    );

                  summaryTotals[`${period.label}-totalResolved`] =
                    pageData.reduce(
                      (sum, row) =>
                        sum + (row[`${period.label}-totalResolved`] || 0),
                      0
                    );

                  summaryAverages[`${period.label}-completedPercentage`] =
                    pageData.reduce(
                      (sum, row) =>
                        sum + (row[`${period.label}-completedPercentage`] || 0),
                      0
                    ) / rowCount;

                  summaryAverages[`${period.label}-totalPercentage`] =
                    pageData.reduce(
                      (sum, row) =>
                        sum + (row[`${period.label}-totalPercentage`] || 0),
                      0
                    ) / rowCount;
                });

                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row className="summary-cell">
                      {/* İlk Kolon: Toplam Başlığı */}
                      <Table.Summary.Cell index={0} className="summary-cell">
                        <b>Total</b>
                      </Table.Summary.Cell>

                      {/* Tüm periodlar için toplam ve yüzdeler */}
                      {dateLabels.flatMap((period, periodIndex) => {
                        const baseIndex = periodIndex * (members.length + 3);

                        return [
                          ...members.map((status, statusIndex) => (
                            <Table.Summary.Cell
                              key={`${period.label}-${status}-summary`}
                              index={baseIndex + statusIndex + 1}
                              align="center"
                              className="summary-cell"
                            >
                              <b>
                                {summaryTotals[`${period.label}-${status}`] ||
                                  0}
                              </b>
                            </Table.Summary.Cell>
                          )),
                          <Table.Summary.Cell
                            key={`${period.label}-totalFiles-summary`}
                            index={baseIndex + members.length + 1}
                            align="center"
                            className="summary-cell"
                          >
                            <b>
                              {summaryTotals[`${period.label}-totalFiles`] || 0}
                            </b>
                          </Table.Summary.Cell>,
                          <Table.Summary.Cell
                            key={`${period.label}-totalNotOpened-summary`}
                            index={baseIndex + members.length + 2}
                            align="center"
                            className="summary-cell"
                          >
                            <b>
                              {summaryTotals[
                                `${period.label}-totalNotOpened`
                              ] || 0}
                            </b>
                          </Table.Summary.Cell>,
                          <Table.Summary.Cell
                            key={`${period.label}-totalResolved-summary`}
                            index={baseIndex + members.length + 3}
                            align="center"
                            className="summary-cell"
                          >
                            <b>
                              {summaryTotals[`${period.label}-totalResolved`] ||
                                0}
                            </b>
                          </Table.Summary.Cell>,
                          <Table.Summary.Cell
                            key={`${period.label}-completedPercentage-summary`}
                            index={baseIndex + members.length + 2}
                            align="center"
                            className="summary-cell"
                          >
                            <Tooltip
                              title={`Average: ${
                                summaryAverages[
                                  `${period.label}-completedPercentage`
                                ]?.toFixed(2) || "0.00"
                              }`}
                            >
                              <b>
                                {summaryAverages[
                                  `${period.label}-completedPercentage`
                                ]?.toFixed(2) || "0.00"}
                              </b>
                            </Tooltip>
                          </Table.Summary.Cell>,
                          <Table.Summary.Cell
                            key={`${period.label}-totalPercentage-summary`}
                            index={baseIndex + members.length + 3}
                            align="center"
                            className="summary-cell"
                          >
                            <Tooltip
                              title={`Average: ${
                                summaryAverages[
                                  `${period.label}-totalPercentage`
                                ]?.toFixed(2) || "0.00"
                              }`}
                            >
                              <b>
                                {summaryAverages[
                                  `${period.label}-totalPercentage`
                                ]?.toFixed(2) || "0.00"}
                              </b>
                            </Tooltip>
                          </Table.Summary.Cell>,
                        ];
                      })}
                    </Table.Summary.Row>
                    {/* Grand Total Row */}
                    <Table.Summary.Row className="summary-cell">
                      {/* İlk Kolon: "Grand Total" Başlığı */}
                      <Table.Summary.Cell
                        index={0}
                        colSpan={1}
                        className="summary-cell"
                      >
                        <Tooltip title="All times"><b>Grand Total *</b></Tooltip>
                      </Table.Summary.Cell>

                      {/* Her Period için Grand Total Hesaplamaları */}
                      {dateLabels.flatMap((period, periodIndex) => [
                        // Members için Grand Total
                        ...members.map((member, memberIndex) => (
                          <Table.Summary.Cell
                            key={`grand-total-${period.label}-${member}`}
                            index={
                              periodIndex * members.length + memberIndex + 1
                            }
                            align="center"
                            className="summary-cell"
                          >
                            <b>
                              {grandTotal.find(
                                (item: any) => item.memberName === member
                              )?.grandTotal || "-"}
                            </b>
                          </Table.Summary.Cell>
                        )),
                        // Total Files için Grand Total
                        <Table.Summary.Cell
                          key={`grand-total-files-${period.label}`}
                          index={periodIndex * members.length + members.length}
                          align="center"
                          className="summary-cell"
                        >
                          <b>
                            {grandTotal.reduce(
                              (sum: number, item: any) =>
                                sum + (item.grandTotal || 0),
                              0
                            )}
                          </b>
                        </Table.Summary.Cell>,
                        // İlgisiz Kolonlara "-"
                        ...[
                          "totalNotOpened",
                          "totalResolved",
                          "completedPercentage",
                          "totalPercentage",
                        ].map((irrelevantColumn, irrelevantIndex) => (
                          <Table.Summary.Cell
                            key={`grand-total-${irrelevantColumn}-${period.label}`}
                            index={
                              periodIndex * members.length + members.length + 1
                            }
                            align="center"
                            className="summary-cell"
                          >
                            <b>-</b>
                          </Table.Summary.Cell>
                        )),
                      ])}
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SuspendTypeBasedMemberReport;
