import {
  Button,
  Modal,
  Form,
  Select,
  DatePicker,
  Row,
  Col,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import UserService from "../../../services/UserService";
import { IUserResponseData } from "../../../types/User";
import filterIcon from "../../../assets/filter.svg";
import filterClearIcon from "../../../assets/filter-clear.svg";
import ProspectCustomerSourcesService from "../../../services/ProspectCustomerSourcesService";
import StatusService from "../../../services/StatusService";

const { Item } = Form;

interface CustomerFilterProps {
  onChangeFilter: (values: any) => void;
  setFormFields: any;
  formFields: any;
  pendingStatusId: any;
}

const Filter: React.FC<CustomerFilterProps> = ({
  onChangeFilter,
  setFormFields,
  formFields,
  pendingStatusId,
}) => {
  const { RangePicker } = DatePicker;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customerStatusOptions, setCustomerStatusOptions] = useState<any[]>([]);
  const [customerSources, setCustomerSources] = useState<any[]>([]);
  const [userOptions, setUserOptions] = useState<IUserResponseData[]>([]);
  const [form] = Form.useForm();
  const roleName = localStorage.getItem("roleName");
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [selectedSourceItems, setSelectedSourceItems] = useState<any[]>([]);
  const [suspendStatusOptions, setSuspendStatusOptions] = useState<any[]>([]);
  const [customerDetailStatusOptions, setCustomerDetailStatusOptions] =
    useState<any[]>([]);
  const savedLocalFilters = localStorage.getItem("prospectFilterText");

  useEffect(() => {
    const savedFilterText = localStorage.getItem("prospectFilterText");
    if (savedFilterText) {
      const params = new URLSearchParams(savedFilterText);
      const callCenterMemberId = params?.get("callCenterMemberId");
      const callCenterStatusIds = params
        ?.get("callCenterStatusIds")
        ?.split(",")
        ?.map((id) => parseInt(id, 10));
      const suspendStatusIds = params?.get("suspendStatusIds");
      const callCenterStatusDetailIds = params?.get(
        "callCenterStatusDetailIds"
      );
      const callCenterStatusDetailSubIds = params?.get(
        "callCenterStatusDetailSubIds"
      );
      const sourceIds = params
        ?.get("sourceIds")
        ?.split(",")
        ?.map((id) => parseInt(id, 10));
      // const startDate = params?.get("startDate");
      // const endDate = params?.get("endDate");
      // const createdAtRange =
      //   startDate && endDate
      //     ? [
      //         moment(startDate).format("YYYY-MM-DD"),
      //         moment(endDate).format("YYYY-MM-DD"),
      //       ]
      //     : undefined;

      form.setFieldsValue({
        callTeamMember: callCenterMemberId
          ? parseInt(callCenterMemberId, 10)
          : undefined,
        status: Array.isArray(callCenterStatusIds)
          ? callCenterStatusIds
          : undefined,
        suspendStatus: suspendStatusIds
          ? parseInt(suspendStatusIds, 10)
          : undefined,
        callCenterStatusDetailIds: callCenterStatusDetailIds
          ? parseInt(callCenterStatusDetailIds, 10)
          : undefined,
        callCenterStatusDetailSubIds: callCenterStatusDetailSubIds
          ? parseInt(callCenterStatusDetailSubIds, 10)
          : undefined,
        source: Array.isArray(sourceIds) ? sourceIds : undefined,
        // createdAtRange: createdAtRange || undefined,
      });

      setSelectedItems(
        Array.isArray(callCenterStatusIds) ? callCenterStatusIds : []
      );
      setSelectedSourceItems(Array.isArray(sourceIds) ? sourceIds : []);
    } else if (pendingStatusId && !localStorage.getItem("prospectFilterText")) {
      form.setFieldsValue({
        status: [pendingStatusId],
      });
      setSelectedItems([pendingStatusId]);
    }
  }, [
    pendingStatusId,
    form,
    userOptions,
    suspendStatusOptions,
    customerDetailStatusOptions,
    customerSources,
  ]);

  const showModal = () => {
    if (customerStatusOptions.length === 0) {
      getCustomerStatus();
    }
    if (userOptions.length === 0) {
      getUsers();
    }
    if (suspendStatusOptions.length === 0) {
      getSuspendStatus();
    }
    if (customerDetailStatusOptions.length === 0) {
      getCustomerDetailStatus();
    }
    if (customerSources.length === 0) {
      getSources();
    }
    setIsModalOpen(true);
  };

  const onFinish = (values: any) => {
    onChangeFilter(values);
    setFormFields(form.getFieldsValue());
    setIsModalOpen(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const getUsers = () => {
    UserService.getAll("?roleName=CALL_TEAM")
      .then((response: any) => {
        let data = response;
        setUserOptions(data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const clearForm = () => {
    form.resetFields();
    setSelectedItems([]);
    setFormFields(form.getFieldsValue());
    localStorage.removeItem("prospectFilterText");
  };

  const clearLocalFilterAndForm = () => {
    clearForm();
    onFinish({ ...form.getFieldsValue() });
  };

  const getCustomerStatus = () => {
    StatusService.getAll("statusType=CALL_CENTER_STATUS", `&active=true`)
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name };
        });
        setCustomerStatusOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  const getSources = () => {
    ProspectCustomerSourcesService.getAll()
      .then((response: any) => {
        let data = response.data;
        const newArray = data
          .filter((item: any) => item.active)
          .map((item: any) => {
            return { value: item.id, label: item.name };
          });
        setCustomerSources(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getSuspendStatus = () => {
    if (
      roleName === "CALL_TEAM_MANAGER" ||
      roleName === "CALL_TEAM" ||
      roleName === "ADMIN" ||
      roleName === "SUCCESS_TEAM_REPORTER"
    ) {
      let mainStatuses: any[] = [];
      let subStatuses: any[] = [];
      StatusService.getAll("statusType=SOFTWARE_SUSPENDED_STATUS")
        .then((response: any) => {
          let data = response.data;
          data?.forEach((item: any) => {
            if (item.parent === null) {
              mainStatuses.push(item);
            } else {
              subStatuses.push(item);
            }
          });

          let processedData = mainStatuses.map((mainStatus: any) => {
            return {
              ...mainStatus,
              subStatuses: subStatuses.filter(
                (subStatus: any) => subStatus.parent.id === mainStatus.id
              ),
            };
          });
          setSuspendStatusOptions(processedData);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const getCustomerDetailStatus = () => {
    if (
      roleName === "CALL_TEAM_MANAGER" ||
      roleName === "CALL_TEAM" ||
      roleName === "ADMIN" ||
      roleName === "SUCCESS_TEAM_REPORTER"
    ) {
      let mainStatuses: any[] = [];
      let subStatuses: any[] = [];
      StatusService.getAll("statusType=CALL_CENTER_STATUS_DETAIL")
        .then((response: any) => {
          let data = response.data;
          data?.forEach((item: any) => {
            if (item.parent === null) {
              mainStatuses.push(item);
            } else {
              subStatuses.push(item);
            }
          });

          let processedData = mainStatuses.map((mainStatus: any) => {
            return {
              ...mainStatus,
              subStatuses: subStatuses.filter(
                (subStatus: any) => subStatus.parent.id === mainStatus.id
              ),
            };
          });
          setCustomerDetailStatusOptions(processedData);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    const { startPassiveDay, endPassiveDay } = allValues;
    if (
      startPassiveDay &&
      (endPassiveDay < startPassiveDay || !endPassiveDay)
    ) {
      form.setFieldsValue({ endPassiveDay: startPassiveDay });
    }
  };

  const selectAll = () => {
    const allIds = customerStatusOptions.map((option) => option.value);
    setSelectedItems(allIds);
    form.setFieldsValue({
      status: allIds,
    });
  };

  const handleChange = (selected: any) => {
    setSelectedItems(selected);
    form.setFieldsValue({
      status: selected,
    });
  };

  const selectAllSource = () => {
    const allIds = customerSources.map((option) => option.value);
    setSelectedSourceItems(allIds);
    form.setFieldsValue({
      source: allIds,
    });
  };

  const handleChangeSource = (selected: any) => {
    setSelectedSourceItems(selected);
    form.setFieldsValue({
      source: selected,
    });
  };

  return (
    <>
      <Modal
        title="Customer Filter"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
        >
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              {roleName !== "CALL_TEAM" && userOptions ? (
                <>
                  Call Team Member
                  <Item name="callTeamMember">
                    <Select placeholder={"Select"}>
                      <Select.Option key={0} value={null}>
                        Select
                      </Select.Option>
                      {userOptions?.map((option) => (
                        <Select.Option key={option?.id} value={option?.id}>
                          {option?.firstName} {option?.lastName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Item>
                </>
              ) : null}
            </Col>
            <Col xs={24} sm={roleName === "CALL_TEAM" ? 24 : 12}>
              Created Date
              <Item name="createdAtRange">
                <RangePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
              </Item>
            </Col>
          </Row>
          Status
          <Item name="status">
            <Select
              placeholder={"Select a status"}
              options={customerStatusOptions}
              showSearch
              allowClear
              mode="multiple"
              value={selectedItems}
              style={{ width: "75%", marginRight: "10px" }}
              onChange={handleChange}
              filterOption={(input, option: any) =>
                option?.label.toLowerCase().includes(input.toLowerCase())
              }
            ></Select>
            <Button style={{ float: "right" }} onClick={selectAll}>
              Select All
            </Button>
          </Item>
          Source
          <Item name="source">
            <Select
              placeholder={"Select a source"}
              options={customerSources}
              showSearch
              allowClear
              mode="multiple"
              value={selectedSourceItems}
              style={{ width: "75%", marginRight: "10px" }}
              onChange={handleChangeSource}
              filterOption={(input, option: any) =>
                option?.label.toLowerCase().includes(input.toLowerCase())
              }
            ></Select>
            <Button style={{ float: "right" }} onClick={selectAllSource}>
              Select All
            </Button>
          </Item>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              Call Center Detail Main Status
              <Item name="callCenterMainStatusDetail">
                <Select placeholder={"Select"}>
                  <Select.Option key={0} value={null}>
                    Select
                  </Select.Option>
                  {customerDetailStatusOptions?.map((mainStatus) => (
                    <Select.Option key={mainStatus?.id} value={mainStatus?.id}>
                      {mainStatus?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              Call Center Detail Sub Status
              <Item name="callCenterSubStatusDetail">
                <Select placeholder={"Select"}>
                  <Select.Option key="null" value={null}>
                    Select
                  </Select.Option>
                  {customerDetailStatusOptions.map((mainStatus) => (
                    <Select.OptGroup
                      key={mainStatus.id}
                      label={mainStatus.name}
                    >
                      {mainStatus.subStatuses.map((subStatus: any) => (
                        <Select.Option key={subStatus.id} value={subStatus.id}>
                          {subStatus.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  ))}
                </Select>
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24}>
              Source of Referral
              <Item name="sourceOfReferral">
                <Select placeholder={"Select a Level"}>
                  <Select.Option key={7} value={null}>
                    Select
                  </Select.Option>
                  <Select.Option key={0} value="dijital-reklamlar">
                    Dijital Reklamlar
                  </Select.Option>
                  <Select.Option key={1} value="arkadas-tavsiyesi">
                    Arkadaş Tavsiyesi
                  </Select.Option>
                  <Select.Option key={2} value="arama-motoru-sonuclari">
                    Arama Motoru Sonuçları
                  </Select.Option>
                  <Select.Option key={3} value="sosyal-medya-ve-forumlar">
                    Sosyal Medya ve Forumlar
                  </Select.Option>
                  <Select.Option key={4} value="bloglar-ve-inceleme-siteleri">
                    Bloglar ve İnceleme Siteleri
                  </Select.Option>
                </Select>
              </Item>
            </Col>
          </Row>
          <Item>
            <Row justify="end">
              <Button
                key="back"
                style={{ marginRight: "10px" }}
                onClick={clearForm}
              >
                Clear
              </Button>
              <Button htmlType="submit" type="primary">
                Apply
              </Button>
            </Row>
          </Item>
        </Form>
      </Modal>
      <Tooltip title="Filters">
        <Button className="mr-15" onClick={showModal} type="default">
          <img src={filterIcon} alt="userIcon" style={{ margin: "1px" }} />
        </Button>
      </Tooltip>
      {savedLocalFilters && savedLocalFilters.length > 0 ? (
        <Tooltip title="Clear Filters">
          <Button
            style={{ marginRight: "10px" }}
            onClick={clearLocalFilterAndForm}
            type="default"
          >
            <img
              src={filterClearIcon}
              alt="userIcon"
              style={{ margin: "1px" }}
            />
          </Button>
        </Tooltip>
      ) : null}
    </>
  );
};

export default Filter;
