import { Row, Col } from "antd";
import { useEffect, useState } from "react";
import DashboardService from "../../../services/DashboardService";
import InfoCard from "./InfoCard";
import RetentionInfoCard from "./RetentionInfoCard";
import dayjs from "dayjs";

interface TotalInfoCardProps {
  roleName: string;
  dateRange: any;
}

const TotalInfoCard: React.FC<TotalInfoCardProps> = ({
  roleName,
  dateRange,
}) => {
  const [loading, setLoading] = useState(false);
  const [learnerData, setLearnerData] = useState({ total: 0, today: 0 });
  const [retentionData, setRetentionData] = useState({ total: 0, today: 0 });
  const [softwareSuspendedData, setSoftwareSuspendedData] = useState({
    total: 0,
    today: 0,
  });
  // const [retentionProcessData, setRetentionProcessData] = useState({
  //   totalUsers: 0,
  //   retainedUsers: 0,
  // });
  const [underSixMonthsRetentionData, setUnderSixMonthsRetentionData] =
    useState({
      underSixMonthsCount: 0,
      underSixMonthsContacted: 0,
    });
  const [overSixMonthsRetentionData, setOverSixMonthsRetentionData] = useState({
    overSixMonthsCount: 0,
    overSixMonthsContacted: 0,
  });
  //  eslint-disable-next-line
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, []);

  const fetchTotalInfo = async (
    formattedStartDate: string,
    formattedEndDate: string
  ) => {
    setLoading(true);
    try {
      if (roleName === "SUCCESS_TEAM_MANAGER") {
        const responseTotalLearner =
          await DashboardService.getTotalLearnerStmForManager(
            formattedStartDate,
            formattedEndDate
          );
        const allLearners = responseTotalLearner.data;

        const totalLearnersSum = allLearners.reduce(
          (acc: any, curr: any) => acc + curr.totalLearners,
          0
        );
        const todayLearnersSum = allLearners.reduce(
          (acc: any, curr: any) => acc + curr.todayLearners,
          0
        );

        setLearnerData({ total: totalLearnersSum, today: todayLearnersSum });

        const responseTotalRetention =
          await DashboardService.getTotalRetentionCustomerStmForManager(
            formattedStartDate,
            formattedEndDate
          );
        const allRetentions = responseTotalRetention.data;
        const totalRetentionsSum = allRetentions.reduce(
          (acc: any, curr: any) => acc + curr.totalRetentionCount,
          0
        );
        const todayRetentionsSum = allRetentions.reduce(
          (acc: any, curr: any) => acc + curr.todayRetentionCount,
          0
        );
        setRetentionData({
          total: totalRetentionsSum,
          today: todayRetentionsSum,
        });

        const responseTotalSoftwareSuspended =
          await DashboardService.getTotalSoftwareSuspendedCustomerStmForManager(
            formattedStartDate,
            formattedEndDate
          );
        setSoftwareSuspendedData({
          total: responseTotalSoftwareSuspended.data.totalSuspendedCustomers,
          today: responseTotalSoftwareSuspended.data.todaySuspendedCustomers,
        });

        // const responseTotalRetentionProcess =
        //   await DashboardService.getTotalRetentionProcessStmForManager(
        //     formattedStartDate,
        //     formattedEndDate
        //   );
        // const allRetentionProcess = responseTotalRetentionProcess.data;

        // const totalUsersSum = allRetentionProcess.reduce(
        //   (acc: any, curr: any) => acc + curr.totalUsers,
        //   0
        // );
        // const todayRetainedUsersSum = allRetentionProcess.reduce(
        //   (acc: any, curr: any) => acc + curr.retainedUsers,
        //   0
        // );
        // setRetentionProcessData({
        //   totalUsers: totalUsersSum,
        //   retainedUsers: todayRetainedUsersSum,
        // });

        const responseUnderSixMonthRetention =
          await DashboardService.getUnderSixMonthRetentionStmForManager(
            dayjs(formattedStartDate).format("YYYY-MM-DDT00:00:00"),
            dayjs(formattedEndDate).format("YYYY-MM-DDT23:59:59")
          );
        setUnderSixMonthsRetentionData({
          underSixMonthsCount:
            responseUnderSixMonthRetention.data.totalUnderSixMonths,
          underSixMonthsContacted:
            responseUnderSixMonthRetention.data.totalUnderSixMonthsContacted,
        });

        const responseOverSixMonthRetention =
          await DashboardService.getOverSixMonthRetentionStmForManager(
            dayjs(formattedStartDate).format("YYYY-MM-DDT00:00:00"),
            dayjs(formattedEndDate).format("YYYY-MM-DDT23:59:59")
          );
        setOverSixMonthsRetentionData({
          overSixMonthsCount:
            responseOverSixMonthRetention.data.totalOverSixMonths,
          overSixMonthsContacted:
            responseOverSixMonthRetention.data.totalOverSixMonthsContacted,
        });
      } else {
        const responseTotalLearner =
          await DashboardService.getTotalLearnerStmForMember(
            formattedStartDate,
            formattedEndDate
          );
        setLearnerData({
          total: responseTotalLearner.data.totalLearners,
          today: responseTotalLearner.data.todayLearners,
        });

        const responseTotalRetention =
          await DashboardService.getTotalRetentionCustomerStmForMember(
            formattedStartDate,
            formattedEndDate
          );
        setRetentionData({
          total: responseTotalRetention.data.totalRetentionCount,
          today: responseTotalRetention.data.todayRetentionCount,
        });

        const responseTotalSoftwareSuspended =
          await DashboardService.getTotalSoftwareSuspendedCustomerStmForMember(
            formattedStartDate,
            formattedEndDate
          );
        setSoftwareSuspendedData({
          total: responseTotalSoftwareSuspended.data.totalSuspendedCustomers,
          today: responseTotalSoftwareSuspended.data.todaySuspendedCustomers,
        });

        // const responseTotalRetentionProcess =
        //   await DashboardService.getTotalRetentionProcessStmForMember(
        //     formattedStartDate,
        //     formattedEndDate
        //   );
        // setRetentionProcessData({
        //   totalUsers: responseTotalRetentionProcess.data.totalUsers,
        //   retainedUsers: responseTotalRetentionProcess.data.retainedUsers,
        // });

        const responseUnderSixMonthRetention =
          await DashboardService.getUnderSixMonthRetentionStmForMember(
            dayjs(formattedStartDate).format("YYYY-MM-DDT00:00:00"),
            dayjs(formattedEndDate).format("YYYY-MM-DDT23:59:59")
          );
        setUnderSixMonthsRetentionData({
          underSixMonthsCount:
            responseUnderSixMonthRetention.data.underSixMonthsCount,
          underSixMonthsContacted:
            responseUnderSixMonthRetention.data.underSixMonthsContacted,
        });

        const responseOverSixMonthRetention =
          await DashboardService.getOverSixMonthRetentionStmForMember(
            dayjs(formattedStartDate).format("YYYY-MM-DDT00:00:00"),
            dayjs(formattedEndDate).format("YYYY-MM-DDT23:59:59")
          );
        setOverSixMonthsRetentionData({
          overSixMonthsCount:
            responseOverSixMonthRetention.data.overSixMonthsCount,
          overSixMonthsContacted:
            responseOverSixMonthRetention.data.overSixMonthsContacted,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dateRange.length > 0) {
      if (dateRange[0] && dateRange[1]) {
        fetchTotalInfo(dateRange[0], dateRange[1]);
      }
    }
    // eslint-disable-next-line
  }, [dateRange, roleName]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} lg={8}>
          <InfoCard
            title="Learners"
            description={`Total ${learnerData.total}`}
            count={`+${learnerData.today}`}
            loading={loading}
          />
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <InfoCard
            title="Retention"
            description={`Total ${retentionData.total}`}
            count={`+${retentionData.today}`}
            loading={loading}
          />
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <InfoCard
            title="Software Suspended"
            description={`Total ${softwareSuspendedData.total}`}
            count={`+${softwareSuspendedData.today}`}
            loading={loading}
          />
        </Col>
        {/* <Col xs={24} sm={12} lg={12}>
          <InfoCard
            title="Retention Process"
            description={``}
            count={`${retentionProcessData.totalUsers} / ${retentionProcessData.retainedUsers}`}
            loading={loading}
          />
        </Col> */}
        <Col xs={24} sm={12} lg={8}>
          <RetentionInfoCard
            title="Retention Info"
            dateRange={dateRange}
            roleName={roleName ?? ""}
          />
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <InfoCard
            title="Under 6 Months For Retention"
            description={`Total ${underSixMonthsRetentionData.underSixMonthsCount}`}
            count={`${underSixMonthsRetentionData.underSixMonthsContacted}`}
            loading={loading}
          />
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <InfoCard
            title="Over 6 Months For Retention"
            description={`Total ${overSixMonthsRetentionData.overSixMonthsCount}`}
            count={`${overSixMonthsRetentionData.overSixMonthsContacted}`}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
};

export default TotalInfoCard;
