import { Col, Row, Table, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useRef, useState } from "react";
import AmazonSuspendedApplicantReportsService from "../../../../services/AmazonSuspendedApplicantReportsService";
import dayjs from "dayjs";
import { SuspendFileStatusInput } from "../../../../types/AmazonSuspendedApplicantReports";
import ReusableChart from "../../CommonReport/SummaryReport/ReusableChart";

const SourceBasedSuspendedFileStatusReport = ({
  dateRange,
  periodCount,
}: {
  dateRange: any;
  periodCount: number;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [statuses, setStatuses] = useState<string[]>([]);
  const [dateLabels, setDateLabels] = useState<any[]>([]);
  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [chartHeight, setChartHeight] = useState<number>(200);
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1600 && tableRef.current) {
        const tableColHeight = tableRef.current.getBoundingClientRect().height;
        setChartHeight(Math.max(200, tableColHeight - 30));
      } else {
        setChartHeight(250);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const periods = [];
      let start = dayjs(dateRange[0]);
      let end = dayjs(dateRange[1]);

      for (let i = 0; i < periodCount; i++) {
        const formattedStart = start.format("YYYY-MM-DDT00:00:00");
        const formattedEnd = end.format("YYYY-MM-DDT23:59:59");

        periods.push({
          start: formattedStart,
          end: formattedEnd,
          label: `${start.format("DD.MMM.YY")} - ${end.format("DD.MMM.YY")}`,
        });

        const diffDays = end.diff(start, "days") + 1;
        end = start.subtract(1, "days");
        start = end.subtract(diffDays - 1, "days");
      }

      const reversedPeriods = periods.reverse(); // Period'ları tersine çevir
      getReports(reversedPeriods);
      setDateLabels(reversedPeriods);
    }
    // eslint-disable-next-line
  }, [dateRange, periodCount]);

  const getReports = async (periods: any[]) => {
    setLoading(true);
    try {
      const promises = periods.map((period) =>
        AmazonSuspendedApplicantReportsService.getSuspendFileStatusInput(
          period.start,
          period.end
        )
      );

      const responses = await Promise.all(promises);

      const allInputSources = Array.from(
        new Set(
          responses.flatMap((response) =>
            response.data.map(
              (item: SuspendFileStatusInput) => item.inputSource
            )
          )
        )
      );

      const allStatuses = Array.from(
        new Set(
          responses.flatMap((response) =>
            response.data.flatMap((item: SuspendFileStatusInput) =>
              Object.keys(item.statusCounts)
            )
          )
        )
      );
      setStatuses(allStatuses);

      const normalizedData = allInputSources.map((inputSource) => {
        const row: Record<string, any> = { inputSource };
        periods.forEach((period, index) => {
          const periodData = responses[index].data.find(
            (item: SuspendFileStatusInput) => item.inputSource === inputSource
          );

          allStatuses.forEach((status) => {
            row[`${period.label}-${status}`] =
              periodData?.statusCounts?.[status] || 0;
          });

          row[`${period.label}-total`] = periodData?.total || 0;
          row[`${period.label}-openPercentage`] =
            periodData?.openPercentage || 0;
          row[`${period.label}-overallPercentage`] =
            periodData?.overallPercentage || 0;
        });
        return row;
      });

      setDataSource(normalizedData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dataSource.length > 0) {
      const chartData: Record<string, number[]> = {};

      // Dinamik statüler ve diğer metrikler için seriler oluştur
      statuses.forEach((status) => {
        chartData[status] = dateLabels.map((period) =>
          dataSource.reduce(
            (total, row) => total + (row[`${period.label}-${status}`] || 0),
            0
          )
        );
      });

      // chartData["Total"] = dateLabels.map((period) =>
      //   dataSource.reduce(
      //     (total, row) => total + (row[`${period.label}-total`] || 0),
      //     0
      //   )
      // );

      // chartData["Open (%)"] = dateLabels.map(
      //   (period) =>
      //     dataSource.reduce(
      //       (total, row) =>
      //         total + (row[`${period.label}-openPercentage`] || 0),
      //       0
      //     ) / dataSource.length
      // );

      // chartData["Overall (%)"] = dateLabels.map(
      //   (period) =>
      //     dataSource.reduce(
      //       (total, row) =>
      //         total + (row[`${period.label}-overallPercentage`] || 0),
      //       0
      //     ) / dataSource.length
      // );

      // Seriler
      const series = Object.keys(chartData).map((key) => ({
        name: key,
        data: chartData[key],
      }));

      setChartSeries(series);
    }
  }, [dataSource, dateLabels, statuses]);

  const columns: ColumnsType<SuspendFileStatusInput> = [
    {
      title: "Source",
      dataIndex: "inputSource",
      key: "inputSource",
      fixed: "left",
      width: 200,
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.inputSource.localeCompare(b.inputSource),
      render: (text: string, record: any) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={record.inputSource}
        >
          <b>{record.inputSource}</b>
        </div>
      ),
    },
    ...dateLabels.map((period) => ({
      title: (
        <div
          className="column-group-title"
          style={{
            border: "3px solid #e9e9e9",
            paddingBottom: "8px",
            paddingTop: "8px",
            marginBottom: "-16px",
          }}
        >
          {period.label}
        </div>
      ),
      align: "center" as "center",
      children: [
        ...statuses.map((status) => ({
          title: status ? status.toUpperCase() : "",
          dataIndex: `${period.label}-${status}`,
          key: `${period.label}-${status}`,
          align: "center",
          width: 150,
          ellipsis: true,
          render: (value: number) => <b>{value}</b>,
        })),
        {
          title: "Total",
          dataIndex: `${period.label}-total`,
          key: `${period.label}-total`,
          align: "center",
          width: 150,
          ellipsis: true,
          render: (value: number) => <b>{value}</b>,
        },
        {
          title: "Open (%)",
          dataIndex: `${period.label}-openPercentage`,
          key: `${period.label}-openPercentage`,
          align: "center",
          width: 150,
          ellipsis: true,
          render: (value: number) =>
            value !== undefined ? <b>{value.toFixed(2)}</b> : <b>"0.00"</b>,
        },
        {
          title: "Overall (%)",
          dataIndex: `${period.label}-overallPercentage`,
          key: `${period.label}-overallPercentage`,
          align: "center",
          width: 150,
          ellipsis: true,
          render: (value: number) =>
            value !== undefined ? <b>{value.toFixed(2)}</b> : <b>"0.00"</b>,
        },
      ],
    })),
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40, marginTop: 40 }}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={24} xxl={14}>
            <ReusableChart
              type="bar"
              series={chartSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col>
          <Col xs={24} xl={24} xxl={10} ref={tableRef}>
            <Table
              rowKey={(record) => record?.inputSource}
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              scroll={{ x: "100%" }}
              pagination={false}
              tableLayout="fixed"
              summary={(pageData) => {
                if (!pageData || pageData.length === 0) return null;

                const summaryTotals: Record<string, number> = {};
                const summaryAverages: Record<string, number> = {};
                const rowCount = pageData.length;

                // Tüm period ve statüler için toplam ve yüzdelik değerler hesaplama
                dateLabels.forEach((period) => {
                  statuses.forEach((status) => {
                    const key = `${period.label}-${status}`;
                    summaryTotals[key] = pageData.reduce(
                      (sum, row) => sum + (row[key] || 0),
                      0
                    );
                  });

                  summaryTotals[`${period.label}-total`] = pageData.reduce(
                    (sum, row) => sum + (row[`${period.label}-total`] || 0),
                    0
                  );
                  summaryAverages[`${period.label}-openPercentage`] =
                    pageData.reduce(
                      (sum, row) =>
                        sum + (row[`${period.label}-openPercentage`] || 0),
                      0
                    ) / rowCount;
                  summaryAverages[`${period.label}-overallPercentage`] =
                    pageData.reduce(
                      (sum, row) =>
                        sum + (row[`${period.label}-overallPercentage`] || 0),
                      0
                    ) / rowCount;
                });

                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row className="summary-cell">
                      {/* İlk Kolon: Toplam Başlığı */}
                      <Table.Summary.Cell index={0} className="summary-cell">
                        <b>Total</b>
                      </Table.Summary.Cell>

                      {/* Tüm periodlar için toplam ve yüzdeler */}
                      {dateLabels.flatMap((period, periodIndex) => {
                        const baseIndex = periodIndex * (statuses.length + 3);

                        return [
                          ...statuses.map((status, statusIndex) => (
                            <Table.Summary.Cell
                              key={`${period.label}-${status}-summary`}
                              index={baseIndex + statusIndex + 1}
                              align="center"
                              className="summary-cell"
                            >
                              <b>
                                {summaryTotals[`${period.label}-${status}`] ||
                                  0}
                              </b>
                            </Table.Summary.Cell>
                          )),
                          <Table.Summary.Cell
                            key={`${period.label}-total-summary`}
                            index={baseIndex + statuses.length + 1}
                            align="center"
                            className="summary-cell"
                          >
                            <b>{summaryTotals[`${period.label}-total`] || 0}</b>
                          </Table.Summary.Cell>,
                          <Table.Summary.Cell
                            key={`${period.label}-openPercentage-summary`}
                            index={baseIndex + statuses.length + 2}
                            align="center"
                            className="summary-cell"
                          >
                            <Tooltip
                              title={`Average: ${
                                summaryAverages[
                                  `${period.label}-openPercentage`
                                ]?.toFixed(2) || "0.00"
                              }`}
                            >
                              <b>
                                {summaryAverages[
                                  `${period.label}-openPercentage`
                                ]?.toFixed(2) || "0.00"}
                              </b>
                            </Tooltip>
                          </Table.Summary.Cell>,
                          <Table.Summary.Cell
                            key={`${period.label}-overallPercentage-summary`}
                            index={baseIndex + statuses.length + 3}
                            align="center"
                            className="summary-cell"
                          >
                            <Tooltip
                              title={`Average: ${
                                summaryAverages[
                                  `${period.label}-overallPercentage`
                                ]?.toFixed(2) || "0.00"
                              }`}
                            >
                              <b>
                                {summaryAverages[
                                  `${period.label}-overallPercentage`
                                ]?.toFixed(2) || "0.00"}
                              </b>
                            </Tooltip>
                          </Table.Summary.Cell>,
                        ];
                      })}
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SourceBasedSuspendedFileStatusReport;
