import { Tabs } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

const SettingsRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const roleName = localStorage.getItem("roleName");

  const onTabChange = (key: string) => {
    navigate(key);
  };

  const items = [
    ...(roleName === "SUCCESS_TEAM_MANAGER" || roleName === "ADMIN"
      ? [
          {
            key: "/settings/learner-status",
            label: "Learner Status",
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "SUCCESS_TEAM_MANAGER" || roleName === "ADMIN"
      ? [
          {
            key: "/settings/retention-status",
            label: "Retention Status",
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "SUCCESS_TEAM_MANAGER" || roleName === "ADMIN"
      ? [
          {
            key: "/settings/software-suspend-status",
            label: "Software Suspend Status",
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "SUCCESS_TEAM_MANAGER" ||
    roleName === "ACCOUNT_MANAGER_MANAGER" ||
    roleName === "HI5_ACM_MANAGER" ||
    roleName === "ADMIN"
      ? [
          {
            key: "/settings/reason-for-dropout-status",
            label: "Reason for Dropout Status",
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "SUCCESS_TEAM_MANAGER" || roleName === "ADMIN"
      ? [
          {
            key: "/settings/affiliate",
            label: "Affiliate",
            children: <></>,
          },
        ]
      : []),

    ...(roleName === "CALL_TEAM_MANAGER" || roleName === "ADMIN"
      ? [
          {
            key: "/settings/call-status",
            label: "Call Team Status",
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "CALL_TEAM_MANAGER" || roleName === "ADMIN"
      ? [
          {
            key: "/settings/call-center-detail-status",
            label: "Call Team Detail Status",
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "ADMIN" ||
    roleName === "CALL_TEAM_MANAGER" ||
    roleName === "SUCCESS_TEAM_MANAGER" ||
    roleName === "ACCOUNT_MANAGER_MANAGER" ||
    roleName === "HI5_ACM_MANAGER" ||
    roleName === "SUSPEND_TEAM_MANAGER"
      ? [
          {
            key: "/settings/call-sales-package",
            label: "Call Team Sales Package",
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "CALL_TEAM_MANAGER" || roleName === "ADMIN"
      ? [
          {
            key: "/settings/call-sales-package-servey",
            label: "Call Team Sales Package Servey",
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "ACCOUNT_MANAGER_MANAGER" || roleName === "ADMIN"
      ? [
          {
            key: "/settings/acm-status",
            label: "Account Manager Status",
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "HI5_ACM_MANAGER" || roleName === "ADMIN"
      ? [
          {
            key: "/settings/hi5-acm-status",
            label: "Hi5 Account Manager Status",
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "ADMIN" || roleName === "CALL_TEAM_MANAGER"
      ? [
          {
            key: "/settings/prospect-customer-sources",
            label: "Call Team Customer Sources",
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "ADMIN" || roleName === "SUCCESS_TEAM_MANAGER"
      ? [
          {
            key: "/settings/package-of-interest-status-for-stm",
            label: `Package of Interest Status${
              roleName === "ADMIN" ? " for STM" : ""
            }`,
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "ADMIN" || roleName === "ACCOUNT_MANAGER_MANAGER"
      ? [
          {
            key: "/settings/package-of-interest-status-for-acm",
            label: `Package of Interest Status${
              roleName === "ADMIN" ? " for ACM" : ""
            }`,
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "ADMIN" || roleName === "HI5_ACM_MANAGER"
      ? [
          {
            key: "/settings/package-of-interest-status-for-hi5-acm",
            label: `Package of Interest Status${
              roleName === "ADMIN" ? " for HI5 ACM" : ""
            }`,
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "ADMIN" || roleName === "SUCCESS_TEAM_MANAGER"
      ? [
          {
            key: "/settings/old-package-of-interest-status-for-stm",
            label: `Old Package of Interest Status${
              roleName === "ADMIN" ? " for STM" : ""
            }`,
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "ADMIN" || roleName === "ACCOUNT_MANAGER_MANAGER"
      ? [
          {
            key: "/settings/current-package-of-interest-status-for-acm",
            label: `Current Package of Interest Status${
              roleName === "ADMIN" ? " for ACM" : ""
            }`,
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "ADMIN" || roleName === "HI5_ACM_MANAGER"
      ? [
          {
            key: "/settings/current-package-of-interest-status-for-hi5-acm",
            label: `Current Package of Interest Status${
              roleName === "ADMIN" ? " for HI5 ACM" : ""
            }`,
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "SUCCESS_TEAM_MANAGER" ||
    roleName === "CALL_TEAM_MANAGER" ||
    roleName === "ACCOUNT_MANAGER_MANAGER" ||
    roleName === "HI5_ACM_MANAGER" ||
    roleName === "SUSPEND_TEAM_MANAGER" ||
    roleName === "MAS_TEAM_MANAGER" ||
    roleName === "ADMIN"
      ? [
          {
            key: "/settings/users",
            label: "Users",
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "ADMIN"
      ? [
          {
            key: "/settings/roles",
            label: "Roles",
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "SUCCESS_TEAM_MANAGER" || roleName === "ADMIN"
      ? [
          {
            key: "/settings/bonus",
            label: "Bonus Policies",
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "SUSPEND_TEAM_MANAGER" || roleName === "ADMIN"
      ? [
          {
            key: "/settings/amazon-suspended-applicant-status",
            label: "Amazon Suspended Applicant Status",
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "SUSPEND_TEAM_MANAGER" || roleName === "ADMIN"
      ? [
          {
            key: "/settings/amazon-suspended-applicant-suspend-types",
            label: "Amazon Suspended Applicant Suspend Types",
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "SUSPEND_TEAM_MANAGER" || roleName === "ADMIN"
      ? [
          {
            key: "/settings/amazon-suspended-applicant-service-types",
            label: "Amazon Suspended Applicant Service Types",
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "SUSPEND_TEAM_MANAGER" || roleName === "ADMIN"
      ? [
          {
            key: "/settings/amazon-suspended-applicant-action-types",
            label: "Amazon Suspended Applicant Action Types",
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "SUSPEND_TEAM_MANAGER" || roleName === "ADMIN"
      ? [
          {
            key: "/settings/amazon-suspended-applicant-inputs",
            label: "Amazon Suspended Applicant Inputs",
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "MAS_TEAM_MANAGER" || roleName === "ADMIN"
      ? [
          {
            key: "/settings/feedback-status",
            label: `${roleName === "ADMIN" ? " MAS Team " : ""}Feedback Status`,
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "MAS_TEAM_MANAGER" || roleName === "ADMIN"
      ? [
          {
            key: "/settings/feedback-channel",
            label: `${
              roleName === "ADMIN" ? " MAS Team " : ""
            }Feedback Channel`,
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "MAS_TEAM_MANAGER" || roleName === "ADMIN"
      ? [
          {
            key: "/settings/checklist-status",
            label: `${
              roleName === "ADMIN" ? " MAS Team " : ""
            }Checklist Status`,
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "ADMIN" || roleName === "CALL_TEAM_MANAGER"
      ? [
          {
            key: "/settings/departments",
            label: "Departments",
            children: <></>,
          },
        ]
      : []),
    ...(roleName === "ADMIN" || roleName === "CALL_TEAM_MANAGER"
      ? [
          {
            key: "/settings/employees",
            label: "Employees",
            children: <></>,
          },
        ]
      : []),
  ];

  return (
    <Tabs activeKey={location.pathname} onChange={onTabChange} items={items} />
  );
};

export default SettingsRouter;
