import { Col, Row, Table } from "antd";
import { useEffect, useState, useRef } from "react";
import { ColumnsType } from "antd/es/table";
import CustomerAssignmentHistoryService from "../../../../services/CustomerAssignmentHistoryService";
import dayjs from "dayjs";
import ReusableChart from "./ReusableChart";

const MemberBasedTotalSoftwareSuspendedCustomerTransferReport = ({
  oldMemberFlag,
  newMemberFlag,
  dateRange,
  teamMember,
  periodCount,
}: {
  oldMemberFlag: string;
  newMemberFlag: string;
  dateRange: any;
  teamMember: string;
  periodCount: number;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dateLabels, setDateLabels] = useState<any[]>([]);
  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [chartHeight, setChartHeight] = useState<number>(200);
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200 && tableRef.current) {
        const tableColHeight = tableRef.current.getBoundingClientRect().height;
        setChartHeight(Math.max(200, tableColHeight - 30));
      } else {
        setChartHeight(250);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1] && oldMemberFlag && newMemberFlag) {
      const periods = [];
      let start = dayjs(dateRange[0]);
      let end = dayjs(dateRange[1]);

      for (let i = 0; i < periodCount; i++) {
        const formattedStart = start.format("YYYY-MM-DDT00:00:00");
        const formattedEnd = end.format("YYYY-MM-DDT23:59:59");

        periods.push({
          start: formattedStart,
          end: formattedEnd,
          label: `${start.format("DD.MMM.YY")} - ${end.format("DD.MMM.YY")}`,
        });

        const diffDays = end.diff(start, "days") + 1;
        end = start.subtract(1, "days");
        start = end.subtract(diffDays - 1, "days");
      }

      const reversedPeriods = periods.reverse(); // Period'ları tersine çevir
      getReports(reversedPeriods, newMemberFlag, oldMemberFlag);
      setDateLabels(reversedPeriods);
    }
    // eslint-disable-next-line
  }, [dateRange, oldMemberFlag, newMemberFlag, periodCount]);

  const getReports = async (
    periods: any[],
    newMemberFlag: string,
    oldMemberFlag: string
  ) => {
    setLoading(true);
  
    try {
      const promises = periods.map((period) =>
        CustomerAssignmentHistoryService.getMemberBasedFlagReport(
          period.start,
          period.end,
          newMemberFlag,
          oldMemberFlag
        )
      );
  
      const responses = await Promise.all(promises);
  
      const memberMap = new Map();

      periods.forEach((_, periodIndex) => {
        const response = responses[periodIndex]?.data || [];
        response.forEach((item: any) => {
          if (!item.member) return; 
  
          const memberKey = `${item.member.firstName} ${item.member.lastName}`;
          if (!memberMap.has(memberKey)) {
            memberMap.set(memberKey, { member: memberKey, counts: Array(periods.length).fill(0) });
          }
          const existing = memberMap.get(memberKey);
          existing.counts[periodIndex] = item.customerCount || 0;
          memberMap.set(memberKey, existing);
        });
      });

      const groupedData = Array.from(memberMap.values()).map((member) => {
        const data: Record<string, any> = { member: member.member };
        periods.forEach((_, index) => {
          data[index.toString()] = member.counts[index] || 0;
        });
        return data;
      });
  
      setDataSource(groupedData);

      const chartData = Array.from(memberMap.values()).map((member) => ({
        name: member.member,
        data: member.counts.map((count: number) => count || 0),
      }));
  
      setChartSeries(chartData);
      // setDateLabels([...periods].reverse());
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: teamMember,
      dataIndex: "member",
      key: "member",
      width: 200,
      align: "left",
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a: any, b: any) => a?.member?.localeCompare(b?.member),
      render: (text: any, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={record?.member}
          >
            <b>{record?.member}</b>
          </div>
        );
      },
    },
    ...dateLabels.map((label: any, index: number) => ({
      title: label.label,
      dataIndex: index.toString(),
      key: index.toString(),
      align: "center" as "center",
      width: 175,
      render: (count: any) => <b>{count ?? 0}</b>,
    })),
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40, marginTop: 40 }}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={14}>
            <ReusableChart
              type="bar"
              series={chartSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col>
          <Col xs={24} xl={10} ref={tableRef}>
            <Table
              rowKey={(record) => record?.member}
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              scroll={{ x: "100%" }}
              pagination={false}
              tableLayout="fixed"
              summary={(pageData) => {
                const totalCounts = Array(dateLabels.length).fill(0);
                pageData.forEach((record: any) => {
                  totalCounts.forEach((_, index) => {
                    totalCounts[index] += record[index] || 0;
                  });
                });
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row className="summary-cell">
                      <Table.Summary.Cell
                        index={0}
                        colSpan={1}
                        align="left"
                        className="summary-cell"
                      >
                        <b>Total</b>
                      </Table.Summary.Cell>
                      {totalCounts.map((total: number, index: number) => (
                        <Table.Summary.Cell
                          key={index}
                          index={index + 1}
                          align="center"
                          className="summary-cell"
                        >
                          <b>{total}</b>
                        </Table.Summary.Cell>
                      ))}
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MemberBasedTotalSoftwareSuspendedCustomerTransferReport;
