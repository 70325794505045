import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Input,
  Popconfirm,
  Tooltip,
  Select,
  Table,
  Modal,
} from "antd";
import "./task-detail.css";
import { useNavigate, useParams } from "react-router-dom";
import CustomerInfo from "../components/ProspectCustomerDetail/CustomerInfo";
import ProspectCustomerService from "../services/ProspectCustomerService";
import phoneIcon from "../assets/phone.svg";
import smsIcon from "../assets/sms.svg";
import callIcon from "../assets/call.svg";
import Notes from "../components/ProspectCustomerDetail/Notes";
import LearnerService from "../services/LearnerService";
import {
  PhoneOutlined,
  CloseCircleOutlined,
  ShoppingCartOutlined,
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { openNotification } from "../utils";
import Tasks from "../components/ProspectCustomerDetail/Tasks/Tasks";
import { ICustomerStatusResponseData } from "../types/CustomerStatus";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { CopyClipboard } from "../components/General/CopyClipboard";
import CallHistory from "../components/ProspectCustomerDetail/CallHistory";
import moment from "moment";
import CallSalesPackageService from "../services/CallSalesPackageService";
import UserService from "../services/UserService";
import { FiPhone } from "react-icons/fi";
import StatusService from "../services/StatusService";
import AdditionalPackageHistoryCard from "../components/ProspectCustomerDetail/AdditionalPackageHistoryCard";

const { Item } = Form;

const ProspectCustomerDetail = () => {
  const [triggerUpdate, setTriggerUpdate] = useState(0);
  const { id } = useParams<{ id: string }>();
  const [customer, setCustomer] = useState<any>(null);
  // eslint-disable-next-line
  const [learnerDetail, setLearnerDetail] = useState<any>(null);
  const [form] = Form.useForm();
  const [gsmPortalEmployeeId, setGsmPortalEmployeeId] = useState("");
  const [customerStatusOptions, setCustomerStatusOptions] = useState<
    ICustomerStatusResponseData[]
  >([]);
  const [customerDetailStatusOptions, setCustomerDetailStatusOptions] =
    useState<any[]>([]);
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  // eslint-disable-next-line
  const [packageNames, setPackageNames] = useState([]);
  const [salesLog, setSalesLog] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [userOptions, setUserOptions] = useState<any>([]);
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [contactForm] = Form.useForm();
  const roleName = localStorage.getItem("roleName");

  const showModal = () => {
    setIsModalVisible(true);
    getSoldPackages();
    getPackages();
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getUsers = () => {
    const roleMapping: { [key: string]: string } = {
      CALL_TEAM_MANAGER: "CALL_TEAM",
      SUCCESS_TEAM_MANAGER: "SUCCESS_TEAM",
      ACCOUNT_MANAGER_MANAGER: "ACCOUNT_MANAGER",
      SUSPENDED_TEAM_MANAGER: "SUSPENDED_TEAM",
      ADMIN: "ADMIN",
    };

    if (roleName) {
      const teamRole = roleMapping[roleName];
      if (teamRole) {
        UserService.getAll(`?roleName=${teamRole}`)
          .then((response: any) => {
            const newArray = response.map((item: any) => ({
              value: item.id,
              label: `${item.firstName} ${item.lastName}`,
            }));

            setUserOptions(newArray);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }
    }
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, [roleName]);

  const handleChangeAssigneUser = (userId: any, row: any) => {
    const payload = {
      callCenterSalesPackageId: row?.callCenterSalesPackage?.id,
      learnerId: row?.learner?.id || null,
      customerId: row?.customer?.id || null,
      prospectCustomerId: row?.prospectCustomer?.id || null,
      userId: userId,
    };

    CallSalesPackageService.updateSalesLog(row.id, payload)
      .then((response: any) => {
        openNotification("success", "Success", "Changed assigned user");
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const salesLogColumns = [
    {
      title: "Package Name",
      dataIndex: "callCenterSalesPackage",
      key: "callCenterSalesPackage",
      width: "150px",
      render: (callCenterSalesPackage: any) =>
        callCenterSalesPackage?.name || "-",
    },
    {
      title: "Approval Date",
      dataIndex: "approvalDate",
      key: "approvalDate",
      width: "150px",
      render: (text: any) => (text ? moment(text).format("YYYY-MM-DD") : "-"),
    },
    ...(roleName === "CALL_TEAM_MANAGER" ||
    roleName === "SUCCESS_TEAM_MANAGER" ||
    roleName === "SUSPEND_TEAM_MANAGER" ||
    roleName === "ACCOUNT_MANAGER_MANAGER" ||
    roleName === "HI5_ACM_MANAGER" ||
    roleName === "ADMIN"
      ? [
          {
            title: "Team Member",
            dataIndex: "user",
            key: "user",
            width: "150px",
            render: (_: any, row: any) => {
              const userOption = userOptions.find(
                (option: any) => option.value === row?.user?.id
              );
              const displayName = userOption
                ? userOption.label
                : `${row.user.firstName} ${row.user.lastName}`;
              return (
                <span>
                  {userOption ? (
                    <Select
                      showSearch
                      filterOption={(input, option: any) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={(selectedUser) =>
                        handleChangeAssigneUser(selectedUser, row)
                      }
                      options={userOptions}
                      key={row.id}
                      {...(row?.user?.id && {
                        defaultValue: row.user.id,
                      })}
                      placeholder={"Select"}
                      style={{ width: "180px" }}
                    ></Select>
                  ) : (
                    displayName
                  )}
                </span>
              );
            },
          },
        ]
      : []),
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "50px",
      render: (_: any, row: any) => (
        <Tooltip placement="topLeft" title="Delete">
          <CloseCircleOutlined
            style={{ marginTop: 5, marginLeft: 10, height: 24 }}
            onClick={() => showDeleteSalesLogModal(row.id)}
          >
            Delete
          </CloseCircleOutlined>
        </Tooltip>
      ),
    },
  ];

  const getPackages = () => {
    CallSalesPackageService.getAll()
      .then((response: any) => {
        let data = response.data;
        const soldPackageIds = salesLog.map(
          (log: any) => log.callCenterSalesPackageId
        );

        const newArray = data
          .filter((item: any) => !soldPackageIds.includes(item.id))
          .map((item: any) => {
            return {
              value: item.id,
              label: item.name,
              disabled: !item.isActive,
            };
          });

        const packageNamesMap = response.data.reduce((acc: any, curr: any) => {
          acc[curr.id] = curr.name;
          return acc;
        }, {});

        setPackageNames(packageNamesMap);
        setOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getSoldPackages = () => {
    let filter = `prospectCustomerId=${customer?.id}`;
    CallSalesPackageService.getSalesLog(`?${filter}`)
      .then((response: any) => {
        setSalesLog(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleAddSaleLog = () => {
    const selectedPackageId = form.getFieldValue("selectedPackage");
    const prospectCustomerId = customer?.id;
    if (!selectedPackageId || !prospectCustomerId) {
      console.error("Selected package or customer ID is missing!");
      return;
    }
    const packageData = {
      callCenterSalesPackageId: selectedPackageId,
      prospectCustomerId: prospectCustomerId,
    };
    setLoading(true);
    CallSalesPackageService.createSalesLog(packageData)
      .then((response) => {
        if (response.status === 200) {
          openNotification(
            "success",
            "Success",
            "Sales log created successfully"
          );
          getSoldPackages();
        } else {
          openNotification("error", "Error", "Failed to create sales log");
        }
      })
      .catch((error) => {
        console.error("Error creating sales log:", error);
        openNotification("error", "Error", "Failed to create sales log");
      })
      .finally(() => setLoading(false));
  };

  const handleDeleteSaleLog = (salesLogId: number) => {
    setLoading(true);
    CallSalesPackageService.removeSalesLog(salesLogId)
      .then((response) => {
        openNotification(
          "success",
          "Success",
          "Sales log deleted successfully"
        );
        getSoldPackages();
      })
      .catch((error) => {
        console.error("Error deleting sales log:", error);
        openNotification("error", "Error", "Failed to delete sales log");
      })
      .finally(() => setLoading(false));
  };

  const showDeleteSalesLogModal = (salesLogId: any) => {
    Modal.confirm({
      title: "Delete Sale Log",
      content: "Are you sure you want to delete this sale log?",
      onOk: () => handleDeleteSaleLog(salesLogId),
      onCancel: () => {},
    });
  };

  const showAddPackageModal = () => {
    Modal.confirm({
      title: "Package Selection",
      content: (
        <Select
          style={{ width: "100%" }}
          placeholder="Package select"
          onChange={(value) => form.setFieldsValue({ selectedPackage: value })}
        >
          <Select.Option key={0} value={null}>
            Select
          </Select.Option>
          {options.map(
            (option: any) =>
              !option.disabled && (
                <Select.Option
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </Select.Option>
              )
          )}
        </Select>
      ),
      okText: "Add Sale Log",
      onOk: () => handleAddSaleLog(),
      onCancel: () => {},
    });
  };

  useEffect(() => {
    if (customer) {
      getPackages();
    }
    // eslint-disable-next-line
  }, [customer]);

  useEffect(() => {
    getCustomer();
    // eslint-disable-next-line
  }, [id, triggerUpdate]);

  const getCustomerStatus = () => {
    StatusService.getAll("statusType=CALL_CENTER_STATUS", `&active=true`)
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setCustomerStatusOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getCustomerDetailStatus = () => {
    if (roleName === "CALL_TEAM_MANAGER" || roleName === "CALL_TEAM") {
      let mainStatuses: any[] = [];
      let subStatuses: any[] = [];
      StatusService.getAll("statusType=CALL_CENTER_STATUS_DETAIL")
        .then((response: any) => {
          let data = response.data;
          data.forEach((item: any) => {
            if (item.parent === null) {
              mainStatuses.push(item);
            } else {
              subStatuses.push(item);
            }
          });

          let processedData = mainStatuses.map((mainStatus: any) => {
            return {
              ...mainStatus,
              subStatuses: subStatuses.filter(
                (subStatus: any) => subStatus.parent.id === mainStatus.id
              ),
            };
          });
          setCustomerDetailStatusOptions(processedData);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    let netGsmId = localStorage.getItem("gsmPortalEmployeeId");
    // eslint-disable-next-line
    if (netGsmId != null && netGsmId != "") {
      setGsmPortalEmployeeId(netGsmId);
    }
    getCustomerStatus();
    getCustomerDetailStatus();
    // eslint-disable-next-line
  }, []);

  const getCustomer = () => {
    ProspectCustomerService.get(id)
      .then((response: any) => {
        setCustomer(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  form.setFieldsValue({
    phoneNumber: customer?.phone,
  });

  const handeStartCall = async () => {
    try {
      const values = await form.validateFields();
      if (values) {
        openNotification("success", "Calling", values.phoneNumber, 5);
        LearnerService.learnerCall(gsmPortalEmployeeId, values.phoneNumber)
          .then((response: any) => {
            if (response.data?.status === "Success") {
              openNotification(
                "success",
                response.data.message,
                values.phoneNumber,
                5
              );
            } else {
              openNotification(
                "error",
                response.data.message,
                values.phoneNumber,
                5
              );
            }
          })
          .catch((e: Error) => {});
      }
    } catch (error) {
      openNotification(
        "error",
        "Start a call",
        "Phone number must comply with the format"
      );
    }
  };

  const callForm = () => {
    if (
      // eslint-disable-next-line
      gsmPortalEmployeeId != "" &&
      // eslint-disable-next-line
      gsmPortalEmployeeId != null &&
      !isNaN(Number(gsmPortalEmployeeId)) &&
      // eslint-disable-next-line
      gsmPortalEmployeeId != "null" &&
      gsmPortalEmployeeId
    ) {
      return (
        <Popconfirm
          title="Customer the call"
          description={
            <Form form={form} layout="vertical">
              Are you sure you want to call the customer?
              <Item
                style={{ marginTop: "10px" }}
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                  {
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "Phone number must be only digits!",
                  },
                  {
                    len: 11,
                    message: "Phone number must be 11 digits long!",
                  },
                ]}
              >
                <Input />
              </Item>
            </Form>
          }
          okText={
            <>
              <PhoneOutlined /> Call
            </>
          }
          cancelText="No"
          onConfirm={() => handeStartCall()}
        >
          <Button
            type="primary"
            style={{
              marginLeft: 8,
              fontSize: 10,
              fontWeight: "bold",
              alignItems: "center",
              display: "flex",
            }}
          >
            <FiPhone style={{ fontSize: "13px", marginRight: "5px" }} />
            Call
          </Button>
        </Popconfirm>
      );
    }

    return (
      <Tooltip
        placement="top"
        title={"Contact your administrator to make a call"}
      >
        <Button
          type="default"
          style={{
            marginLeft: 16,
            fontSize: 10,
            fontWeight: "bold",
            alignItems: "center",
            display: "flex",
          }}
        >
          <img
            src={callIcon}
            alt="call-icon"
            style={{ marginRight: 5, marginTop: 0 }}
          />
          Call
        </Button>
      </Tooltip>
    );
  };

  const handleChangeStatus = (status: any, id: any) => {
    ProspectCustomerService.update(id, { statusId: status })
      .then((response: any) => {
        openNotification("success", "Success", "Changed status");
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleChangeDetailStatus = (status: any, id: any) => {
    if (roleName === "CALL_TEAM_MANAGER" || roleName === "CALL_TEAM") {
      setLoading(true);
      ProspectCustomerService.updateDetailStatus(id, { statusId: status })
        .then((response: any) => {
          openNotification("success", "Success", "Changed status");
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleUpdatePhone = async () => {
    try {
      setLoading(true);
      const values = await contactForm.validateFields(["phone"]);

      ProspectCustomerService.updateContact(Number(id), { phone: values.phone })
        .then((response: any) => {
          openNotification(
            "success",
            "Success",
            "Customer phone information has been updated successfully."
          );
          setIsEditingPhone(false);
          setCustomer(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
          openNotification(
            "error",
            "Error",
            "Failed to update contact information."
          );
        })
        .finally(() => setLoading(false));
    } catch (error) {
      console.error("Validation error: ", error);
      openNotification("error", "Error", "Please correct the form errors.");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateEmail = async () => {
    try {
      setLoading(true);
      const values = await contactForm.validateFields(["email"]);

      ProspectCustomerService.updateContact(Number(id), { email: values.email })
        .then((response: any) => {
          openNotification(
            "success",
            "Success",
            "Customer email information has been updated successfully."
          );
          setIsEditingEmail(false);
          setCustomer(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
          openNotification(
            "error",
            "Error",
            "Failed to update contact information."
          );
        })
        .finally(() => setLoading(false));
    } catch (error) {
      console.error("Validation error: ", error);
      openNotification("error", "Error", "Please correct the form errors.");
    } finally {
      setLoading(false);
    }
  };

  const handleCancelPhoneEdit = () => {
    setIsEditingPhone(false);
    contactForm.resetFields(["phone"]);
  };

  const handleCancelEmailEdit = () => {
    setIsEditingEmail(false);
    contactForm.resetFields(["email"]);
  };

  return (
    <>
      <Row justify={"start"} align={"middle"}>
        <Button
          type="default"
          onClick={() => navigate(-1)}
          style={{ marginRight: 16 }}
        >
          <ArrowLeftOutlined />
        </Button>
        <p className="page-title">Call Center Customer Detail</p>
      </Row>
      <Row justify={"space-between"} align={"middle"}>
        <div>
          <p
            style={{
              fontSize: 24,
              fontWeight: "bold",
              margin: 0,
              marginTop: 10,
            }}
          >
            {customer?.fullName}
          </p>
          <Row align={"middle"}>
            <Form form={contactForm} layout="inline">
              <p
                style={{
                  marginRight: 20,
                  color: "#292D32",
                  fontSize: 12,
                  display: "flex",
                  alignItems: "end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    src={phoneIcon}
                    alt="phone-icon"
                    style={{ marginRight: 2, marginTop: 2 }}
                  />
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a phone number!",
                      },
                      {
                        pattern: /^\+?[0-9]*$/,
                        message:
                          'Phone number can only contain numbers and "+" symbol!',
                      },
                      {
                        min: 7,
                        message: "Phone number must be at least 7 digits!",
                      },
                      {
                        max: 15,
                        message: "Phone number cannot exceed 15 digits!",
                      },
                    ]}
                  >
                    {isEditingPhone ? (
                      <Input
                        style={{ width: 200 }}
                        placeholder="Phone Number"
                        defaultValue={customer?.phone}
                        allowClear
                        addonAfter={
                          <span>
                            <CheckOutlined onClick={handleUpdatePhone} />
                            <CloseOutlined
                              onClick={handleCancelPhoneEdit}
                              style={{ marginLeft: 8 }}
                            />
                          </span>
                        }
                      />
                    ) : (
                      <>
                        <CopyClipboard text={customer?.phone}></CopyClipboard>{" "}
                        <EditOutlined
                          style={{
                            marginLeft: 5,
                            cursor: "pointer",
                            fontSize: 16,
                          }}
                          onClick={() => setIsEditingPhone(true)}
                          title="Edit Phone Number"
                        />
                      </>
                    )}
                  </Form.Item>
                </div>{" "}
              </p>

              <p
                style={{
                  marginRight: 10,
                  color: "#292D32",
                  fontSize: 12,
                  display: "flex",
                  alignItems: "end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    src={smsIcon}
                    alt="sms-icon"
                    style={{ marginRight: 2, marginTop: 2 }}
                  />{" "}
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a email!",
                      },
                      {
                        type: "email",
                        message: "Please enter a valid email!",
                      },
                      {
                        min: 5,
                        message: "Email must be at least 5 digits!",
                      },
                      {
                        max: 50,
                        message: "Email cannot exceed 50 digits!",
                      },
                    ]}
                  >
                    {isEditingEmail ? (
                      <Input
                        style={{ width: 250 }}
                        placeholder="Email"
                        defaultValue={customer?.email}
                        allowClear
                        type="email"
                        addonAfter={
                          <span>
                            <CheckOutlined onClick={handleUpdateEmail} />
                            <CloseOutlined
                              onClick={handleCancelEmailEdit}
                              style={{ marginLeft: 8 }}
                            />
                          </span>
                        }
                      />
                    ) : (
                      <>
                        <CopyClipboard text={customer?.email}></CopyClipboard>{" "}
                        <EditOutlined
                          style={{
                            marginLeft: 5,
                            cursor: "pointer",
                            fontSize: 16,
                          }}
                          onClick={() => setIsEditingEmail(true)}
                          title="Edit Email"
                        />
                      </>
                    )}
                  </Form.Item>
                </div>{" "}
              </p>
            </Form>
          </Row>
        </div>
        <Row justify={"center"} align={"middle"}>
          <Row justify={"end"}>
            <Button type="primary" onClick={showModal}>
              <ShoppingCartOutlined /> Sales
            </Button>
            <Modal
              title="Sales"
              open={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <Row justify={"end"}>
                <Button
                  type="primary"
                  style={{ marginBottom: 16 }}
                  onClick={showAddPackageModal}
                >
                  Add Sale Log
                </Button>
              </Row>
              <Table
                dataSource={salesLog}
                columns={salesLogColumns}
                rowKey="id"
              />
            </Modal>
          </Row>
          <Row>{callForm()}</Row>
        </Row>
      </Row>
      <Row justify={"end"} style={{ marginBottom: 10 }}>
        <Row
          style={{
            flexDirection: "column",
            alignItems: "start",
            marginLeft: 8,
          }}
        >
          <label
            htmlFor="callCenterStatusDetail"
            style={{ margin: "4px 0 2px 4px" }}
          >
            Call Center Detail Status
          </label>
          <Select
            title="Select Detail Status"
            id="callCenterStatusDetail"
            showSearch
            filterOption={(input, option) =>
              option && option.label && typeof option.label === "string"
                ? option.label.toLowerCase().includes(input.toLowerCase())
                : false
            }
            onChange={(selectedStatus) =>
              handleChangeDetailStatus(selectedStatus, customer?.id)
            }
            key={customer?.id}
            defaultValue={
              customer?.callCenterStatusDetail &&
              customer?.callCenterStatusDetail.id
            }
            placeholder={"Select"}
            style={{ width: "180px" }}
          >
            {customerDetailStatusOptions.map((mainStatus) => (
              <Select.OptGroup key={mainStatus.id} label={mainStatus.name}>
                {mainStatus.subStatuses.map((subStatus: any) => (
                  <Select.Option key={subStatus.id} value={subStatus.id}>
                    {subStatus.name}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            ))}
          </Select>
        </Row>
        <Row
          style={{
            flexDirection: "column",
            alignItems: "start",
            marginLeft: 8,
          }}
        >
          <label htmlFor="callCenterStatus" style={{ margin: "4px 0 2px 4px" }}>
            Call Center Status
          </label>
          <Select
            title="Select a Call Center Status"
            id="callCenterStatus"
            onChange={(selectedStatus) =>
              handleChangeStatus(selectedStatus, customer?.id)
            }
            options={customerStatusOptions}
            key={customer?.id}
            {...(customer?.callCenterStatus1?.id && {
              defaultValue: customer.callCenterStatus1.id,
            })}
            placeholder={"Select Call Center Status"}
            style={{ width: "180px" }}
          />
        </Row>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} xl={8}>
          <AdditionalPackageHistoryCard email={customer?.email} />
        </Col>
        <Col sm={24} xl={10}>
          <CallHistory customer={customer} />
        </Col>
        <Col sm={24} xl={6}>
          <CustomerInfo customer={customer} learner={learnerDetail} />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col sm={24} xl={12}>
          <Notes
            customerId={customer?.id}
            setTriggerUpdate={setTriggerUpdate}
          />
        </Col>
        <Col sm={24} xl={12}>
          <Tasks
            customerName={customer?.fullName}
            customerId={customer?.id}
            setTriggerUpdate={setTriggerUpdate}
            userOptions={userOptions}
          />
        </Col>
      </Row>
    </>
  );
};

export default ProspectCustomerDetail;
