import { Col, Row, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useRef, useState } from "react";
import AmazonSuspendedApplicantReportsService from "../../../../services/AmazonSuspendedApplicantReportsService";
import dayjs from "dayjs";
import { SuspendFileCount } from "../../../../types/AmazonSuspendedApplicantReports";
import ReusableChart from "../../CommonReport/SummaryReport/ReusableChart";

const SuspendedFileCountReport = ({
  dateRange,
  periodCount,
}: {
  dateRange: any;
  periodCount: number;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dateLabels, setDateLabels] = useState<any[]>([]);
  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [chartHeight, setChartHeight] = useState<number>(200);
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200 && tableRef.current) {
        const tableColHeight = tableRef.current.getBoundingClientRect().height;
        setChartHeight(Math.max(200, tableColHeight - 30));
      } else {
        setChartHeight(250);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const periods = [];
      let start = dayjs(dateRange[0]);
      let end = dayjs(dateRange[1]);

      for (let i = 0; i < periodCount; i++) {
        const formattedStart = start.format("YYYY-MM-DDT00:00:00");
        const formattedEnd = end.format("YYYY-MM-DDT23:59:59");

        periods.push({
          start: formattedStart,
          end: formattedEnd,
          label: `${start.format("DD.MMM.YY")} - ${end.format("DD.MMM.YY")}`,
        });

        // Calculate the previous period
        const diffDays = end.diff(start, "days") + 1;
        end = start.subtract(1, "days");
        start = end.subtract(diffDays - 1, "days");
      }

      const reversedPeriods = periods.reverse(); // Period'ları tersine çevir
      getReports(reversedPeriods);
      setDateLabels(reversedPeriods);
    }
    // eslint-disable-next-line
  }, [dateRange, periodCount]);

  const getReports = async (periods: any[]) => {
    setLoading(true);
    try {
      const promises = periods.map((period) =>
        AmazonSuspendedApplicantReportsService.getSuspendFileCount(
          period.start,
          period.end
        )
      );

      const responses = await Promise.all(promises);

      const formattedData: SuspendFileCount[] = responses.flatMap(
        (response, index) =>
          response.data.map((item: SuspendFileCount) => ({
            ...item,
            reportDate: periods[index].label,
            label: periods[index].label, // Her veri için period etiketi ekliyoruz
          }))
      );

      setDataSource(formattedData);

      setChartSeries([
        {
          name: "File Count",
          data: formattedData.map((data) => data.fileCount),
        },
      ]);
      // setDateLabels(reversedPeriods);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<SuspendFileCount> = [
    {
      title: "Period",
      dataIndex: "reportDate",
      key: "reportDate",
      align: "center",
      width: 180,
      render: (reportDate: string) => <b>{reportDate ? reportDate : "N/A"}</b>,
    },
    {
      title: "File Count",
      dataIndex: "fileCount",
      key: "fileCount",
      align: "center",
      width: 150,
      sorter: (a, b) => a.fileCount - b.fileCount,
      render: (fileCount: number) => (
        <b>{fileCount !== undefined ? fileCount : "N/A"}</b>
      ),
    },
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40, marginTop: 40 }}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={14}>
            <ReusableChart
              type="bar"
              series={chartSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col>
          <Col xs={24} xl={10} ref={tableRef}>
            <Table
              loading={loading}
              scroll={{ x: "max-content" }}
              sticky
              rowKey={(record) => record.reportDate}
              columns={columns}
              dataSource={dataSource}
              pagination={false}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SuspendedFileCountReport;
