import { Breadcrumb, Card, Col, Collapse, Row, Select, Typography } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { ReportsRouter } from "../../ReportsRouter";
import DateRange from "../../../Common/DateRange";
import { useState } from "react";
import MemberBasedTotalCustomerReport from "./MemberBasedTotalCustomerReport";
import MemberFlagReport from "./MemberFlagReport";
import ReasonForDropoutReport from "./ReasonForDropoutReport";
import SalesLogReport from "./SalesLogReport";
import MemberBasedTotalSoftwareSuspendedCustomerReport from "./MemberBasedTotalSoftwareSuspendedCustomerReport";
import AdditionalPackagesPurchasedWithGoldOrSlpPackageReport from "./AdditionalPackagesPurchasedWithGoldOrSlpPackageReport";
import RetentionCountReport from "./RetentionCountReport";
import MemberBasedTotalSoftwareSuspendedCustomerTransferReport from "./MemberBasedTotalSoftwareSuspendedCustomerTransferReport";

const { Title } = Typography;
const { Panel } = Collapse;

const SummaryReport = () => {
  const [dateRange, setDateRange] = useState<[string, string]>(["", ""]);
  const [periodCount, setPeriodCount] = useState<number>(3);
  const [loadedPanels, setLoadedPanels] = useState<string[]>([]);
  const [activePanels, setActivePanels] = useState<string[]>([]);
  const location = useLocation();

  const getTeamMemberAndFlags = () => {
    if (location.pathname.includes("/stm-reports/summary-report")) {
      return {
        teamName: "success-team-members",
        teamMember: "Success Team Member",
        memberFlag: "SUCCESS_TEAM",
      };
    } else if (location.pathname.includes("/acm-reports/summary-report")) {
      return {
        teamName: "account-manager-members",
        teamMember: "Account Manager",
        memberFlag: "ACCOUNT_MANAGER",
      };
    } else if (location.pathname.includes("/hi5-acm-reports/summary-report")) {
      return {
        teamName: "hi5-acm-members",
        teamMember: "Hi5 Account Manager",
        memberFlag: "HI5_ACM_MEMBER",
      };
    } else
      return {
        teamName: "",
        teamMember: "",
        memberFlag: "",
      };
  };

  const { teamMember, memberFlag, teamName } = getTeamMemberAndFlags();

  const handlePanelChange = (key: string | string[]) => {
    const keyArray = Array.isArray(key) ? key : [key];
    setActivePanels(keyArray);
    setLoadedPanels((prev) => Array.from(new Set([...prev, ...keyArray])));
  };

  return (
    <>
      <Row>
        <Col>
          <Title className="onemaz-page-title" level={3}>
            Reports
          </Title>
          <Breadcrumb
            items={[
              {
                title: <NavLink to="/">Home</NavLink>,
              },
              {
                title: "Summary Report",
              },
            ]}
          />
        </Col>
      </Row>
      <ReportsRouter />
      <Card
        className="card-customers zebra-table"
        title={
          <>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <div
                className="customer-card-title"
                style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
              >
                Summary Report
              </div>
            </div>
          </>
        }
        extra={
          <Row justify={"end"}>
            <DateRange thisMonth={true} onChangeDateRange={setDateRange} />
            <Select
              defaultValue={3}
              style={{ width: 150, marginLeft: 10 }}
              onChange={(value) => setPeriodCount(value)}
              size="large"
            >
              <Select.Option value={1}>Last 1 period</Select.Option>
              <Select.Option value={2}>Last 2 period</Select.Option>
              <Select.Option value={3}>Last 3 period</Select.Option>
              <Select.Option value={4}>Last 4 period</Select.Option>
              <Select.Option value={5}>Last 5 period</Select.Option>
              <Select.Option value={6}>Last 6 period</Select.Option>
            </Select>
          </Row>
        }
        bordered
        style={{ marginTop: "30px" }}
      >
        <Collapse
          onChange={handlePanelChange}
          activeKey={activePanels}
          expandIconPosition="end"
        >
          <Panel
            header={
              <h3 style={{ margin: 0, padding: 0 }}>
                Member Based Total Customer Report
              </h3>
            }
            key="1"
          >
            {loadedPanels.includes("1") && (
              <MemberBasedTotalCustomerReport
                memberFlag={memberFlag}
                teamMember={teamMember}
              />
            )}
          </Panel>
          <Panel
            header={
              <h3 style={{ margin: 0, padding: 0 }}>
                {`Joined & Left ${
                  memberFlag === "SUCCESS_TEAM" ? "& New " : ""
                }Customers Count Report`}
              </h3>
            }
            key="2"
          >
            {loadedPanels.includes("2") && (
              <MemberFlagReport
                memberFlag={memberFlag}
                dateRange={dateRange}
                periodCount={periodCount}
              />
            )}
          </Panel>
          <Panel
            header={
              location.pathname.includes("/stm-reports/summary-report") ? (
                <h3 style={{ margin: 0, padding: 0 }}>
                  {memberFlag
                    .toLowerCase()
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (char) => char.toUpperCase())}{" "}
                  Reason for Dropout Report
                </h3>
              ) : (
                <h3 style={{ margin: 0, padding: 0 }}>
                  Reason for Dropout Report
                </h3>
              )
            }
            key="3"
          >
            {loadedPanels.includes("3") && (
              <ReasonForDropoutReport
                memberFlag={memberFlag}
                dateRange={dateRange}
                periodCount={periodCount}
              />
            )}
          </Panel>
          <Panel
            header={<h3 style={{ margin: 0, padding: 0 }}>Sales Log Report</h3>}
            key="4"
          >
            {loadedPanels.includes("4") && (
              <SalesLogReport
                memberFlag={memberFlag}
                dateRange={dateRange}
                teamMember={teamMember}
                periodCount={periodCount}
              />
            )}
          </Panel>
          {!location.pathname.includes("/stm-reports/summary-report") && (
            <>
              <Panel
                header={
                  <h3 style={{ margin: 0, padding: 0 }}>
                    Additional Packages Purchased With{" "}
                    {memberFlag === "ACCOUNT_MANAGER" ? "Gold" : "SLP"} Package
                    Report
                  </h3>
                }
                key="5"
              >
                {loadedPanels.includes("5") && (
                  <AdditionalPackagesPurchasedWithGoldOrSlpPackageReport
                    memberFlag={memberFlag}
                    dateRange={dateRange}
                  />
                )}
              </Panel>
              <Panel
                header={
                  <h3 style={{ margin: 0, padding: 0 }}>
                    Member Based Total Software Suspended Customer Transfer
                    Report
                  </h3>
                }
                key="6"
              >
                {loadedPanels.includes("6") && (
                  <MemberBasedTotalSoftwareSuspendedCustomerTransferReport
                    oldMemberFlag={memberFlag}
                    newMemberFlag={"SUCCESS_TEAM_SOFTWARE_SUSPEND"}
                    dateRange={dateRange}
                    teamMember={teamMember}
                    periodCount={periodCount}
                  />
                )}
              </Panel>
            </>
          )}
          <Panel
            header={
              <h3 style={{ margin: 0, padding: 0 }}>Retention Count Report</h3>
            }
            key="7"
          >
            {loadedPanels.includes("7") && (
              <RetentionCountReport
                dateRange={dateRange}
                teamName={teamName}
                teamMember={teamMember}
                periodCount={periodCount}
              />
            )}
          </Panel>
          {location.pathname.includes("/stm-reports/summary-report") && (
            <>
              <Panel
                header={
                  <h3 style={{ margin: 0, padding: 0 }}>
                    Member Based Total Software Suspended Customer Report
                  </h3>
                }
                key="8"
              >
                {loadedPanels.includes("8") && (
                  <MemberBasedTotalSoftwareSuspendedCustomerReport
                    memberFlag={"SUCCESS_TEAM_SOFTWARE_SUSPEND"}
                    dateRange={dateRange}
                    teamMember={teamMember}
                    periodCount={periodCount}
                  />
                )}
              </Panel>
              <Panel
                header={
                  location.pathname.includes("/stm-reports/summary-report") ? (
                    <h3 style={{ margin: 0, padding: 0 }}>
                      {memberFlag === "SUCCESS_TEAM"
                        ? "Software Suspended "
                        : ""}
                      Reason for Dropout Report
                    </h3>
                  ) : (
                    <h3 style={{ margin: 0, padding: 0 }}>
                      Reason for Dropout Report
                    </h3>
                  )
                }
                key="9"
              >
                {loadedPanels.includes("9") && (
                  <ReasonForDropoutReport
                    memberFlag={"SUCCESS_TEAM_SOFTWARE_SUSPEND"}
                    dateRange={dateRange}
                    periodCount={periodCount}
                  />
                )}
              </Panel>
            </>
          )}
        </Collapse>
      </Card>
    </>
  );
};

export default SummaryReport;
