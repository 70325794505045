import http from "../http-common";
import {
  IVisitorExpensesCreateRequest,
  IMarketingReportResponse,
  IVisitorExpensesResponse,
  IVisitorExpensesUpdateRequest,
} from "../types/MarketingReport";

const getMarketingReport = (startDate: string, endDate: string) => {
  return http.get<Array<IMarketingReportResponse>>(
    `/marketing-report/get-report?startDate=${startDate}&endDate=${endDate}`
  );
};

const getVisitorAndExpenses = (startDate: string, endDate: string) => {
  return http.get<Array<IVisitorExpensesResponse>>(
    `/marketing-report/get-visitor-expenses?startDate=${startDate}&endDate=${endDate}`
  );
};

const createVisitorAndExpenses = (request: IVisitorExpensesCreateRequest) => {
  return http.post<Array<IMarketingReportResponse>>(
    `/marketing-report/generate-report`,
    request
  );
};

const updateVisitorAndExpenses = (
  request: IVisitorExpensesUpdateRequest,
  id: number,
) => {
  return http.put<Array<any>>(
    `/marketing-report/update-visitor-expenses${id}`,
    request
  );
};

const MarketingReportService = {
  getMarketingReport,
  getVisitorAndExpenses,
  createVisitorAndExpenses,
  updateVisitorAndExpenses,
};

export default MarketingReportService;
